import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Checkbox, Avatar, Spin, Button as AntdButton, message, Steps } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { editOpportunity, getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem } from "../../utils/utilities";
import goal1 from "../../assets/goal1.png";
import goal2 from "../../assets/goal2.png";
import goal3 from "../../assets/goal3.png";
import goal4 from "../../assets/goal4.png";
import goal5 from "../../assets/goal5.png";
import goal6 from "../../assets/goal6.png";
import goal7 from "../../assets/goal7.png";
import goal8 from "../../assets/goal8.png";
import goal9 from "../../assets/goal9.png";
import goal10 from "../../assets/goal10.png";
import goal11 from "../../assets/goal11.png";
import goal12 from "../../assets/goal12.png";
import goal13 from "../../assets/goal13.png";
import goal14 from "../../assets/goal14.png";
import goal15 from "../../assets/goal15.png";
import goal16 from "../../assets/goal16.png";
import goal17 from "../../assets/goal17.png";
import { opportunityDataSelector, programDataSelector, editOpportunityData, toggleSpinnerSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import lightbulb from '../../assets/lightbulb.svg'

const outcomeUNSDGImages = [
    { key: 1, image: goal1 },
    { key: 2, image: goal2 },
    { key: 3, image: goal3 },
    { key: 4, image: goal4 },
    { key: 5, image: goal5 },
    { key: 6, image: goal6 },
    { key: 7, image: goal7 },
    { key: 8, image: goal8 },
    { key: 9, image: goal9 },
    { key: 10, image: goal10 },
    { key: 11, image: goal11 },
    { key: 12, image: goal12 },
    { key: 13, image: goal13 },
    { key: 14, image: goal14 },
    { key: 15, image: goal15 },
    { key: 16, image: goal16 },
    { key: 17, image: goal17 },
];

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};

const CharityImpactProgram = ({ className }) => {
    const baseClassName = clsx("charityImpactProgram", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [wordCounts, setWordCounts] = useState({});
    const [missingFields, setMissingFields] = useState([]);
    const [savedClicked, setSavedClicked] = useState(false);
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector) || {};
    const editOpp = useAppSelector(editOpportunityData) || {};
    const { impactStrategy } = programData || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunityID } = editOpp || {};
    const { opportunity } = opportunityData || {};

    useEffect(() => {
        if (opportunity?.impactApproach?.objectives) {
            const checkboxGroup = opportunity.impactApproach.objectives.map(obj => obj.name) || [];
            const objectivesOutcome = {};
            opportunity.impactApproach.objectives.forEach(obj => {
                if (obj.objectivesOutcome) {
                    objectivesOutcome[`objectivesOutcome-${obj.name}`] = obj.objectivesOutcome;
                }
            });
            const initialValues = {
                checkboxGroup,
                ...objectivesOutcome
            };
            form.setFieldsValue(initialValues);
            setFormData(initialValues);
        }
    }, [opportunity?.impactApproach?.objectives]);

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(toggleSpinner(false));
            dispatch(setEditOpportunitiesData(''))
            navigate("/impact-maker/applications/create/impact-project-custom");
        }
    }, [opportunityID])

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const validateFormList = (values, objectives) => {
        let isValid = true;
        let errorMessages = [];
        let missingFields = [];

        objectives.forEach(objective => {
            const outcomes = values[`objectivesOutcome-${objective.name}`];
            if (outcomes && outcomes.length > 0) {
                const isEmptyOutcome = outcomes.every(outcome => !outcome.name || outcome.name?.trim() === "");
                if (isEmptyOutcome) {
                    isValid = false;
                    errorMessages.push(`At least one outcome is required for ${objective.name}`);
                    missingFields.push(objective.name);
                }
            } else {
                isValid = false;
                errorMessages.push(`At least one outcome is required for ${objective.name}`);
                missingFields.push(objective.name);
            }
        });

        return { isValid, errorMessages, missingFields };
    };

    const handleProceed = () => {
        form.validateFields().then(values => {
            const selectedObjectives = impactStrategy?.objectives?.filter((objective) =>
                values?.checkboxGroup?.includes(objective?.name)
            );

            if (!selectedObjectives || selectedObjectives.length === 0) {
                form.setFields([{ name: 'checkboxGroup', errors: [intl.formatMessage({ id: "at_least_one_checkbox_must_be_selected" })] }]);
                return;
            }

            const { isValid, missingFields } = validateFormList(values, selectedObjectives);
            setMissingFields(missingFields);

            if (!isValid) {
                const fieldErrors = selectedObjectives.map(obj => ({
                    name: `objectivesOutcome-${obj.name}`,
                    errors: [`At least one outcome is required for ${obj.name}`]
                }));
                form.setFields(fieldErrors);
                return;
            }

            const data = {
                programID,
                opportunityID: opportunityId,
                application: "Y",
                impactApproach: {
                    objectives: selectedObjectives?.map((objective) => ({
                        name: objective?.name,
                        objectiveReportType: "application",
                        objectiveID: objective?.objectiveID,
                        themeID: objective?.themeID,
                        themeName: objective?.themeName,
                        objectiveUNSDG: objective?.objectiveUNSDG || [],
                        objectivesOutcome: (values?.[`objectivesOutcome-${objective?.name}`] || [])?.map(outcome => ({
                            name: outcome?.name,
                            outcomeReportType: "application",
                            objectiveID: objective?.objectiveID,
                            outcomeApproach: undefined
                        }))
                    }))
                }
            };

            dispatch(toggleSpinner(true));
            dispatch(editOpportunity(data));
        }).catch(errorInfo => {
            console.log('Validate Failed:', errorInfo);
        });
    };


    const countWords = (text) => {
        return text?.trim().split(/\s+/).filter(word => word.length > 0).length;
    };

    // Handler to track word count and set form values
    const handleTextChange = (key, maxWords) => (e) => {
        const text = e.target.value;
        const wordCount = countWords(text);

        if (wordCount <= maxWords) {
            setWordCounts((prev) => ({ ...prev, [key]: wordCount }));
            form.setFieldsValue({ [key]: text });
        }
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    };

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/request");
    };

    return (
        <div className={baseClassName}>
            <Steps
                current={2}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        onValuesChange={onFormChange}
                        scrollToFirstError
                    >
                        <StyledText as="p" variant="H1" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "which_of_the_company_impact_objectives_will_your_project_support" })}
                        </StyledText>
                        <Form.Item
                            name="checkboxGroup"
                            rules={[{
                                validator: (_, value) => {
                                    if (!value || value.length === 0) {
                                        return Promise.reject(new Error(intl.formatMessage({ id: "at_least_one_checkbox_must_be_selected" })));
                                    }
                                    return Promise.resolve();
                                }
                            }]}
                        >
                            <Checkbox.Group className='checkboxGroup'>
                                {impactStrategy?.objectives?.map(el => (
                                    <>
                                        <Checkbox key={el.name} value={el.name}>
                                            <StyledText as="p" variant="B3e">
                                                {el.name}
                                            </StyledText>
                                        </Checkbox>
                                        <div className='objectiveUNSDGImagesContainer'>
                                            {outcomeUNSDGImages
                                                ?.filter(im => el.objectiveUNSDG.includes(im.key))
                                                ?.map(im => (
                                                    <Avatar shape="square" key={im.key} src={im.image} />
                                                ))}
                                        </div>
                                    </>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        {impactStrategy?.objectives?.filter(el => formData.checkboxGroup?.includes(el.name))?.map((el, index) => (
                            <>
                                {index === 0 && <>
                                    <StyledText as="p" variant="B3d">
                                        {intl.formatMessage({ id: "what_outcomes_do_you_hope_your_project_will_achieve" })}
                                    </StyledText>
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "please_use_no_more_than_25_words_as_you_will_be_asked_to_describe_your_approach_on_the_next_screen" })}
                                    </StyledText>
                                </>}
                                <div key={el.name} className='outcomeParentContainer'>
                                    <StyledText as="p" variant="B3e" className='outcomeParentName'>
                                        {el.name}
                                    </StyledText>
                                    <Form.List name={`objectivesOutcome-${el.name}`}>
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields?.map(({ key, name, fieldKey, ...restField }, index) => (
                                                    <div key={`name-${el.name}-${name}`} className='outcomeChildContainer'>
                                                        <div className='formTextField'>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'name']}
                                                                fieldKey={[fieldKey, 'name']}
                                                                rules={[{ required: true, message: 'Outcome name is required' },
                                                                {
                                                                    validator: (_, value) => {
                                                                        const words = value?.trim().split(/\s+/).filter((word) => word.length > 0) || [];
                                                                        if (words.length > 25) {
                                                                            return Promise.reject(new Error('Word limit exceeded (Max 25 words)'));
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                }
                                                                ]}
                                                            >
                                                                <TextArea
                                                                    rows={2}
                                                                    placeholder={intl.formatMessage({ id: "enter_outcome" })}
                                                                    value={formData[`objectivesOutcome-${el.name}`]?.[index]?.name || ''}
                                                                    onChange={handleTextChange(`name-${el.name}-${name}`, 25)}
                                                                    rules={[
                                                                        {
                                                                            validator: (_, value) => {
                                                                                const words = value?.trim().split(/\s+/).filter((word) => word.length > 0) || [];
                                                                                if (words.length > el?.questionCharLimit) { 
                                                                                    return Promise.reject(new Error(`Word limit exceeded (Max 25 words)`));
                                                                                }
                                                                                return Promise.resolve();
                                                                            }
                                                                        }
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                            <div className={`wordCount ${countWords(formData[`objectivesOutcome-${el.name}`]?.[index]?.name || '') === 25 ? 'error' : ''}`}>
                                                                {countWords(formData[`objectivesOutcome-${el.name}`]?.[index]?.name || '')}/{25} words
                                                            </div>
                                                        </div>
                                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ fontSize: '20px', color: '#04ac9c', marginBottom: '40px', marginLeft: '-15%' }} />
                                                    </div>
                                                ))}
                                                {missingFields.includes(el.name) && (
                                                    <StyledText as="p" variant="B3" className="errorText">
                                                        {intl.formatMessage({ id: `At least one outcome is required for ${el.name}` })}
                                                    </StyledText>
                                                )}
                                                <Form.Item>
                                                    <AntdButton
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        icon={<PlusOutlined />}
                                                        className='addButton'
                                                        htmlType='button'
                                                    >
                                                        <StyledText as="p" variant="B3">
                                                            {intl.formatMessage({ id: "add" })}
                                                        </StyledText>
                                                    </AntdButton>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </div>
                            </>
                        ))}

                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={handlePrevious}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={handleSaveAndExit}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className='tipsContainer'>
                    <div className='titleContainer'>
                        <Avatar src={lightbulb} />
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "tips" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_is_an_outcome" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "an_outcome_is_the_change_you_hope_to_see_as_a_result_of_your_project_below_are_some_examples" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "objective_protect_and_improve_the_local_environment" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "outcome_improve_fish_populations_through_river_restoration" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "objective_create_and_improve_local_amenities" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "outcome_improve_the_energy_efficiency_of_the_village_hall_with_new_windows" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "objective_engage_the_local_community" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "outcome_reduce_isolation_and_loneliness_in_older_people" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "how_will_you_achieve_these_outcomes" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "briefly_describe_your_approach_to_achieving_the_outcomes" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "below_are_some_examples" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "objective_protect_and_improve_the_local_environment" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "outcome_improve_fish_populations_through_river_restoration" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "we_will_remove_excess_silt" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "objective_create_and_improve_local_amenities" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "outcome_improve_the_energy_efficiency_of_the_village_hall_with_new_windows" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "we_will_replace_our_old_windows_with_triple_glazed_windows" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "objective_engage_the_local_community" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "outcome_reduce_isolation_and_loneliness_in_older_people" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "we_will_run_weekly_workshops_bringing_older_people_together" })}
                        </StyledText>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default styled(CharityImpactProgram)`
&.charityImpactProgram{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 250vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .for{
        margin-bottom: 40px;
    }
     .formItem .ant-form-item-explain-error{
        margin-top: -20px !important;
    }
    .errorText {
    color: red;
    margin-bottom: 20px;
}
    .formTextField{
        width: 100%;
        margin-bottom: 15px;
        .ant-form-item{
            margin-bottom: 5px;
        }
    }
    .ant-form{
        width: 50vw;
        display: flex;
        flex-direction: column;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .formItemTextFieldPart{
        margin-bottom: 5px !important;
        width: 800px;
        .ant-form-item-control{
            width: 300px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .formContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
    .outcomeParentContainer{
        display: flex;
        flex-direction: column;
        .outcomeParentName{
            margin-bottom: 10px;
        }
        .outcomeChildContainer{
            display: flex;
            .ant-form-item{
                width: 100%;
            }
            .inputContainer {
                width: 60%;
                margin-bottom: 0;
            }
        }
        .outcomeContainer{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ant-form-item{
            margin-bottom: 0px !important;
        }
    }
    }

    .outcomeUNSDGImagesContainer{
        display: flex;
        gap: 20px;
        .ant-avatar{
            border-radius: 0 !important;
            height: 50px !important;
            width: 50px !important;
        }
    }

    .wordCount{
        text-align: right;
        margin-right: 17%;
        &.error{
            color: red;
        }
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .objectiveUNSDGImagesContainer{
            margin-left: 25px;
            display: flex;
            gap: 20px;
            .ant-avatar{
                border-radius: 0 !important;
                height: 50px !important;
                width: 50px !important;
            }
        }
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
}
`;
