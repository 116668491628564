import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Helmet as HelmetAsync } from "react-helmet-async";
import {
    EnvironmentFilled,
    StarFilled,
    CalendarFilled,
} from "@ant-design/icons";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline, Form, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import styled from "styled-components";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import {
    programDataSelector,
    opportunityDataSelector,
    profileSelector
} from "../../../redux/selectors";
import MapBoxComponent from "../../../components/MapBoxComponent";
import FullScreenWrapper from '../../../components/FullscreenWrapper';
import { getProfileData } from '../../../redux/actions';
import ProgStartData from '../bizGiveThemeImageData';
import { setLocalStorageItem, getSecuredUrl } from "../../../utils/utilities";
import DateFormatter from "../../../components/DateFormatter";
import ProgramReview from './CharityCreateReview';
import StyledText from "../../../components/StyledText";
import Button from '../../../components/Button';
import CustomModal from "../../../components/Modal";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const getUpdateDueDateMessage = ({
    updateDueDateRelativeDays,
    updateDueDate,
    currentBudgetStartDate,
    currentBudgetEndDate
}) => {
    if (updateDueDateRelativeDays) {
        if (currentBudgetStartDate && currentBudgetEndDate) {
            return `Every ${updateDueDateRelativeDays} days during the project running dates`;
        } else {
            return `${updateDueDateRelativeDays} days after the project start date`;
        }
    } else if (updateDueDate) {
        return updateDueDate.join(', ');
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const [openModal, setOpenModal] = useState(false);
    const [selectedBizGiveImage, setSelectedBizGiveImage] = useState({});
    const [logoS3URL, setLogoS3URL] = useState('');
    const [logoImageURL, setLogoImageURL] = useState('');
    const programData = useAppSelector(programDataSelector);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { programID, opportunityId } = useParams();
    const dispatch = useAppDispatch();
    const { charity: { logoS3URL: logoImage, tradingName, } = {} } = useAppSelector(profileSelector) || {};
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [isFullscreen, setIsFullscreen] = useState(false);

    const {
        currentBudgetStartDate,
        currentBudgetEndDate,
        programmeLocation,
        updateDueDateRelativeDays,
        outcomeDueDateRelativeDays,
        opportunityCriteriaChecklist,
    } = programData || {};
    const { opportunity:
        { startDate,
            endDate,
            coverImage,
            donationDeadLineDate,
            updateDueDate,
            outcomeDate,
            opportunityName,
            coverImageType,
            opportunityLocation2: {
                features
            } = {},
        } = {} }
        = opportunityData || {};


    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const setLogoBannerImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoImageURL(securedURL);
        return fileKey ? securedURL : '';
    };

    useEffect(() => {
        if (opportunityName) {
            dispatch(getProfileData())
            form.setFieldsValue({
                opportunityName: opportunityName,
                coverImageType: coverImageType,
                coverImage: coverImage,
            });

            if (coverImage) {
                const selectedImage = ProgStartData.bizGiveThemeImageData.find(el => el.id === coverImage);
                if (coverImageType === '2') {
                    setLogoImage(coverImage).then(savedCover => setSelectedBizGiveImage(savedCover));
                } else {
                    setSelectedBizGiveImage(selectedImage);
                }
                ProgStartData.bizGiveThemeImageData.forEach(item => {
                    if (item.id === coverImage) item.checked = true;
                });
            }
        }
    }, [opportunityName]);

    useEffect(() => {
        if (logoImage) {
            setLogoBannerImage(logoImage)
        }
    }, [logoImage])

    const renderCoverImage = () => {
        if (selectedBizGiveImage?.path && typeof selectedBizGiveImage?.path === 'string') return <img alt="covercc" className="cameraIcon" src={selectedBizGiveImage.path} />;
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return '';
    };

    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeDays,
        updateDueDate,
        currentBudgetStartDate,
        currentBudgetEndDate
    });

    const onFinish = (values) => {
        setLocalStorageItem('programReviewDetails', JSON.stringify({ programID, opportunityId }));
        navigate('/impact-maker/applications/create/start');
        setOpenModal(false)
    };

    const items = [
        {
            key: "programme",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "applications" })}
            </StyledText>,
            children: <ProgramReview />,
        },
    ];


    const validateCheckboxGroup = (_, value) => {
        if (!value || value.length < opportunityCriteriaChecklist.length) {
            return Promise.reject(new Error(intl.formatMessage({ id: 'please_select' })));
        }
        return Promise.resolve();
    };

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        {renderCoverImage()}
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'>{logoImageURL && <img src={logoImageURL} alt="logoImage" />}</div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {opportunityName}
                            </StyledText>
                            <StyledText as="p" variant="H3" className="title">
                                {tradingName}
                            </StyledText>
                        </div>
                        {/* <div className='titleButtonContainer'>
                            <Button variant="primary" className="declineButton" onClick={() => setOpenModal(true)}>{intl.formatMessage({ id: 'decline' })}</Button>
                            <Button variant="primary" onClick={() => setOpenModal(true)}>{intl.formatMessage({ id: 'approve' })}</Button>
                        </div> */}
                    </div>
                </div>
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            <CustomModal
                title={intl.formatMessage({ id: 'eligibility' })}
                open={openModal}
                onCancel={() => setOpenModal(false)}
                closable={false}>
                <Form
                    {...formItemLayout}
                    form={form}
                    className="eligibilityFormContainer"
                    name="register"
                    onFinish={onFinish}
                    scrollToFirstError
                >
                    {opportunityCriteriaChecklist?.length > 0 && <><StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "before_applying_please_confirm_your_eligibility" })}
                    </StyledText>
                        <Form.Item
                            name="checkboxGroup"
                            rules={[{ validator: validateCheckboxGroup }]}
                        >
                            <Checkbox.Group>
                                {opportunityCriteriaChecklist?.map((el) => (
                                    <Checkbox key={el} value={el}>
                                        {el}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                    </>
                    }
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "please_confirm_that_your_project_is_located_within_or_that_it_benefits_those_within_the_supported_locations" })}
                    </StyledText>
                    <Form.Item
                        name="yesCheck"
                        valuePropName="checked"
                        rules={[{ required: true, message: intl.formatMessage({ id: 'please_select' }) }]}
                    >
                        <Checkbox value='yes'>
                            {intl.formatMessage({ id: "yes" })}
                        </Checkbox>
                    </Form.Item>
                    <div className='modalFooterButtonCenter'>
                        <Button
                            variant="secondary"
                            htmlType="button"
                            onClick={() => setOpenModal(false)}>
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button
                            variant="primary"
                            htmlType="submit"
                            className='deleteButton'>
                            {intl.formatMessage({ id: "apply" })}
                        </Button>
                    </div>
                </Form>
            </CustomModal>
            <div className="rightSideContainer">
                <FullScreenWrapper isFullscreen={isFullscreen}
                    setIsFullscreen={setIsFullscreen}>
                    <MapBoxComponent
                        zoom={4}
                        defaultStyle="MonoCrome"
                        id="submission"
                        markers={features}
                        geoJsonData={programmeLocation}
                        hideStyles
                        isFullscreen={isFullscreen}
                        hideZoomControls
                    />
                </FullScreenWrapper>
                <div className="rightContainer">
                    <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "project_location" })}
                        </StyledText>
                        {features?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item?.place_name === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : item?.place_name}
                            </StyledText>
                        </div>)}
                    </div>
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "project_timelines" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "application_accepted_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                <>
                                                    <DateFormatter dateValue={startDate?.trim()} /> - <DateFormatter dateValue={endDate?.trim()} />
                                                </>
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donation_to_be_made_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                <DateFormatter dateValue={donationDeadLineDate?.trim()} />
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_update_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {updateDueDate || updateDueDateMessage}
                                        </StyledText>}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {<DateFormatter dateValue={outcomeDate?.trim()} /> || `${outcomeDueDateRelativeDays} after the project end date`}
                                        </StyledText>}
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    gap: 20px;

    .cameraIcon {
            font-size: 185px;
            display: block;
            position: relative;
            height: 250px;
        }

        .coverPreview {
            width: 100%;
            overflow: hidden;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            height: 250px;
            img {
                width: 100%;
            }
        }

        .radioGroup {
            display: flex;
            flex-direction: column;
        }

        .bizgiveImageContainer {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .imageContainer {
                position: relative;

                &:hover {
                    transform: scale(2);
                    transition: transform .2s, -webkit-transform .2s;
                    z-index: 99;
                }

                img {
                    height: 40px;
                    width: 120px;
                }

                .checkedCircle {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
            }
        }

    .declineButton{
        background: #ed6c2d !important;
        border: 1px solid #ed6c2d !important;
    }

    .titleButtonContainer{
        display: flex;
        gap: 20px;
    }

    .ant-tabs-tab-active .title {
      color: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .bannerImageContainer {
      width: 100%;
      height: 216px;
      overflow: hidden;
      position: relative;
      background: white;
      border-radius: 10px;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      gap: 35px;
      overflow: scroll;
      height: 90vh;
      width: 95%;

      svg {
        fill: #04ac9c !important;
      }
    }

    .companyTitleContainer {
      display: flex;
      padding: 10px 0px 20px 200px;
      justify-content: space-between;
      position: relative;
      align-items: center;

      .imageContainer {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        border: 1px solid black;
        background-color: grey;
        top: -80px;
        left: 25px;
        position: absolute;
        img{
            height: 144px;
    width: 144px;
    border-radius: 50%;
    border: 1px solid black;
        }
      }
    }

    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    ul {
      margin: 0;
    }

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .mapIFrameStyle {
      border: 0;
      position: relative;
      height: 300px;
    }

    .locationIcon {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .locationContainer {
      .locationDistance {
        font-style: italic;
      }

      svg {
        color: #f96675;
      }
    }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .descriptionContainer {
        p, li {
          font-size: 14px;
          font-weight: 500;
          color: #242424;
          font-family: sans-serif;
        }
      }
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

    .rightSideContainer {
      overflow: scroll;
      height: 90vh;

      .rightContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: white;
        padding: 12px 16px;
      }
    }

    .ant-collapse {
      border: 0;
      border-bottom: 1px solid #D7DDE1;
      border-radius: 0px;

      .ant-collapse-header {
        background: white !important;
      }
    }

    .ant-collapse-content-active {
      border-top: 0;
    }
  }
`;

export default StyledDashboardPage;
