import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Spin, Steps, Upload, message } from "antd";
import styled from "styled-components";
import axios from '../../utils/axios';
import Config from '../../config';
import { getLocalStorageItem, uploadFile } from "../../utils/utilities";
import { editOpportunity, getOpportunity, getProgramById, toggleSpinner, setEditOpportunitiesData } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import {
    opportunityDataSelector,
    toggleSpinnerSelector,
    editOpportunityData
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CreateDescription = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const [pictures, setPictures] = useState([]);
    const [mediaDescriptions, setMediaDescriptions] = useState([]);
    const [savedClicked, setSavedClicked] = useState(false);
    const opportunityData = useAppSelector(opportunityDataSelector) || {}
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunity } = opportunityData || {};
    const [opportunitySummary, setOpportunitySummary] = useState('');
    const [opportunityObjectives, setOpportunityObjectives] = useState('');
    const maxWordsSummary = 30;
    const maxWordsObjectives = 300;
    const editOpp = useAppSelector(editOpportunityData) || {};
    const { opportunityID } = editOpp || {};

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/applications/create/location");
        }
    }, [opportunityID])

    useEffect(() => {
        if (opportunity?.opportunityName) {
            form.setFieldsValue({
                opportunitySummary: opportunity.opportunitySummary,
                opportunityObjectives: opportunity.opportunityObjectives,
            });

            if (opportunity?.media) {
                setPictures(opportunity?.media);
                const initialDescriptions = opportunity.media.reduce((acc, pic) => {
                    acc[pic.uid] = pic.mediaDescription || '';
                    return acc;
                }, {});
                setMediaDescriptions(initialDescriptions);
            }
            setOpportunitySummary(opportunity.opportunitySummary);
            setOpportunityObjectives(opportunity.opportunityObjectives);
        }
    }, [opportunity?.opportunityName]);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
            dispatch(toggleSpinner(false));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = () => {
        const formData = form.getFieldsValue();
        const data = {
            opportunitySummary: formData?.opportunitySummary,
            opportunityObjectives: formData?.opportunityObjectives,
            media: pictures,
            opportunityID: opportunityId,
            programID
        }
        dispatch(toggleSpinner(true));
        dispatch(editOpportunity(data));
    }

    const handleDescriptionChange = (key) => (e) => {
        const newDescriptions = { ...mediaDescriptions, [key]: e.target.value };
        setMediaDescriptions(newDescriptions);
        const updatedPictures = pictures.map((pic) =>
            pic.uid === key ? { ...pic, mediaDescription: e.target.value } : pic
        );
        setPictures(updatedPictures);
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/name")
    }

    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(word => word.length > 0).length;
    };

    const handleTextChange = (setter, maxWords) => (e) => {
        const text = e.target.value;
        if (countWords(text) <= maxWords) {
            setter(text);
            form.setFieldsValue({ [e.target.name]: text });
        }
    };

    const onChange = ({ fileList: newFileList }) => {
        setPictures(prevPictures => {
            const updatedPictures = newFileList.map(newFile => {
                const existingPicture = prevPictures.find(pic => pic.uid === newFile.uid);
                return existingPicture ? { ...existingPicture, ...newFile } : newFile;
            });
            return updatedPictures;
        });
    };

    const processFileUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file, onProgress);
            onSuccess(null, file);
            setPictures(prevPictures =>
                prevPictures.map(pic =>
                    pic.uid === file.uid ? { ...pic, ...presignedPostData.fields } : pic
                )
            );
        } catch (e) {
            message.error(`File upload failed. ${e.message || ''}`);
            onError(e);
        }
    };


    const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
        const fileName = selectedFile.name.replace(
            /(?:\.(?![^.]+$)|[^\w.])+/g,
            ''
        );
        const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
        axios.get(url, (res, err) => {
            if (res && res.status === 200) {
                resolve(res.data);
            } else {
                reject(err.response);
            }
        });
    });

    const uploadFileToS3 = async (presignedPostData, file, onProgress) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const onPreview = async (file) => {
        let src = file.url;
        if (!src && file?.originFileObj instanceof Blob) {
            src = await new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(new Error('Failed to read file'));
            });
        }

        if (src) {
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        } else {
            console.error('Failed to preview image: Source is invalid or unavailable.');
        }
    };

    const wordCountSummary = countWords(opportunitySummary);
    const wordCountObjectives = countWords(opportunityObjectives);

    const onDelete = (file) => {
        setPictures((prevPictures) => prevPictures.filter(picture => picture.uid !== file.uid));
    }

    const onRemove = async (file) => {
        const { deleteURL } = Config.dev.url;
        const data = { bucketName: 'bg-uploaded-media', fileName: file.key };
        axios.put(deleteURL, () => onDelete(file), data, true);
    };

    return (
        <div className={baseClassName}>
            <Steps
                current={0}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "project_description" })}
            </StyledText>
            <div>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    onFinishFailed={onFinishFailed}
                    className="formContainer"
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "please_share_a_summary_of_your_project" })}
                    </StyledText>
                    <Form.Item
                        name="opportunitySummary"
                        className="formItem"
                        rules={[
                            {
                                whitespace: true,
                                required: true,
                                message: intl.formatMessage({ id: "enter" }),
                            },
                        ]}
                    >
                        <div>
                            <TextArea
                                name="opportunitySummary"
                                value={opportunitySummary}
                                rows={2}
                                placeholder={intl.formatMessage({ id: "enter_project_summary" })}
                                onChange={handleTextChange(setOpportunitySummary, maxWordsSummary)}
                            />
                            <div className={`wordCount ${wordCountSummary === maxWordsSummary ? 'error' : ''}`}>
                                {wordCountSummary}/{maxWordsSummary} words
                            </div>
                        </div>
                    </Form.Item>
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "please_describe_your_project" })}
                    </StyledText>
                    <Form.Item
                        name="opportunityObjectives"
                        className="formItem"
                        rules={[
                            {
                                whitespace: true,
                                required: true,
                                message: intl.formatMessage({ id: "enter" }),
                            },
                        ]}
                    >
                        <div>
                            <TextArea
                                name="opportunityObjectives"
                                value={opportunityObjectives}
                                rows={4}
                                placeholder={intl.formatMessage({ id: "for_example_what_do_you_want_to_do" })}
                                onChange={handleTextChange(setOpportunityObjectives, maxWordsObjectives)}
                            />
                            <div className={`wordCount ${wordCountObjectives === maxWordsObjectives ? 'error' : ''}`}>
                                {wordCountObjectives}/{maxWordsObjectives} words
                            </div>
                        </div>
                    </Form.Item>
                    <StyledText as="span" variant="B3d">
                        {intl.formatMessage({ id: "additional_images" })}
                        <StyledText as="span" variant="B3a">
                            {` (${intl.formatMessage({ id: "optional" })})`}
                        </StyledText>
                    </StyledText>
                    <Form.Item className='uploadWrapper'>
                        <Upload
                            listType="picture-card"
                            onChange={onChange}
                            fileList={pictures}
                            onPreview={onPreview}
                            onRemove={onRemove}
                            customRequest={({
                                onSuccess, onError, file, onProgress
                            }) => processFileUpload({
                                onSuccess, onError, file, onProgress
                            })}
                            multiple
                        >
                            {`+ ${intl.formatMessage({ id: "upload" })}`}
                        </Upload>
                        <div className='uploadInputWrapper'>
                            {pictures.map((file) => (
                                <Form.Item key={file.uid}>
                                    <Input
                                        placeholder={intl.formatMessage({ id: "enter_caption" })}
                                        value={mediaDescriptions[file.uid] || ''}
                                        onChange={handleDescriptionChange(file.uid)}
                                    />
                                </Form.Item>
                            ))}
                        </div>
                    </Form.Item>
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => handlePrevious()}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    htmlType="submit"
                                >
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => handleSaveAndExit()}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

const StyledCreateDescription = styled(CreateDescription)`
&.createProfile{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 100vh;
    .uploadWrapper .ant-form-item-control-input-content{
        display: flex !important;
        width: 100%;
        .ant-upload-list{
        display: flex;
        flex-direction: column !important;
    }
    .uploadInputWrapper{
        gap: 56px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 30px;
        width: 100%;
    }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
            .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }
    .mainTitle{
        margin-bottom: 20px;
    }
    .formContainer{
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .wordCount{
        text-align: right;
        &.error{
            color: red;
        }
    }
     .formItem .ant-form-item-explain-error{
        margin-top: -20px !important;
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
}`;

export default StyledCreateDescription;