import React, { useEffect } from "react";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Row, Input } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
  useAppDispatch,
} from "../../redux/store";
import {
  setSearchInputFieldValue,
  setActionInteraction,
} from "../../redux/actions";
import ApplicationAction from "./ApplicationAction";
import PendingAction from "./PendingAction";
import ApprovedAction from "./ApprovedAction";
import DeclinedAction from "./DeclinedAction";
import StyledText from "../../components/StyledText";

const DashboardPage = ({ className }) => {
  const intl = useIntl();
  const baseClassName = clsx("dashboardActionPage", className);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActionInteraction(null))

    return () => dispatch(setSearchInputFieldValue(''))
  }, [location]);

  const items = [
    {
      key: "actions",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "actions_required" })}
        </StyledText>
      ),
      children: <ApplicationAction />,
    },
    {
      key: "pending",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "pending_response" })}
        </StyledText>
      ),
      children: <PendingAction />,
    },
    {
      key: "approved",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "approved" })}
        </StyledText>
      ),
      children: <ApprovedAction />,
    },
    {
      key: "declined",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "declined" })}
        </StyledText>
      ),
      children: <DeclinedAction />,
    },
  ];

  const handleChangeRoute = (key) => {
    navigate(`/impact-maker/applications/${key}`);
  };

  const onSearch = ({ target }) => {
    const { value } = target
    dispatch(setSearchInputFieldValue(value));
  }

  const activeKey = window?.location?.pathname.split('/').pop();

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Applications Page</title>
      </HelmetAsync>
      <StyledText as="p" variant="H1" className="title">
        {intl.formatMessage({ id: "applications" })}
      </StyledText>
      <Row className="filters">
        <div className="sortOptions">
          <Input.Search
            allowClear
            placeholder={intl.formatMessage({ id: 'search_placeholder' })}
            className="searchTableCom"
            onChange={onSearch}
          />
        </div>
      </Row>
      <Tabs defaultActiveKey={activeKey} items={items} onChange={handleChangeRoute} />
    </div>
  );
};

const StyledDashboardPage = styled(DashboardPage)`
  &.dashboardActionPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
    .filters{
      margin: 20px 0px;
      display: flex;
      justify-content: end;
    }
    .sortOptions{
      display: flex;
      gap: 20px;
    }
    .button{
        border: 0;
        background: transparent;
        color: #5550F7;
        cursor: pointer;
        min-width: 100px;
    }
  }
`;

export default StyledDashboardPage;
