import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Avatar, Spin, Radio, Steps } from "antd";
import styled from "styled-components";
import { getOpportunity, getProgramById } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem, formatDate } from "../../utils/utilities";
import { opportunityDataSelector, toggleSpinnerSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import lightbulb from '../../assets/lightbulb.svg'

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 20 },
};

const CharityImpactProgram = ({ className }) => {
    const baseClassName = clsx("charityImpactProgram", className);
    const intl = useIntl();
    const [savedClicked, setSavedClicked] = useState(false);
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunity } = opportunityData || {};

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
            form.setFieldsValue({
                radioBox: 'Y'
            })
        }
    }, [opportunityId]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = () => {
        form.validateFields().then(values => {
            if (values.radioBox === "Y" && !savedClicked) {
                navigate("/impact-maker/applications/create/admin-details");
            } else {
                form.setFields([
                    {
                        name: 'radioBox',
                        errors: [intl.formatMessage({ id: "you_must_select_yes_to_proceed" })],
                    }
                ]);
            }
        }).catch(errorInfo => {
            console.log('Validation Failed:', errorInfo);
        });
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    };

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/custom-questions");
    };

    return (
        <div className={baseClassName}>
            <Steps
                current={3}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        scrollToFirstError
                    >
                        <StyledText as="p" variant="H1" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "if_you_are_awarded_support_you_may_be_required_to_share_interim_Updates" })}
                        </StyledText>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "can_you_meet_the_required_submission_deadlines" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "based_on_the_estimated_start_and_finish_date_of_your_project" })}
                        </StyledText>
                        <div className='datesBox'>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "interim_updates" })}
                            </StyledText>
                            {opportunity?.updateDueDate?.map((el) => <StyledText key={el} as="p" variant="B3">{formatDate(el)}</StyledText>)}
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "final_report" })}
                            </StyledText>
                            <StyledText as="p" variant="B3">
                                {formatDate(opportunity?.outComeDate)}
                            </StyledText>
                        </div>
                        <Form.Item
                            name="radioBox"
                            rules={[
                                { required: true, message: intl.formatMessage({ id: "enter" }) }
                            ]}
                        >
                            <Radio.Group className="radioGroup">
                                <Radio className="radio" value="Y">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "yes" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="N">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "no" })}
                                    </StyledText>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={handlePrevious}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={handleSaveAndExit}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className='tipsContainer'>
                    <div className='titleContainer'>
                        <Avatar src={lightbulb} />
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "tips" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_is_an_interim_update" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "an_update_required_by_the_company_on_the_progress_of_your_project" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "download_interim_update_report_template" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_is_a_final_report" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "a_single_report_required_by_the_company_on_what_your_project_achieved_with_the_support_provided" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "download_final_report_template" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "how_will_i_know_when_an_interim_update_or_final_report_is_due" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "you_will_receive_an_email_notification_from_bizGive_when_the_deadline_is_approaching" })}
                        </StyledText>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default styled(CharityImpactProgram)`
&.charityImpactProgram{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 250vh;
    }
    .radioGroup{
        display: flex;
    flex-direction: column;
    gap: 10px;
    }
    .datesBox{
        border: 1px solid #D9D9D9;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: max-content;
    }
    .ant-form{
        width: 50vw;
        display: flex;
        flex-direction: column;
    }
    .mainTitle{
        margin-bottom: 10px;
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .formContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
}
`;
