import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import PropTypes from 'prop-types';
import { Button as AntButton } from "antd";
import { injectIntl } from 'react-intl';
import { Buffer } from 'buffer';
import { onFileRemovedHandler } from '../../utils/utilities';
import CustomModal from '../Modal';
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import axios from '../../utils/axios';
import Config from '../../config';
import styles from './imageCrop.module.css';

const propTypes = {
  src: PropTypes.string,
  setSelectedFile: PropTypes.func.isRequired,
  setUploadedFileDetails: PropTypes.func.isRequired,
  parent: PropTypes.string,
};

const aspectRatio = 1000 / 250

class ImageCrop extends React.Component {
  constructor() {
    super();
    this.state = {
      src: null,
      loading: false,
      crop: {
        unit: 'px',
        width: 1000,
        height: 250,
        aspect: aspectRatio, // Fixed aspect ratio
        x: 0,
        y: 0,
      },
      showCrop: true,
      choosenFileName: '',
      croppedImage: '',
      showModal: false,
      uploaded: this?.props?.uploaded,
      imageUrl: '',
      isDeleteModalOpen: false,
      uploadedImageUrl: ''
    };
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.parent === 'admin') {
      this.setState((prevState) => ({
        crop: {
          ...prevState.crop,
          aspect: aspectRatio,
        },
      }));
    }
    this.setState({
      uploaded: this?.props?.uploaded,
    });
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({
        src: reader.result,
        showCrop: true,
      }));
      const tempChoosenFileName = e?.target?.files?.[0]?.name?.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, '') || `image-${new Date().getTime()}`;
      this.setState({ choosenFileName: tempChoosenFileName, showModal: true });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const offsetX = Math.max(0, (canvas.width - (image.width * scaleX)) / 2);
    const offsetY = Math.max(0, (canvas.height - (image.height * scaleY)) / 2);
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      offsetX,
      offsetY,
      crop.width * scaleX,
      crop.height * scaleY
    );
    const img = canvas.toDataURL('image/jpeg', 1.0);
    this.setState({ croppedImageUrl: img });
    return img;
  };

  fileToBase64 = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

  getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
    const fileName = selectedFile.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, '');
    const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;

    axios.get(url, (res, err) => {
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject(err.response);
      }
    });
  });

  uploadFileToS3 = async (presignedPostData, file) => new Promise((resolve, reject) => {
    const formData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        this.setState({ progress: percent, selectedFileName: file.name });
        if (percent === 100) {
          setTimeout(() => this.setState({ progress: 0 }), 1000);
        }
      },
    };

    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });

    formData.append('file', file);
    this.setState({ uploadedImageUrl: presignedPostData.fields?.key })
    axios.uploadFile(presignedPostData.url, formData, (res, err) => {
      if (res && res.status === 204) {
        resolve(res.data);
      } else {
        reject(err.response);
      }
    }, config);
  });

  processFileUpload = async (file) => {
    try {
      this.setState({ loading: true });
      let presignedPostData = await this.getPresignedPostData(file.name);
      presignedPostData = await presignedPostData.data;
      await this.uploadFileToS3(presignedPostData, file);

      const logoFileNameKey = presignedPostData.fields.key;
      const { setUploadedFileDetails } = this.props;
      const isFileUploaded = true;

      setUploadedFileDetails(logoFileNameKey, isFileUploaded);

      this.setState({ loading: false, uploaded: true, imageUrl: file.url, showModal: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  initiateFileUpload = () => {
    this.processFileUpload(this.state.croppedImageUrl);
  };

  afterFileRemoval = () => {
    this.setState({ uploaded: false, imageUrl: '', src: null, isDeleteModalOpen: false });
    this.props.setUploadedFileDetails('');
  }

  deleteImage = () => {
    onFileRemovedHandler(this.state.uploadedImageUrl, this.afterFileRemoval)
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      const i = croppedImageUrl.indexOf('base64,');
      const buffer = Buffer.from(croppedImageUrl.slice(i + 7), 'base64');
      const file = new File([buffer], `${this.state.choosenFileName}new.png`, { type: 'image/png' });
      const tempFile = { ...file };
      this.fileToBase64(file).then((result) => {
        this.props.setSelectedFile(result);
        tempFile.name = `${this.state.choosenFileName}new.png`;
        tempFile.url = result;
        tempFile.uid = 0;
        this.setState({ croppedImageUrl: file, croppedImage: tempFile });
      });
    }
  }

  render() {
    const { crop, src, showCrop, showModal, uploaded, isDeleteModalOpen } = this.state;
    const { intl } = this.props;

    return (
      <div className={styles.chooseSection}>
        {!uploaded && (
          <div className={styles.customFileInputWrapper}>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={this.onSelectFile}
              ref={this.fileInputRef}
              className={styles.customFileInput}
            />
            <label htmlFor="file-upload" className={styles.customFileInputButton}>
              <span className={styles.buttonText}>{intl.formatMessage({ id: "upload" })}</span>
            </label>
          </div>
        )}
        {uploaded && (
          <div className={styles.imagePreviewWrapper}>
            <Button variant="secondary" onClick={() => { this.setState({ isDeleteModalOpen: true }) }} className={styles.deleteButton}>
              {intl.formatMessage({ id: "change_image" })}
            </Button>
          </div>
        )}
        {src && showCrop && showModal && (
          <CustomModal
            title={intl.formatMessage({ id: "edit_image" })}
            className="forgotPasswordModal"
            open={src && showCrop && showModal}
            onOk={this.initiateFileUpload}
            onCancel={() => {
              this.props.setUploadedFileDetails('');
              this.setState({
                showModal: false,
                choosenFileName: '',
                src: null,
                crop: {
                  unit: 'px',
                  width: 1000,
                  height: 250,
                  aspect: aspectRatio,
                  x: 0,
                  y: 0,
                },
              });
            }}
            closable={false}
            width={1200}
            height={300}
            showFooter
          >
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onChange={this.onCropChange}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
            />
          </CustomModal>
        )}
        <CustomModal
          title={intl.formatMessage({ id: 'delete_image' })}
          open={isDeleteModalOpen}
          onOk={() => { this.setState({ isDeleteModalOpen: false }) }}
          onCancel={() => { this.setState({ isDeleteModalOpen: false }) }}
          closable={false}
          footer={null}
        >
          <>
            <StyledText as="p" variant="B3" className="title">
              {intl.formatMessage({ id: 'click_delete_to_remove_this_image' })}
            </StyledText>
            <div>
              <div className='modalFooterButton'>
                <Button
                  variant="secondary"
                  htmlType="button"
                  onClick={() => { this.setState({ isDeleteModalOpen: false }) }}
                >
                  {intl.formatMessage({ id: "cancel" })}
                </Button>
                <AntButton
                  variant="primary"
                  htmlType="button"
                  className='deleteButton'
                  onClick={() => this.deleteImage()}
                >
                  {intl.formatMessage({ id: "delete" })}
                </AntButton>
              </div>
            </div>
          </>
        </CustomModal>
      </div>
    );
  }
}

ImageCrop.propTypes = propTypes;
export default injectIntl(ImageCrop);
