import React from 'react'
import { useNavigate } from "react-router-dom";
import {
    Form, Input, Checkbox
} from 'antd';
import clsx from "clsx";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
    useAppDispatch,
} from "../../redux/store";
import { passwordValidator, setLocalStorageItem } from './../../utils/utilities';
import { getSignUpData } from '../../redux/actions';
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";

const SignUpPage = ({ className }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const baseClassName = clsx("SignUpPage", className);

    const onFinish = (values) => {
        const data = { email: values.email, password: values.password };
        setLocalStorageItem('signupEmail', values.email)
        dispatch(getSignUpData(data));
        navigate(`/signup-requested`);
    };

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleClick = () => {
        const mailtoLink = `mailto:hello@bizgiveworld.com`;
        window.location.href = mailtoLink;
    };

    return (
        <div className={baseClassName}>
            <div className="wrap">
                <div className="leftContainer">
                    <StyledText as="p" variant="H1a" className="signupTitle">
                        {intl.formatMessage({ id: 'connect_with_funders_and_design_your_opportunities_to_create_positive_impact' })}
                    </StyledText>
                    <div className='signUpDetailsContainer'>
                        <div className='textContainer'>
                            <StyledText as="p" variant="H2" className="signupTitle">
                                {intl.formatMessage({ id: 'sign_up' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="signupTitle">
                                {intl.formatMessage({ id: 'or_login_to_create_your_free_BizGive_account' })}
                            </StyledText>
                        </div>
                        <div className='textContainer'>
                            <StyledText as="p" variant="H2" className="signupTitle">
                                {intl.formatMessage({ id: 'discover' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="signupTitle">
                                {intl.formatMessage({ id: 'funding_programmes_and_create_a_profile_to_receive_alerts' })}
                            </StyledText>
                        </div>
                        <div className='textContainer'>
                            <StyledText as="p" variant="H2" className="signupTitle">
                                {intl.formatMessage({ id: 'submit' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="signupTitle">
                                {intl.formatMessage({ id: 'applications_auto_verified_against_funder_criteria' })}
                            </StyledText>
                        </div>
                        <div className='textContainer'>
                            <StyledText as="p" variant="H2" className="signupTitle">
                                {intl.formatMessage({ id: 'share' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="signupTitle">
                                {intl.formatMessage({ id: 'content_track_impact_and_capture_funder_feedback' })}
                            </StyledText>
                        </div>
                    </div>
                </div>
                <div className="fright">
                    <div className='loginContainer'>
                        <StyledText as="p" variant="H1" className="title">
                            {intl.formatMessage({ id: 'create_an_account_to_apply_for_support' })}
                        </StyledText>
                        <div className='signUpContainer'>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'are_you_impact_funder_contact_us' })}
                            </StyledText>
                            <button className='hereButton' onClick={handleClick}><StyledText as="p" variant="B3" className="signUp">
                                {intl.formatMessage({ id: 'here' })}
                            </StyledText></button>
                        </div>
                        <Form form={form} onFinishFailed={onFinishFailed} name="normal_login" className="login-form" initialValues={{ remember: true, }} onFinish={onFinish} >
                            <Form.Item name="email" hasFeedback rules={[
                                {
                                    type: 'email',
                                    message: intl.formatMessage({ id: 'the_input_is_not_valid_email' }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'please_enter_email' }),
                                },
                            ]}
                            >
                                <Input placeholder="E-mail" />
                            </Form.Item>
                            <Form.Item name="confirmEmail" hasFeedback dependencies={['email']} rules={[
                                {
                                    type: 'email',
                                    message: intl.formatMessage({ id: 'the_input_is_not_valid_email' }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'please_enter_email' }),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('email') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(`${intl.formatMessage({ id: 'the_new_email_that_you_entered_do_not_match' })}`));
                                    },
                                }),
                            ]}
                            >
                                <Input placeholder={intl.formatMessage({ id: 'confirm_email' })} />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({ id: "please_enter_password" }),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (passwordValidator(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(intl.formatMessage({ id: "please_enter_a_valid_password" }))
                                            );
                                        },
                                    }),
                                ]}
                                hasFeedback >
                                <Input.Password placeholder={intl.formatMessage({ id: 'password' })} />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({ id: "please_confirm_your_password" }),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(`${intl.formatMessage({ id: 'the_new_password_that_you_entered_do_not_match' })}`));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder={intl.formatMessage({ id: 'confirm_password' })} />
                            </Form.Item>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'password_must_include' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'more_than_eight_characters' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'an_upper_and_lower_case_character' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'a_number' })}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'a_special_character' })}
                            </StyledText>
                            <div className="checkBoxWrapper">
                                <Form.Item
                                    valuePropName="checked"
                                    name="user_terms"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({ id: 'please_accept_the_user_terms' }),
                                        },
                                    ]}
                                >
                                    <Checkbox />
                                </Form.Item>
                                <StyledText as="p" variant="B3" className='terms'>
                                    {intl.formatMessage({ id: "i_accept_the" })}
                                    <a
                                        href="https://bizgiveworld.com/user-terms/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className='companyLinks'
                                    >
                                        {intl.formatMessage({ id: "user_terms" })}
                                    </a>
                                </StyledText>
                            </div>

                            <Button variant="primary" htmlType="submit" className="loginButton">
                                {intl.formatMessage({ id: "register" })}
                            </Button>
                        </Form>
                    </div>
                    <div className='userTermsContainer'>
                        <StyledText as="p" variant="H2" className="title">
                            <a href="https://bizgiveworld.com/user-terms/" target='_blank' rel="noreferrer">{intl.formatMessage({ id: 'user_terms' })}</a>
                        </StyledText>
                        <StyledText as="p" variant="H2" className="signUp">
                            <a href="https://bizgiveworld.com/privacy-policy/" target='_blank' rel="noreferrer">{intl.formatMessage({ id: 'privacy_policy' })}</a>
                        </StyledText>
                    </div>
                </div>
            </div>
        </div >
    );
}
const StyledSignUpPage = styled(SignUpPage)`
&.SignUpPage{
    .wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media only screen and (max-width: ${props => props.theme.breakpoints?.medium}) {
          flex-direction: column;
        }
        }
        .leftContainer {
            float:left; 
            width: 56%;
            height: 400px;
            padding-block: 35px;
            margin-top: 5%;
            @media only screen and (max-width: ${props => props.theme.breakpoints?.medium}) {
                height: fit-content;
            }
            .signupTitle{
                padding-bottom: 15px;
            }
            .signUpDetailsContainer{
                display: flex;
                flex-direction: column;
                gap: 15px;
                .textContainer{
                    display: flex;
                    gap: 5px;
                    align-items: center;
                }
            }
        }
        .fright {
            float: right;
            height: 95vh;
            width: 40%;
            background : #00aa9d;
            padding-right: 15px;
            padding-top: 15px;
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media only screen and (max-width: ${props => props.theme.breakpoints?.medium}) {
                height: 87vh;
                width: 100%;
                right: 18px;
                position: relative;
            }
        }
        .loginContainer{
            background : white;
            padding: 20px;
            border-radius: 10px;
            gap: 10px;
            display: flex;
            flex-direction: column;
            margin-top: 20%;
            @media only screen and (max-width: ${props => props.theme.breakpoints?.medium}) {
                margin-top: 5%;
            }
            .signUpContainer{
                display: flex;
                .signUp{
                    padding-left: 4px;                
                }
                .hereButton{
                    cursor: pointer;
                    border: 0;
                    background: transparent;
                    padding-inline: 0;
                    text-decoration: underline;
                    color: #04ac9c;
                    margin-bottom: 4px;
                    margin-top: -1px;
                    p{
                        color: #04ac9c
                    }
                }
            }
            .loginButton{
                margin-top: 20px;
            }
        }
        .userTermsContainer{
            display: flex;
            bottom: 0;
            bottom: 0;
            justify-content: space-between;
            margin-block: 20px;
            p, a{
                color: #FFFFFF;
            }
        }
        .checkBoxWrapper{
            display: flex;
            align-items: center;
            gap: 10px;   
            position: relative;
            .terms{
                position: absolute;
                left: 25px;
                top: 5px;
            }
            .ant-form-item{
                margin-bottom: 0;
            }       
            .companyLinks {
              margin-left: 5px;
              text-decoration: underline;
              color: #04ac9c;
            }
          }
}
`;

export default StyledSignUpPage;