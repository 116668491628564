import React from "react";
import {
    Modal
} from 'antd';
import clsx from "clsx";
import styled from "styled-components";
import StyledText from "./StyledText";

const CustomModal = ({ title, className, children, showFooter, ...rest }) => {

    const baseClassName = clsx(".customModal", className);

    const restProps = {
        ...(!showFooter && {
            footer: null,
        })
    }

    return (
        <Modal
            className={baseClassName}
            title={
                <StyledText as="p" variant="H1" className="customModalTitle">
                    {title}
                </StyledText>
            }
            {...restProps}
            {...rest}
        >
            {children}
        </Modal>
    );
};

const StyledCustomModal = styled(CustomModal)`
.customModal{
    .signUp{
        margin-bottom: 20px;
    }
}
  `;

export default StyledCustomModal;

