import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Tabs, Row, Col } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
  useAppDispatch,
  useAppSelector
} from "../../redux/store";
import {
  allAssetsSelector,
  assetsSelector
} from "../../redux/selectors";
import {
  getAssets,
} from "../../redux/actions";
import StyledText from "../../components/StyledText";
import CustomSelect from "../../components/CustomSelect";
import Insights from "./Insights";
import Data from "./Data";

const DashboardPage = ({ className }) => {
  const intl = useIntl();
  const baseClassName = clsx("dashboardPage", className);
  const [phaseID, setPhaseID] = useState('');
  const [submissionType, setSubmissionType] = useState('');
  const dispatch = useAppDispatch();
  const assetId = sessionStorage.getItem('assetID');
  const phaseId = sessionStorage.getItem('phaseId');
  const submissionTypeSaved = sessionStorage.getItem('submissionType');
  const allAssets = useAppSelector(allAssetsSelector);
  const assets = useAppSelector(assetsSelector);
  const { phaseList } = assets?.[0] || {};

  useEffect(() => {
    if (assetId) {
      dispatch(getAssets({ assetID: assetId }));
    }
  }, [assetId])

  const submitterTypeOptions = [
    {
      key: 'overview',
      label: 'Overview',
      value: 'company/submissions',
    },
    {
      key: 'idea',
      label: 'Idea',
      value: 'company/submissions/ideas',
    },
    {
      key: 'feedback',
      label: 'Feedback',
      value: 'company/submissions/feedback',
    },
    {
      key: 'collaborate',
      label: 'Collaborate',
      value: 'company/submissions/collaborate',
    },
  ]

  const onSubmissionTypeChange = (id) => {
    const val = submitterTypeOptions.find((el) => el.value === id)?.label;
    sessionStorage.setItem('submissionType', val);
    setSubmissionType(id)
    window.open(`${window.location.origin}/${id}`, '_self');
  }

  const onChange = (id) => {
    sessionStorage.setItem('assetID', id);
    sessionStorage.removeItem('phaseId');
    sessionStorage.removeItem('submissionType');
    setPhaseID('')
    setSubmissionType('')
    dispatch(getAssets({ assetID: id }));
  };

  const generateDropdownOptions = (data, keyField) => (
    data?.map((item, index) => ({
      key: index,
      label: item[keyField],
      value: item.ID || item.phaseName,
    }))
  );

  const onPhaseChange = (id) => {
    sessionStorage.setItem('phaseId', id);
    setPhaseID(id)
  };

  const items = [
    {
      key: "ideas",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "insights" })}
        </StyledText>
      ),
      children: <Insights />,
    },
    {
      key: "ideas-data",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "data" })}
        </StyledText>
      ),
      children: <Data />,
    },
  ];

  const handleChangeRoute = (key) => {
    window.open(`${window.location.origin}/company/submissions/${key}`, '_self');
  };

  const nameDropdownOptions = generateDropdownOptions(allAssets, 'name');
  const phaseListDropdownOptions = generateDropdownOptions(phaseList, 'phaseName');
  const activeKey = window.location.pathname.includes('ideas-data') ? 'ideas-data' : 'ideas';

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Dashboard Ideas Page</title>
      </HelmetAsync>
      <StyledText as="p" variant="H1" className="title">
        {intl.formatMessage({ id: "ideas" })}
      </StyledText>
      <Row className="filters">
        <Col span={5}>
          <CustomSelect type="formSelect" onChange={onChange} placeholder={intl.formatMessage({ id: "asset" })} options={nameDropdownOptions} value={assetId} />
        </Col>
        <Col span={5}>
          <CustomSelect
            type="formSelect"
            onChange={onPhaseChange}
            options={[
              { key: "all", label: "All", value: "All" },
              ...(phaseListDropdownOptions ?? []),
            ]}
            placeholder={intl.formatMessage({ id: "phase" })}
            value={phaseID || phaseId}
            disabled={!phaseListDropdownOptions}
          />
        </Col>
        <Col span={5}>
          <CustomSelect type="formSelect" value={submissionType || submissionTypeSaved} onChange={onSubmissionTypeChange} placeholder={intl.formatMessage({ id: "submission_type" })} options={submitterTypeOptions} />
        </Col>
      </Row>
      <Tabs defaultActiveKey={activeKey} items={items} onChange={handleChangeRoute} />
    </div>
  );
};

const StyledDashboardPage = styled(DashboardPage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
    .filters{
      margin: 20px 0px;
    }
  }
`;

export default StyledDashboardPage;
