import React from 'react';
import { Helmet as HelmetAsync } from "react-helmet-async";
import clsx from "clsx";
import { Tabs, Layout, Divider, Timeline } from 'antd';
import { useIntl } from 'react-intl';
import styled from "styled-components";
import {
    useAppSelector
} from "../../redux/store";
import {
    programDataSelector
} from "../../redux/selectors";
import ProgramReview from './ProgramReview';
import StyledText from "../../components/StyledText";
import Button from '../../components/Button';
import ConfigDev from "../../Config.dev";
import {
    EnvironmentFilled,
    CheckOutlined,
    StarFilled,
    CalendarFilled
} from "@ant-design/icons";
import DateFormatter from "../../components/DateFormatter";

const getUpdateDueDateMessage = ({
    updateDueDateRelativeDays,
    updateDueDate,
    currentBudgetStartDate,
    currentBudgetEndDate
}) => {
    if (updateDueDateRelativeDays) {
        if (currentBudgetStartDate && currentBudgetEndDate) {
            return `Every ${updateDueDateRelativeDays} days during the project running dates`;
        } else {
            return `${updateDueDateRelativeDays} days after the project start date`;
        }
    } else if (updateDueDate) {
        return updateDueDate.join(', ');
    } else {
        return 'Not Required';
    }
}

const ProgramReviewHomePage = ({ className }) => {
    const baseClassName = clsx("dashboardPage", className);
    const programData = useAppSelector(programDataSelector);
    const intl = useIntl();

    const {
        currentBudgetStartDate,
        currentBudgetEndDate,
        opportunityLocation,
        programRadius,
        acceptStartDate,
        acceptEndDate,
        donationSubmitPeriod,
        donationSubmitStartDate,
        donationSubmitEndDate,
        updateDueDate,
        updateDueDateRelativeDays,
        outComeDate,
        outcomeDueDateRelativeDays,
        entitiesSupported,
        opportunityCriteriaChecklist,
        requiredDocuments,
        companyName,
        programName,
        applicationAcceptPeriod
    } = programData || {};

    const updateDueDateMessage = getUpdateDueDateMessage({
        updateDueDateRelativeDays,
        updateDueDate,
        currentBudgetStartDate,
        currentBudgetEndDate
    });

    const items = [
        {
            key: "programme",
            label: <StyledText as="p" variant="B3" className="title">
                {intl.formatMessage({ id: "programme" })}
            </StyledText>,
            children: <ProgramReview />,
        },
    ];

    return (
        <div className={baseClassName}>
            <HelmetAsync>
                <title>Programme Review Page</title>
            </HelmetAsync>
            <div className='leftContainer'>
                <div >
                    <Layout className="bannerImageContainer">
                        <img src="check" alt='bannerImageContainer'></img>
                    </Layout>
                    <div className='companyTitleContainer'>
                        <div className='imageContainer'></div>
                        <div>
                            <StyledText as="p" variant="H1c" className="title">
                                {programName}
                            </StyledText>
                            <StyledText as="p" variant="H3" className="title">
                                {companyName}
                            </StyledText>
                        </div>
                        <div><Button variant="primary" className=''>{intl.formatMessage({ id: "manage_title" })}</Button></div>
                    </div>
                </div>
                <Tabs defaultActiveKey={items[0]} items={items} />
            </div>
            <div className="rightSideContainer">
                {opportunityLocation?.[0] && <iframe
                    height="300"
                    className="mapIFrameStyle"
                    width="500"
                    title="map"
                    src={`${ConfigDev.embedMapUrl + ConfigDev.googleMapApiKey}&q=${opportunityLocation?.[0]}`}
                />}
                <div className="rightContainer">
                    <div className="locationContainer subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "location_supported" })}
                        </StyledText>
                        {opportunityLocation?.map((item) => <div key={item} className="locationIcon">
                            <EnvironmentFilled />
                            <StyledText as="p" variant="B3" key={item} className="city">
                                {item === 'anyLocation'
                                    ? intl.formatMessage({ id: 'any_location' })
                                    : item}
                            </StyledText>
                        </div>)}
                        <StyledText as="p" variant="B3" className="locationDistance">
                            {intl.formatMessage({ id: "project_must_be_within" })} {programRadius} KM {intl.formatMessage({ id: "of_this_location" })}
                        </StyledText>
                    </div>
                    <Divider />
                    <StyledText as="p" variant="H2" className="subContainer">
                        {intl.formatMessage({ id: "programme_dates" })}
                    </StyledText>
                    <Timeline
                        items={[
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "application_accepted_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /> <StyledText as="p" variant="B3c">
                                                {acceptStartDate?.trim() && acceptEndDate?.trim() ? (
                                                    <>
                                                        <DateFormatter dateValue={acceptStartDate?.trim()} /> - <DateFormatter dateValue={acceptEndDate?.trim()} />
                                                    </>
                                                ) : (
                                                    applicationAcceptPeriod
                                                )}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "donation_to_be_made_between" })}
                                    </StyledText>
                                        <div className="locationIcon"><CalendarFilled style={{
                                            color: '#0278C7'
                                        }} /><StyledText as="p" variant="B3c">
                                                {donationSubmitPeriod || <>
                                                    <DateFormatter dateValue={donationSubmitStartDate?.trim()} /> - <DateFormatter dateValue={donationSubmitEndDate?.trim()} />
                                                </>}
                                            </StyledText></div>
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "interim_update_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {updateDueDateMessage}
                                        </StyledText>}
                                    </>
                                ),
                            },
                            {
                                color: '#E6B100',
                                dot: <StarFilled />,
                                children: (
                                    <><StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "final_report_requested" })}
                                    </StyledText>
                                        {<StyledText as="p" variant="B3c">
                                            {outComeDate?.trim() ? <DateFormatter dateValue={outComeDate?.trim()} /> : `${outcomeDueDateRelativeDays} days after the project end date`}
                                        </StyledText>}
                                    </>
                                ),
                            },
                        ]}
                    />
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "application_criteria" })}
                        </StyledText>
                        <StyledText as="p" variant="B3c">
                            {intl.formatMessage({ id: "entities_supported" })}
                        </StyledText>
                        {entitiesSupported?.length > 0 ? <ul>{entitiesSupported?.map((el) => <li key={el}> <StyledText as="span" variant="B3">
                            {el}
                        </StyledText></li>)}</ul> :
                            <div className="locationIcon">
                                <CheckOutlined />
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "any" })}
                                </StyledText>
                            </div>}
                    </div>
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "eligibility_checklist" })}
                        </StyledText>
                        {opportunityCriteriaChecklist?.length > 0 ? <ul>{opportunityCriteriaChecklist?.map((el) => <li key={el}> <StyledText as="span" variant="B3">
                            {el}
                        </StyledText></li>)}</ul> : <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "none" })}
                        </StyledText>}
                    </div>
                    <Divider />
                    <div className="subContainer">
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "documentation_required" })}
                        </StyledText>
                        <div className="locationIcon">
                            {requiredDocuments?.length > 0 ? <ul>{requiredDocuments?.map((el) => <li key={el}> <StyledText as="span" variant="B3">
                                {el}
                            </StyledText></li>)}</ul> : <><CheckOutlined /><StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "none" })}
                            </StyledText></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledDashboardPage = styled(ProgramReviewHomePage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    gap: 20px;

    .ant-tabs-tab-active .title {
      color: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }

    .bannerImageContainer {
      width: 100%;
      height: 216px;
      overflow: hidden;
      position: relative;
      background: white;
      border-radius: 10px;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      gap: 35px;
      overflow: scroll;
      height: 90vh;
      width: 95%;

      svg {
        fill: #04ac9c !important;
      }
    }

    .companyTitleContainer {
      display: flex;
      padding: 10px 0px 20px 200px;
      justify-content: space-between;
      position: relative;
      align-items: center;

      .imageContainer {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        border: 1px solid black;
        background-color: grey;
        top: -80px;
        left: 25px;
        position: absolute;
      }
    }

    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    ul {
      margin: 0;
    }

    .ant-collapse-expand-icon {
      color: #313131 !important;
    }

    .mapIFrameStyle {
      border: 0;
    }

    .locationIcon {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .locationContainer {
      .locationDistance {
        font-style: italic;
      }

      svg {
        color: #f96675;
      }
    }

    .subContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .descriptionContainer {
        p, li {
          font-size: 14px;
          font-weight: 500;
          color: #242424;
          font-family: sans-serif;
        }
      }
    }

    .ant-timeline-item-head {
      background: transparent !important;
    }

    .anticon-check {
      color: #58b55e;
    }

    .rightSideContainer {
      overflow: scroll;
      height: 90vh;

      .rightContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background: white;
        padding: 12px 16px;
      }
    }

    .ant-collapse {
      border: 0;
      border-bottom: 1px solid #D7DDE1;
      border-radius: 0px;

      .ant-collapse-header {
        background: white !important;
      }
    }

    .ant-collapse-content-active {
      border-top: 0;
    }
  }
`;

export default StyledDashboardPage;


