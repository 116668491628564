import React from "react";
import clsx from "clsx";
import { Avatar } from "antd";
import { useIntl } from "react-intl";
import styled from "styled-components";
import * as constants from "../../constants";
import StyledText from "../../components/StyledText";
import { getLocalStorageItem } from './../../utils/utilities';
import sendIcon from "../../assets/send.svg";

const SignUpRequested = ({ className }) => {
    const baseClassName = clsx("signupRequested", className);
    const intl = useIntl();
    const signupEmail = getLocalStorageItem("signupEmail");

    return (
        <div className={baseClassName}>
            <StyledText as="p" variant="T2" className="title">
                {intl.formatMessage({ id: "forgot_password_header" })}
            </StyledText>
            <Avatar shape="square" src={sendIcon} className="sendIcon" />
            <StyledText as="p" variant="H2">
                {`${intl.formatMessage({ id: "we_just_emailed" })} ${signupEmail}`}
            </StyledText>
            <StyledText as="p" variant="B2">
                {intl.formatMessage({ id: "please_click_on_the_link_in_your_email_to_activate_your_account" })}
            </StyledText>
        </div>
    );
};

const StyledSignUpRequested = styled(SignUpRequested)`
  &.signupRequested {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .title{
        color: ${constants.COLOR_BIZ_GIVE_THEME};
    }
    .sendIcon{
        height: 100px;
        width: 100px;
    }
  }
`;

export default StyledSignUpRequested;
