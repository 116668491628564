import React, { useState } from 'react';
import { Helmet as HelmetAsync } from "react-helmet-async";
import clsx from "clsx";
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';
import styled from "styled-components";
import CompanyDonation from './companyDonation';
import CompanyDonationBudget from './companyDonationBudget';
import StyledText from "../../components/StyledText";

const CompanyDonationTabHomePage = ({ className }) => {
  const baseClassName = clsx("dashboardPage", className);
  const [activeTab, setActiveTab] = useState('1');

  const handleChange = (e) => {
    setActiveTab(e);
  };
  const intl = useIntl();

  const items = [
    {
      key: "donations",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "donations" })}
        </StyledText>
      ),
      children: <CompanyDonation />,
    },
    {
      key: "budget_history",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "budget_history" })}
        </StyledText>
      ),
      children: <CompanyDonationBudget />,
    },
  ];

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Company Donations Page</title>
      </HelmetAsync>
      <StyledText as="p" variant="H1" className="title">
        {intl.formatMessage({ id: "donations" })}
      </StyledText>
      <Tabs defaultActiveKey={activeTab} items={items} onChange={handleChange} />
    </div>
  );
};

const StyledDashboardPage = styled(CompanyDonationTabHomePage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
  }
`;

export default StyledDashboardPage;
