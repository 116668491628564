import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { jwtDecode } from 'jwt-decode'
import { Helmet as HelmetAsync, HelmetProvider } from "react-helmet-async";
import { useIdleTimer } from 'react-idle-timer';
import { CHARITY, COMPANY } from './constants';
import { getBrowserLocale, millisecondsToMinutesSeconds, getLocalStorageItem, setLocalStorageItem, decodeString } from "./utils/utilities";
import { getLoginData, getProfileData, getProfileDataCompany, resetState } from './redux/actions';
import { loginSelector } from "./redux/selectors";
import { useAppDispatch, useAppSelector } from "./redux/store";
import locale from "./locale/locale";
import RootRoute from "./RootRoute";
import TimeOutModal from "./components/TimeOutModal";

const App = () => {
  const browserLocale = getBrowserLocale();
  const messages = locale?.[browserLocale];
  const [userType, setUserType] = useState(null);
  const useLocale = browserLocale && messages;
  const loginObj = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);

  const { IDToken } = loginObj || {};

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    debounce: 500,
    onIdle: () => {
      window.open(`${window.location.origin}/login?loggedOut`, '_self');
      localStorage.clear();
      sessionStorage.clear();
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getRemainingTime();
      if (remaining < 1000 * 60 * 5) {
        setRemainingTime(remaining);
        setShowTimeoutModal(true);
      } else {
        setShowTimeoutModal(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [getRemainingTime]);

  const handleCharityLoginRoutes = () => {
    dispatch(getProfileData());
  };

  const handleCompanyLoginRoutes = () => {
    dispatch(getProfileDataCompany());
  };

  useEffect(() => {
    if (IDToken) {
      const decodedToken = jwtDecode(loginObj.IDToken);
      setLocalStorageItem('device_key', loginObj['DeviceKey']);
      setLocalStorageItem('userType', decodedToken["custom:entity"]);
      setLocalStorageItem('companyID', decodedToken["custom:entityID"]);
      setLocalStorageItem('id_Token', loginObj['IDToken']);
      setLocalStorageItem('user_details', decodedToken["email"]);
      const userType = getLocalStorageItem('userType');
      if (userType === CHARITY) {
        setUserType(CHARITY);
        handleCharityLoginRoutes();
      }
      if (userType === COMPANY) {
        setUserType(COMPANY);
        handleCompanyLoginRoutes();
      }
    }
  }, [IDToken, dispatch]);

  const handleLogoutClick = () => {
    dispatch(resetState());
    localStorage.clear();
    sessionStorage.clear();
    window.open(`${window.location.origin}/login`, '_self');
    setShowTimeoutModal(false);
  };

  const handleStayLoggedInClick = () => {
    const data = JSON.parse(getLocalStorageItem('userDetails'));
    const loginData = {
      email: data.email,
      password: decodeString(data.password)
    };
    dispatch(getLoginData(loginData));
    reset();
  };

  return (
    <HelmetProvider>
      <HelmetAsync>
        <title>Biz Give</title>
      </HelmetAsync>
      <IntlProvider
        locale={browserLocale}
        messages={useLocale ? messages : locale?.en}
      >
        <RootRoute userType={userType} />
        {showTimeoutModal && (
          <TimeOutModal
            handleLogoutClick={handleLogoutClick}
            handleStayLoggedInClick={handleStayLoggedInClick}
            remainingTime={millisecondsToMinutesSeconds(remainingTime)}
            showTimeoutModal={showTimeoutModal}
          />
        )}
      </IntlProvider>
    </HelmetProvider>
  );
};

export default App;
