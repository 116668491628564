import axios from 'axios';

const get = (url, cb) => {
  const guestSession = window.sessionStorage.getItem('guestReview');
  const token = (guestSession === 'true') ? localStorage.getItem('guest_Token') : localStorage.getItem('id_Token');
  if (token !== null && token !== 'null') {
    axios.get(url, {
      headers: { Authorization: token },
    })
      .then((res) => {
        cb(res);
      }).catch((err) => {
        cb(null, err);
      });
  }
};

const getImage = (url, cb, obj, filename) => {
  const a = 'image/jpeg';
  const guestSession = window.sessionStorage.getItem('guestReview');
  const token = (guestSession === 'true') ? localStorage.getItem('guest_Token') : localStorage.getItem('id_Token');
  if (!url?.includes('.zip')) {
    try {
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          Authorization: token,
          'Content-Type': a

        })
      })
        .then((response) => response.blob())
        .then((blob) => {
          cb(blob, obj);
        })
        .catch((error) => {
          cb('', obj, error);
        });
    } catch (err) {
      console.log('Error', err);
    }
  }
};

const put = (url, cb, body) => {
  const guestSession = window.sessionStorage.getItem('guestReview');
  const token = (guestSession === 'true') ? localStorage.getItem('guest_Token') : localStorage.getItem('id_Token');
  if (token !== null && token !== 'null') {
    axios.post(url, body, {
      headers: { Authorization: token }
    })
      .then((res) => {
        cb(res);
      }).catch((err) => {
        if (err.response) { cb(null, err.response.data); } else { cb(null, err.response); }
      });
  }
};


const post = (url, cb, body, header) => {
  const headers = header
    ? { Authorization: localStorage.getItem('id_Token') }
    : {};
  axios.post(url, body, { headers })
    .then((res) => {
      cb(res);
    }).catch((err) => {
      if (err.response) { cb(null, err.response.data); } else { cb(null, err.response); }
    });
};

const uploadFile = (url, postData, callBackFn, configObj) => {
  axios.post(url, postData, configObj)
    .then((res) => {
      callBackFn(res);
    }).catch((err) => {
      callBackFn(null, err);
    });
};

const axiosTypes = {
  get,
  put,
  post,
  getImage,
  uploadFile,
};
export default axiosTypes;
