import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from "clsx";
import { useIntl } from 'react-intl';
import {
  Layout,
  Form,
  Spin,
  Dropdown,
  Menu,
  Table,
  Drawer,
  Row,
  Col,
  Input,
  DatePicker,
  message,
  Upload,
  Checkbox,
  Button as AntButton
} from 'antd';
import styled from "styled-components";
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  donationSelector,
  donationListSelector,
  opportunitiesOptionsSelector,
  toggleSpinnerSelector
} from "../../redux/selectors";
import {
  useAppDispatch,
  useAppSelector
} from "../../redux/store";
import Button from "../../components/Button"
import Select from "../../components/CustomSelect"
import axios from '../../utils/axios';
import Modal from "../../components/Modal";
import Config from '../../config';
import {
  setDonationListData as setDonationList,
  setDonationTwo as setDonation,
  setOpportunitiesDropDownAction as setOpportunitiesDropDown,
  toggleSpinner,
  setOpportunitiesDropDownAction
} from '../../redux/actions';
import Arrow from '../../assets/arrow.svg';
import crossCircle from '../../assets/crossCircle.svg';
import StyledText from '../../components/StyledText';

const getSymbolFromCurrency = require('currency-symbol-map');

message.config({
  top: 100,
  duration: 6,
  maxCount: 3,
});

const FormItem = Form.Item;

const uploaderProps = {
  name: 'file',
  method: 'POST',
  disabled: false,
  showUploadList: {
    showPreviewIcon: true,
    showRemoveIcon: true,
    showDownloadIcon: false,
  },
  defaultUploadFileList: [],
  listType: 'text',
};

const shortenString = (str = '') => {
  if (str?.length <= 15) {
    return str;
  }
  return `${str?.substring(0, 15)}...`;
};

const statusDropDownValues = [
  'All',
  'Saved (Private)',
  'Planned (Shared)',
  'Invoice Requested',
  'Invoice Received',
  'Reported',
  'Confirmed',
];
const filterDropDownValues = [
  'Requiring My Action',
  'Awaiting Counterpart Action',
];

const getColumnData = (list, currencyMarker) => {
  if (!list || list.length === 0) {
    return null;
  }

  return list?.map((item, idx) => {
    const {
      programName,
      grantTotalProgPlannedCurrentPeriod,
      grantTotalProgReportedCurrentPeriod,
      opportunity,
      volunteeringTotalProg,
      inkindTotalProg,
      programID,
      remainingBudgetCurrentPeriod,
      currencySymbol
    } = item || {};

    const formatValue = (value) => (value ? `${currencySymbol || currencyMarker} ${value}` : '');

    const formatBudgetEndDate = (date) => {
      if (date?.trim()) {
        return date.trim().replaceAll('/', '-');
      }
      return '';
    };
    return {
      key: idx,
      programName,
      grantTotalProgReportedCurrentPeriod: formatValue(grantTotalProgReportedCurrentPeriod),
      grantTotalProgPlannedCurrentPeriod: formatValue(grantTotalProgPlannedCurrentPeriod),
      remainingBudgetCurrentPeriod: formatValue(remainingBudgetCurrentPeriod),
      budgetAmount: formatValue(opportunity?.[0]?.donations?.[0]?.budgetAmount),
      volunteeringTotalProg: volunteeringTotalProg ? `${volunteeringTotalProg} days` : '',
      inkindTotalProg: inkindTotalProg ? `${currencySymbol || currencyMarker} ${inkindTotalProg}` : '',
      currentBudgetEndDate:
        formatBudgetEndDate(opportunity?.[0]?.donations?.[0]?.currentBudgetEndDate),
      programID,
      currencySymbol: currencySymbol || currencyMarker,
    };
  });
};

const getOpportunityColumnData = (list, currencyMarker) => {
  if (list?.length > 0) {
    return list?.map((item, idx) => {
      const {
        opportunityName,
        grantTotalOpp,
        volunteeringTotalOpp,
        inkindTotalOpp,
        opportunityID,
        donations,
        currencySymbol
      } = item || {};
      return {
        key: idx,
        opportunityName,
        grantTotalOpp: grantTotalOpp ? `${currencySymbol || currencyMarker} ${grantTotalOpp}` : '',
        volunteeringTotalOpp: volunteeringTotalOpp
          ? `${volunteeringTotalOpp} days`
          : '',
        inkindTotalOpp: inkindTotalOpp ? `${currencySymbol || currencyMarker} ${inkindTotalOpp}` : '',
        opportunityID,
        charityName: donations?.[0]?.charityName,
        currencySymbol: currencySymbol || currencyMarker
      };
    });
  }
  return null;
};

const getOpportunityRowData = (list, currencyMarker) => {
  if (!list || list.length === 0) {
    return null;
  }

  return list?.map((item, idx) => {
    const {
      donationAmount,
      type,
      invoiceFile,
      comment,
      dueDate,
      reportedDate,
      status,
      companyNextAction,
      charityName,
      opportunityName,
      currencySymbol
    } = item || {};

    const formatValue = (value) => (value ? `${currencySymbol || currencyMarker} ${value}` : '');
    const formatDate = (date) => `${date?.trim() ? moment(date?.trim()).format('YYYY-MM-DD') : ''}`;
    return {
      key: idx,
      donationAmount: type === 'Volunteering' && donationAmount ? `${donationAmount} days` : formatValue(donationAmount),
      type,
      invoiceFile,
      comment,
      dueDate: formatDate(dueDate),
      reportedDate: formatDate(reportedDate),
      status,
      companyNextAction,
      charityName,
      opportunityName,
      edit: { ...item },
      currencySymbol
    };
  });
};

const getCurrencySymbol = (tempProgramData) => {
  const data = tempProgramData?.split('-');
  const code = data?.[0]?.trim();
  return `${getSymbolFromCurrency(code)}`;
};

const CompanyDonationHomePage = ({ className }) => {
  const intl = useIntl();
  const [filterId, setFilterId] = useState('');
  const [roundOptions, setRoundOptions] = useState([]);
  const [drawerRoundOptions, setDrawerRoundOptions] = useState([]);
  const [drawerOpportunityOptions, setDrawerOpportunityOptions] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [showEditDonationModal, setShowEditDonationModal] = useState(false);
  const [actionFields, setActionFields] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeletePurchaseFileModal, setShowDeletePurchaseFileModal] = useState(false);
  const [showDeleteInvoiceFileModal, setShowDeleteInvoiceFileModal] = useState(false);
  const [selectedOpportunityData, setSelectedOpportunityData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [editView, setEditView] = useState(false);
  const [editFileView, setEditFileView] = useState(false);
  const [currencyMarker, setCurrencyMarker] = useState('');
  const [purchasedFileList, setPurchasedFileList] = useState([]);
  const [invoiceFileList, setInvoiceFileList] = useState([]);
  const [requestInVoiceCheckBox, setRequestInVoiceCheckBox] = useState({});
  const [reload, setReload] = useState(false);
  const dispatch = useAppDispatch();
  const donation = useAppSelector(donationSelector);
  const progListData = useAppSelector(donationListSelector);
  const opportunitiesOptions = useAppSelector(opportunitiesOptionsSelector);
  const showSpinner = useAppSelector(toggleSpinnerSelector);
  const [form] = Form.useForm();
  const [downloadForm] = Form.useForm();
  const [drawerForm] = Form.useForm();
  const drawerFormValues = drawerForm.getFieldsValue();
  const baseClassName = clsx("companyDonationTwo pageContainerReview  steadyFormOuter endPage", className);

  const { donationProgramID, donationRound } = drawerFormValues || {};

  useEffect(() => {
    drawerForm.setFieldsValue({
      donationOpportunityID: undefined,
      donationRound: undefined,
    });
  }, [donationProgramID])

  useEffect(() => {
    drawerForm.setFieldsValue({
      donationOpportunityID: undefined,
    });
  }, [donationRound])


  const typeDropDownOptions = [intl.formatMessage({ id: 'grant' }), intl.formatMessage({ id: 'inkind' }), intl.formatMessage({ id: 'volunteering' })];
  const actionDropDownOptions = [
    { key: 'Saved (Private)', value: intl.formatMessage({ id: 'save_private' }) },
    { key: 'Share donation details', value: intl.formatMessage({ id: 'planned_shared' }) },
    { key: 'Request invoice', value: intl.formatMessage({ id: 'invoice_requested' }) },
    { key: 'Report donation', value: intl.formatMessage({ id: 'report_donation' }), editView: true },
  ];
  const programColumns = [
    {
      title: intl.formatMessage({ id: 'programme' }),
      dataIndex: 'programName',
      key: 'programName',
      render: (text, record) => (
        <Link
          to={`/operations/company/programme/review/${record?.programID}`}
          target="_blank"
        >
          {text}
        </Link>
      ),
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reported_grants' }),
      dataIndex: 'grantTotalProgReportedCurrentPeriod',
      key: 'grantTotalProgReportedCurrentPeriod',
    },
    {
      title: intl.formatMessage({ id: 'planned_grants' }),
      dataIndex: 'grantTotalProgPlannedCurrentPeriod',
      key: 'grantTotalProgPlannedCurrentPeriod',
    },
    {
      title: intl.formatMessage({ id: 'remaining_budget' }),
      dataIndex: 'remainingBudgetCurrentPeriod',
      key: 'remainingBudgetCurrentPeriod',
    },
    {
      title: intl.formatMessage({ id: 'total_budget' }),
      dataIndex: 'budgetAmount',
      key: 'budgetAmount',
    },
    {
      title: intl.formatMessage({ id: 'volunteering' }),
      dataIndex: 'volunteeringTotalProg',
      key: 'volunteeringTotalProg',
    },
    { title: intl.formatMessage({ id: 'inkind' }), dataIndex: 'inkindTotalProg', key: 'inkindTotalProg' },
    {
      title: intl.formatMessage({ id: 'budget_period_end' }),
      dataIndex: 'currentBudgetEndDate',
      key: 'currentBudgetEndDate',
    },
  ];
  const downloadOptions = [
    {
      key: '',
      value: intl.formatMessage({ id: 'all' }),
    },
    {
      key: 'Saved (Private)',
      value: intl.formatMessage({ id: 'save_private' }),
    },
    {
      key: 'Planned (Shared)',
      value: intl.formatMessage({ id: 'planned_shared' }),
    },
    {
      key: 'Invoice Requested',
      value: intl.formatMessage({ id: 'invoice_requested' }),
    },
    {
      key: 'Invoice Received',
      value: intl.formatMessage({ id: 'invoice_received' }),
    },
    {
      key: 'Reported',
      value: intl.formatMessage({ id: 'reported_title' }),
    },
    {
      key: 'Confirmed',
      value: intl.formatMessage({ id: 'confirmed_title' }),
    },
  ];

  const values = form.getFieldsValue();

  useEffect(() => {
    if (progListData?.[0]?.currencySymbol) {
      setCurrencyMarker(progListData?.[0]?.currencySymbol)
    }
  }, [progListData]);

  const {
    companyNextAction,
    reportedDate,
    round,
    dueDate,
    paymentReference,
    type,
    donationAmount,
    comment,
    purchaseOrderFile,
    invoiceFile,
    edit: {
      programName
    } = {}
  } = selectedOpportunityData || {};

  useEffect(() => {
    if (companyNextAction) {
      const currencyMark = donation?.length > 0 && getCurrencySymbol(
        [
          ...new Map(
            donation.map((item) => [item.programName, item])
          ).values(),
        ].filter((item) => item?.programName
          === programName)?.[0]?.programOfferings?.cashGivingCurrencyCode
      );
      const reportDateTrimmed = reportedDate?.trim()
        ? moment(reportedDate?.trim(), 'YYYY-MM-DD')
        : '';
      drawerForm.setFieldsValue({
        donationAction: companyNextAction,
        donationReportedDate: reportDateTrimmed,
        donationProgramID: programName,
        donationRound: round,
        donationDueDate: dueDate?.trim()
          ? moment(dueDate?.trim(), 'YYYY-MM-DD')
          : '',
        donationPaymentReferenced: paymentReference?.trim() || '',
        donationType: type,
        donationAmount: formatDonationAmount(donationAmount, currencyMark),
        donationCondition: comment,
        donationPurchaseOrder: purchaseOrderFile?.[0]?.name || null,
        donationInvoiceFile: invoiceFile || null,
      });
    }
  }, [companyNextAction]);

  const queryString = window?.location?.href;
  const parameters = new URLSearchParams(queryString);
  const programIdQueryValue = parameters?.get('programId');
  const opportunityIDQueryalue = parameters?.get('opportunityID');

  const { programID, opportunityID, programmeRound } = values || {};

  const setData = () => {
    dispatch(toggleSpinner(true));
    const progUrl = Config.dev.url.getInteraction;
    const data = {
      sortBy: 'programName',
      asc: 'true',
      filterBy: { status: ['Approved', 'PreApproved', 'Completed'] },
    };
    axios.put(progUrl, setFilters, data, true);
  };

  const getProgrammeIDOptionsVerify = (programID, opportunityID, filterId) => programID && !opportunityID && !filterId

  const getRoundOptionsVerify = (programmeRound, programID, opportunityID, filterId) => programmeRound && programID && !opportunityID && !filterId

  const getProgrammeOptionsVerify = (programID, opportunityID, filterId) => programID && (opportunityID || filterId);

  const getInitialFilters = (filterId) => filterId === 'All' ? 'all' : filterId;

  const verifyFilterIdExits = (opportunityID, filterId) => !opportunityID && filterId

  const getProgrammeRound = (programmeRound) => programmeRound === 'All' ? undefined : programmeRound

  const setListData = () => {
    const values = form.getFieldsValue();
    dispatch(toggleSpinner(true));
    const { programID, opportunityID, programmeRound } = values || {};
    const programeRound = getProgrammeRound(programmeRound)
    const progUrl = Config.dev.url.getDonationsTwo;
    let val = getInitialFilters(filterId);
    let actionValueRequired = [];
    if (filterId === 'Requiring My Action') {
      actionValueRequired = [
        'Invoice Received',
        'Saved (Private)',
        'Planned (Shared)',
      ];
    } else if (filterId === 'Awaiting Counterpart Action') {
      actionValueRequired = ['Invoice Requested', 'Reported'];
    }
    if (actionValueRequired?.length > 0) {
      val = actionValueRequired;
    } else if (val) {
      val = [val];
    }
    if (getProgrammeOptionsVerify(programID, opportunityID, filterId)) {
      let payload;
      if (verifyFilterIdExits(opportunityID, filterId)) {
        payload = {
          programID,
          opportunityID: 'all',
          status: val,
          programmeRound: programeRound
        };
      } else if (opportunityID && !filterId) {
        payload = {
          programID,
          opportunityID,
          programmeRound: programeRound
        };
      } else {
        payload = {
          programID,
          opportunityID,
          status: val,
          programmeRound: programeRound
        };
      }
      axios.put(progUrl, setProgramListData, payload, true);
      if (programIdQueryValue) {
        const { href = '' } = window.location || {};
        const location = href?.substring(0, href?.indexOf('?'));
        window.location.href = location;
      }
    } else if (getRoundOptionsVerify(programeRound, programID, opportunityID, filterId)) {
      axios.put(progUrl, setProgramListDataAndOptions, {
        programID,
        programmeRound: programeRound
      }, true);
    } else if (getProgrammeIDOptionsVerify(programID, opportunityID, filterId)) {
      axios.put(progUrl, setProgramListRoundOptions, {
        programID
      }, true);
    } else if (!programIdQueryValue) {
      axios.put(progUrl, setProgramListData, true);
    }
    dispatch(toggleSpinner(false));
    setReload(false);
  };

  const setDrawerRoundOptionsResponse = (responseData) => {
    if (responseData) {
      setDrawerRoundOptions(responseData?.data?.uniqueProgrammeRound)
      dispatch(toggleSpinner(false));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const setDrawerOpportunityOptionsResponse = (responseData) => {
    if (responseData) {
      setDrawerOpportunityOptions(responseData?.data?.interaction)
      dispatch(toggleSpinner(false));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const setRoundData = (id) => {
    dispatch(toggleSpinner(true));
    const getInteraction = Config.dev.url.getInteraction;
    axios.put(getInteraction, setDrawerRoundOptionsResponse, {
      sortBy: "programName",
      asc: "true",
      action: "uniqueProgrammeRound",
      filterBy: {
        programID: id
      }
    }, true);
    axios.put(getInteraction, setDrawerOpportunityOptionsResponse, {
      sortBy: "programName",
      asc: "true",
      filterBy: {
        programID: id,
        status: [
          "Approved",
          "PreApproved",
          "Completed"
        ]
      }
    }, true);
    dispatch(toggleSpinner(false));
    setReload(false);
  };

  const setOpportunitiesData = (id) => {
    const values = drawerForm.getFieldsValue();
    dispatch(toggleSpinner(true));
    const { donationProgramID } = values || {};
    const getInteraction = Config.dev.url.getInteraction;
    axios.put(getInteraction, setDrawerOpportunityOptionsResponse, {
      sortBy: "programName",
      asc: "true",
      filterBy: {
        programID: donationProgramID,
        programmeRound: id,
        status: [
          "Approved",
          "PreApproved",
          "Completed"
        ]
      }
    }, true);

    dispatch(toggleSpinner(false));
    setReload(false);
  };

  useEffect(() => {
    dispatch(toggleSpinner(true));
    setData();
    setListData()
  }, []);

  useEffect(() => {
    if (programIdQueryValue && opportunityIDQueryalue) {
      form.setFieldsValue({
        programID: programIdQueryValue,
        opportunityID: opportunityIDQueryalue,
      });
    }
  }, [programIdQueryValue, opportunityIDQueryalue]);

  const onFormChange = (_, allValues) => {
    setFormValues(allValues);
  };

  useEffect(() => {
    if (programmeRound || programID || opportunityID || filterId) {
      dispatch(toggleSpinner(true));
      setReload(true);
      setListData()
    }
  }, [programmeRound, programID, opportunityID, filterId]);

  const setDataFromApi = async (responseData) => {
    const interaction = responseData?.data?.interaction;
    if (interaction) {
      dispatch(setDonation(interaction));
    }
  };


  const setProgramDataFromApi = async (responseData) => {
    const list = responseData?.data?.data;
    if (list) {
      dispatch(setDonationList(list));
    }
  };

  const resetFormFields = () => {
    setRequestInVoiceCheckBox({});
    setShowDeleteInvoiceFileModal(false);
    setShowDeletePurchaseFileModal(false);
    setActionFields(false);
    setInvoiceFileList([]);
    setPurchasedFileList([]);
  };

  const resetFormFieldsAndOverlay = () => {
    resetFormFields();
    setSelectedOpportunityData({});
    setShowDonationModal(false);
    setShowEditDonationModal(false);
    setEditView(false);
    setEditFileView(false);
    setActionFields(false);
    setShowDeleteModal(false);
    setRequestInVoiceCheckBox({});
    setShowDeleteInvoiceFileModal(false);
    setShowDeletePurchaseFileModal(false);
    setInvoiceFileList([]);
    setPurchasedFileList([]);
    setDrawerOpportunityOptions([]);
    setDrawerRoundOptions([]);
  };

  const setProgramDataFromAndOptionsApi = async (responseData) => {
    const list = responseData?.data?.data;
    if (list) {
      dispatch(setDonationList(list));
      dispatch(setOpportunitiesDropDown(list?.[0]?.opportunity));
    }
  };

  const extractProgrammeRound = data => {
    const regex = /\d/;
    const extractRound = name => name.split(' ')[0]?.length === 1 && regex.test(name.split(' ')[0]) ? name.split(' ')[0] : '';
    return [...new Set(data.flatMap(item => [
      item.programName && extractRound(item.programName),
      ...item.opportunity?.flatMap(opportunity => [
        opportunity.programName && extractRound(opportunity.programName),
        ...opportunity.donations?.flatMap(donation => [
          extractRound(donation.programName),
          donation.programmeRound
        ]) || []
      ]) || []
    ]).filter(Boolean))];
  };

  const setProgramListRoundOptions = (responseData) => {
    if (responseData) {
      const round = extractProgrammeRound(responseData?.data?.data)
      setRoundOptions(round)
      setProgramDataFromAndOptionsApi(responseData);
      dispatch(toggleSpinner(false));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const setProgramListDataAndOptions = (responseData) => {
    if (responseData) {
      setProgramDataFromAndOptionsApi(responseData);
      dispatch(toggleSpinner(false));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const setProgramListData = (responseData) => {
    if (responseData) {
      setProgramDataFromApi(responseData);
      dispatch(toggleSpinner(false));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const setFilters = (responseData) => {
    if (responseData) {
      setDataFromApi(responseData);
      dispatch(toggleSpinner(false));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const handleMenuClick = ({ key }) => {
    setFilterId(key);
  };

  const menuItems = [
    {
      key: '1',
      label: 'By Status',
      children: statusDropDownValues.map((item) => ({
        key: item,
        label: item,
      })),
    },
    {
      key: '2',
      label: 'By Action',
      children: filterDropDownValues.map((item) => ({
        key: item,
        label: item,
      })),
    },
  ];

  const menu = (
    <Menu
      items={menuItems}
      onClick={handleMenuClick}
    />
  );

  const expandedRowRender = ({ programName }) => {
    const selectedProgramOpportunities = progListData?.find(
      (item) => item.programName === programName
    );
    const opportunityColumns = [
      {
        title: intl.formatMessage({ id: 'opportunity' }),
        dataIndex: 'opportunityName',
        key: 'opportunityName',
        render: (text, record) => (
          <Link
            to={`opportunity/review/${(record?.opportunityID || selectedProgramOpportunities?.opportunityID)}/${(selectedProgramOpportunities?.programID || values.programID)}/Approved/N`}
            target="_blank"
          >
            {text}
          </Link>
        ),
        fixed: 'left',
      },
      { title: intl.formatMessage({ id: 'awarded' }), dataIndex: 'grantTotalOpp', key: 'grantTotalOpp' },
      {
        title: 'Volunteering',
        dataIndex: 'volunteeringTotalOpp',
        key: 'volunteeringTotalOpp',
      },
      { title: intl.formatMessage({ id: 'inkind' }), dataIndex: 'inkindTotalOpp', key: 'inkindTotalOpp' },
    ];

    return (
      <Table
        columns={opportunityColumns}
        dataSource={getOpportunityColumnData(
          selectedProgramOpportunities?.opportunity, currencyMarker
        )}
        loading={!selectedProgramOpportunities?.opportunity}
        pagination={false}
      />
    );
  };

  const expandedRowOpportunityRender = ({ opportunityID }) => {
    const selectedProgramOpportunities = progListData
      ?.find((item) => item.programID === values.programID)
      ?.opportunity?.find((item) => item.opportunityID === opportunityID);
    const opportunityColumns = [
      {
        title: intl.formatMessage({ id: 'award_title' }),
        dataIndex: 'donationAmount',
        key: 'donationAmount',
        fixed: 'left',
      },
      { title: intl.formatMessage({ id: 'type_title' }), dataIndex: 'type', key: 'type' },
      {
        title: intl.formatMessage({ id: 'invoice_title' }),
        dataIndex: 'invoiceFile',
        key: 'invoiceFile',
        render: (text) => (text?.[0]?.name ? (
          <Button
            onClick={() => onDownloadFiles(text)}
            className={`fileBtn editButton`}
          >
            {shortenString(text?.[0]?.name)}
          </Button>
        ) : null),
      },
      { title: intl.formatMessage({ id: 'condition_title' }), dataIndex: 'comment', key: 'comment' },
      { title: intl.formatMessage({ id: 'due_date' }), dataIndex: 'dueDate', key: 'dueDate' },
      {
        title: intl.formatMessage({ id: 'payment_date' }),
        dataIndex: 'reportedDate',
        key: 'reportedDate',
      },
      { title: intl.formatMessage({ id: 'status' }), dataIndex: 'status', key: 'status' },
      {
        title: intl.formatMessage({ id: 'next_action_title' }),
        dataIndex: 'companyNextAction',
        key: 'companyNextAction',
        render: (text, report) => (text !== 'Awaiting confirmation' && text !== 'Awaiting invoice' && report?.status !== 'Confirmed' ? (
          <Button
            variant="primary"
            onClick={() => {
              resetFormFields();
              setEditView(false);
              setActionFields(true);
              setShowEditDonationModal(true);
              setSelectedOpportunityData(report);
            }}
            type="primary"
          >
            {text}
          </Button>
        ) : report?.status !== 'Confirmed' && (
          <span className='textAlert'>{text}</span>
        )),
      },
      {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, report) => (
          report?.status !== 'Confirmed' ? (
            <AntButton
              className='editButton'
              onClick={() => {
                resetFormFields();
                setEditView(false);
                setActionFields(true);
                setShowEditDonationModal(true);
                setSelectedOpportunityData(report);
              }}
              type="primary"
            >
              {intl.formatMessage({ id: 'edit_title' })}
            </AntButton>
          ) : null
        ),
      },
    ];

    return (
      <Table
        columns={opportunityColumns}
        dataSource={getOpportunityRowData(
          selectedProgramOpportunities?.donations,
          currencyMarker
        )}
        loading={!selectedProgramOpportunities?.donations}
        pagination={false}
        className="donationsTable"
      />
    );
  };

  const getSecuredUrl = async (fileKey, name) => new Promise((resolve, reject) => {
    const url = `${Config.dev.url.imageURL}${fileKey}`;
    axios.getImage(
      url,
      (res, obj, err) => {
        if (res) {
          resolve(res);
        } else {
          reject(err);
        }
      },
      null,
      name
    );
  });

  const onDownloadFiles = async (data) => {
    const { name, fileKey = '' } = data?.[0] || {};
    const getSecuredURL = await getSecuredUrl(fileKey, name);
    const securedURL = URL.createObjectURL(getSecuredURL);
    const fileLink = document.createElement('a');
    fileLink.href = securedURL;
    fileLink.download = name || 'download';
    const clickHandlers = () => {
      setTimeout(() => {
        URL.revokeObjectURL(securedURL);
        fileLink.removeEventListener('click', clickHandlers);
      }, 150);
    };

    fileLink.addEventListener('click', clickHandlers, false);
    fileLink.click();
    return fileLink;
  };

  const onDownloadFile = async (key, name) => {
    const getSecuredURL = await getSecuredUrl(key, name);
    const securedURL = URL.createObjectURL(getSecuredURL);
    const fileLink = document.createElement('a');
    fileLink.href = securedURL;
    fileLink.download = name || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(securedURL);
        fileLink.removeEventListener('click', clickHandler);
      }, 150);
    };

    fileLink.addEventListener('click', clickHandler, false);
    fileLink.click();
    return fileLink;
  };

  const postDonationDownload = (responseData) => {
    if (responseData?.data) {
      const key = responseData.data.key ? responseData.data.key : 'download';
      if (key) {
        drawerForm.setFieldsValue({ fileType: undefined, donationStatus: undefined });
        onDownloadFile(key, key);
      }
    }
    dispatch(toggleSpinner(false));
  };

  const submitDownload = () => {
    const downloadFormValues = downloadForm.getFieldsValue();
    const { donationDownloadStatus: status } = downloadFormValues || {};
    form
      .validateFields(['donationStatus'])
      .then((val) => {
        if (programID) {
          const data = {
            programID,
            opportunityID,
            status,
          };
          const downloadUrl = Config.dev.url.downloadDonationData;
          axios.put(downloadUrl, postDonationDownload, data, true);
          dispatch(toggleSpinner(true));
          setShowDownloadModal(false);
          setInvoiceFileList([]);
          setPurchasedFileList([]);
          setRequestInVoiceCheckBox({});
        }
      })
      .catch(() => {
      });
  };

  const renderTable = () => {
    if (programID && filterId) {
      const opportunityColumns = [
        {
          title: intl.formatMessage({ id: 'opportunity' }),
          dataIndex: 'opportunityName',
          key: 'opportunityName',
          render: (text, record) => (
            <Link
              to={`opportunity/review/${(record?.edit?.opportunityID || values.opportunityID)}/${values.programID}/Approved/N`}
              target="_blank"
            >
              {text}
            </Link>
          ),
          fixed: 'left',
        },
        { title: intl.formatMessage({ id: 'organisation_title' }), dataIndex: 'charityName', key: 'charityName' },
        {
          title: intl.formatMessage({ id: 'award_title' }),
          dataIndex: 'donationAmount',
          key: 'donationAmount',
        },
        { title: intl.formatMessage({ id: 'type_title' }), dataIndex: 'type', key: 'type' },
        {
          title: intl.formatMessage({ id: 'invoice_title' }),
          dataIndex: 'invoiceFile',
          key: 'invoiceFile',
          render: (text) => (text?.[0]?.name ? (
            <Button
              onClick={() => onDownloadFiles(text)}
              className={`fileBtn editButton`}
            >
              {shortenString(text?.[0]?.name)}
            </Button>
          ) : null),
        },
        { title: intl.formatMessage({ id: 'condition_title' }), dataIndex: 'comment', key: 'comment' },
        { title: intl.formatMessage({ id: 'due_date' }), dataIndex: 'dueDate', key: 'dueDate' },
        {
          title: intl.formatMessage({ id: 'payment_date' }),
          dataIndex: 'reportedDate',
          key: 'reportedDate',
        },
        { title: intl.formatMessage({ id: 'status' }), dataIndex: 'status', key: 'status' },
        {
          title: intl.formatMessage({ id: 'next_action_title' }),
          dataIndex: 'companyNextAction',
          key: 'companyNextAction',
          render: (text, report) => (text !== 'Awaiting confirmation' && text !== 'Awaiting invoice' && report?.status !== 'Confirmed' ? (
            <Button
              variant="primary"
              onClick={() => {
                resetFormFields();
                setEditView(false);
                setActionFields(true);
                setShowEditDonationModal(true);
                setSelectedOpportunityData(report);
              }}
              className='companyDonationButton'
            >
              {text}
            </Button>
          ) : report?.status !== 'Confirmed' && (
            <span className='textAlert'>{text}</span>
          )),
        },
        {
          title: '',
          dataIndex: 'edit',
          key: 'edit',
          render: (text, report) => (
            <AntButton
              className='editButton'
              onClick={() => {
                resetFormFields();
                setEditView(false);
                setActionFields(true);
                setShowEditDonationModal(true);
                setSelectedOpportunityData(report);
              }}
              type="primary"
            >
              {intl.formatMessage({ id: 'edit_title' })}
            </AntButton>
          ),
        },
      ];
      const selectedOpportunities = progListData?.filter(
        (item) => item.programID === values.programID
      );
      return (
        <Table
          className="components-table-demo-nested"
          columns={opportunityColumns}
          dataSource={getOpportunityRowData(selectedOpportunities, currencyMarker)}
          loading={!selectedOpportunities}
          pagination={false}
        />
      );
    }
    if (programID) {
      const selectedOpportunities = progListData?.find(
        (item) => item.programID === values.programID
      );
      const opportunityColumns = [
        {
          title: intl.formatMessage({ id: 'opportunity' }),
          dataIndex: 'opportunityName',
          key: 'opportunityName',
          render: (text, record) => (
            <Link
              to={`opportunity/review/${record?.opportunityID}/${(selectedOpportunities?.programID || values.programID)}/Approved/N`}
              target="_blank"
            >
              {text}
            </Link>
          ),
          fixed: 'left',
        },
        { title: intl.formatMessage({ id: 'organisation_title' }), dataIndex: 'charityName', key: 'charityName' },
        { title: intl.formatMessage({ id: 'awarded' }), dataIndex: 'grantTotalOpp', key: 'grantTotalOpp' },
        {
          title: intl.formatMessage({ id: 'volunteering' }),
          dataIndex: 'volunteeringTotalOpp',
          key: 'volunteeringTotalOpp',
        },
        {
          title: intl.formatMessage({ id: 'inkind' }),
          dataIndex: 'inkindTotalOpp',
          key: 'inkindTotalOpp',
        },
      ];

      return (
        <>
          <Table
            className="components-table-demo-nested"
            columns={programColumns}
            dataSource={getColumnData(progListData, currencyMarker)}
            loading={!progListData}
            pagination={false}
            scroll={{ x: 1300 }}
          />
          {selectedOpportunities?.opportunity?.length > 0 && selectedOpportunities?.opportunity?.length === 1 && (
            <Table
              className="components-table-opportunity"
              columns={opportunityColumns}
              dataSource={getOpportunityColumnData(
                selectedOpportunities?.opportunity,
                currencyMarker
              )}
              loading={!selectedOpportunities?.opportunity}
              expandable={{ expandedRowRender: (record) => expandedRowOpportunityRender({ opportunityID: record.opportunityID, defaultExpandAllRows: opportunityID }), expandedRowKeys: [0] }}
              pagination={false}
            />
          )}
          {selectedOpportunities?.opportunity?.length > 1 && (
            <Table
              className="components-table-opportunity"
              columns={opportunityColumns}
              dataSource={getOpportunityColumnData(
                selectedOpportunities?.opportunity,
                currencyMarker
              )}
              loading={!selectedOpportunities?.opportunity}
              expandable={{ expandedRowRender: (record) => expandedRowOpportunityRender({ opportunityID: record.opportunityID }), defaultExpandAllRows: opportunityID }}
              pagination={false}
            />
          )}
        </>
      );
    }

    return (
      <Table
        className="components-table-demo-nested"
        columns={programColumns}
        dataSource={getColumnData(progListData)}
        expandable={{
          expandedRowRender: (record) => expandedRowRender({ programName: record?.programName })
        }}
        loading={!progListData}
        pagination={false}
      />
    );
  };

  const postCreateDonation = (response) => {
    if (response) {
      const drawerFormValues = drawerForm.getFieldsValue();
      const { donationProgramID, donationOpportunityID, donationRound } = drawerFormValues || {};
      setTimeout(() => {
        form.setFieldsValue({
          programID: donationProgramID,
          opportunityID: donationOpportunityID,
          programmeRound: donationRound
        });
      }, 1000);
      setTimeout(() => {
        setListData();
      }, 1200);
      setActionFields(false);
      setShowDonationModal(false);
      setShowEditDonationModal(false);
      setInvoiceFileList([]);
      setPurchasedFileList([]);
      setRequestInVoiceCheckBox({});
      dispatch(toggleSpinner(false));
      message.success(intl.formatMessage({ id: 'donation_created' }));
    } else {
      dispatch(toggleSpinner(false));
      setActionFields(false);
    }
  };

  const postEditDonation = (response) => {
    if (response) {
      const {
        edit: {
          opportunityID: donationOpportunityID = '',
          programID: donationProgramID = '',
          round: donationRound
        } = {},
      } = selectedOpportunityData || {};
      setTimeout(() => {
        form.setFieldsValue({
          programID: donationProgramID,
          opportunityID: donationOpportunityID,
          programmeRound: donationRound
        });
      }, 1000);
      setTimeout(() => {
        setListData();
      }, 1200);
      setShowDonationModal(false);
      setShowEditDonationModal(false);
      setInvoiceFileList([]);
      setPurchasedFileList([]);
      setRequestInVoiceCheckBox({});
      dispatch(toggleSpinner(false));
      setEditView(false);
      setEditFileView(false);
      setActionFields(false);
      message.success(intl.formatMessage({ id: 'donation_updated' }));
    } else {
      dispatch(toggleSpinner(false));
      setActionFields(false);
    }
  };
  const deleteDonation = () => {
    const donationID = selectedOpportunityData?.edit?.ID;
    if (donationID) {
      const removeDonationUrl = Config.dev.url.removeDonation;
      dispatch(toggleSpinner(true));
      axios.put(
        removeDonationUrl,
        postDeleteDonation,
        {
          donationID,
        },
        true
      );
    }
  };

  const postDeleteDonation = (response) => {
    if (response) {
      setEditView(false);
      setEditFileView(false);
      setShowDeleteModal(false);
      setInvoiceFileList([]);
      setPurchasedFileList([]);
      setRequestInVoiceCheckBox({});
      resetFormFieldsAndOverlay();
      dispatch(toggleSpinner(false));
      setActionFields(false);
      message.success(intl.formatMessage({ id: 'donation_deleted' }));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const addCommas = (nStr) => {
    nStr += '';
    const x = nStr.split('.');
    let x1 = x[0];
    const x2 = x?.length > 1 ? `.${x[1]}` : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  };
  const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
    const fileName = selectedFile.name.replace(
      /(?:\.(?![^.]+$)|[^\w.])+/g,
      ''
    );
    const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
    axios.get(url, (res, err) => {
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject(err.response);
      }
    });
  });
  const uploadFileToS3 = async (presignedPostData, file, onProgress) => new Promise((resolve, reject) => {
    const formData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent <= 90) {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      },
    };
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);
    const {
      size = '', name = '', uid = '', type = ''
    } = file || {};
    const { fields: { key = '' } = {}, url = '' } = presignedPostData || {};
    const data = {
      fileKey: key, size, name, uid, fileLoaded: true, mediaDescription: '', type, url, thumbUrl: url
    };
    axios.uploadFile(
      presignedPostData.url,
      formData,
      (res, err) => {
        if (res && res.status === 204) {
          if (requestInVoiceCheckBox?.donationUploadPurchase) {
            setPurchasedFileList([{ ...data }]);
          }
          if (requestInVoiceCheckBox?.donationCounterpartBehalf) {
            setInvoiceFileList([{ ...data }]);
          }
          resolve(res.data);
        } else {
          reject(err.response);
        }
      },
      config
    );
  });
  const uploadInvoiceFileToS3 = async (presignedPostData, file, onProgress) => new Promise((resolve, reject) => {
    const formData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent <= 90) {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      },
    };
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);
    const {
      size = '', name = '', uid = '', type = ''
    } = file || {};
    const { fields: { key = '' } = {}, url = '' } = presignedPostData || {};
    const data = {
      fileKey: key, size, name, uid, fileLoaded: true, mediaDescription: '', type, url, thumbUrl: url
    };
    axios.uploadFile(
      presignedPostData.url,
      formData,
      (res, err) => {
        if (res && res.status === 204) {
          if (requestInVoiceCheckBox?.donationCounterpartBehalf) {
            setInvoiceFileList([{ ...data }]);
          }
          resolve(res.data);
        } else {
          reject(err.response);
        }
      },
      config
    );
  });
  const processFileUpload = async ({
    onSuccess, onError, file, onProgress
  }) => {
    try {
      let presignedPostData = await getPresignedPostData(file);
      presignedPostData = await presignedPostData.data;
      await uploadFileToS3(presignedPostData, file, onProgress);
      onSuccess(null, file);
    } catch (e) {
      message.error(`File upload failed. ${e.message || ''}`);
      onError(e);
    }
  };
  const processFileInvoiceUpload = async ({
    onSuccess,
    onError,
    file,
    onProgress,
  }) => {
    try {
      let presignedPostData = await getPresignedPostData(file);
      presignedPostData = await presignedPostData.data;
      await uploadInvoiceFileToS3(presignedPostData, file, onProgress);
      onSuccess(null, file);
    } catch (e) {
      message.error(`File upload failed. ${e.message || ''}`);
      onError(e);
    }
  };
  const commaFormatted = (amount) => {
    const stringValue = amount.target.value;
    const stringWithoutComma = stringValue.replace(/,/g, '');
    const formattedValue = addCommas(stringWithoutComma.toString());
    drawerForm.setFieldsValue({
      donationAmount: formattedValue,
    });
  };
  const deleteUploadedFiles = async (file, purchasedFileListModal) => {
    const editDonationUrl = Config.dev.url.editDonation;
    try {
      // eslint-disable-next-line no-new
      new Promise((resolve) => {
        axios.put(
          editDonationUrl,
          (res) => {
            if (
              res
              && res.status === 200
              && res.data.message === 'S3 object deleted  successfully'
            ) {
              setEditView(false);
              setEditFileView(false);
              setShowDeleteModal(false);
              setShowDeleteInvoiceFileModal(false);
              setShowDeletePurchaseFileModal(false);
              if (purchasedFileListModal) {
                setPurchasedFileList([]);
                setRequestInVoiceCheckBox({
                  ...requestInVoiceCheckBox,
                  donationUploadPurchase:
                    !requestInVoiceCheckBox?.donationUploadPurchase,
                });
                drawerForm.setFieldsValue({
                  donationUploadPurchase: undefined,
                });
              } else {
                setInvoiceFileList([]);
                setRequestInVoiceCheckBox({
                  ...requestInVoiceCheckBox,
                  donationCounterpartBehalf:
                    !requestInVoiceCheckBox?.donationCounterpartBehalf,
                });
                drawerForm.setFieldsValue({
                  donationCounterpartBehalf: undefined,
                });
              }
              message.success('File Deleted');
              resolve(res.data);
            } else {
              message.error('File deletionFailed failed.');
            }
          },
          {
            bucketName: 'bg-uploaded-media',
            fileName: file,
            action: 'deleteDonationFile',
          },
          true
        );
      });
    } catch (e) {
      message.error(`File deletionFailed failed. ${e.message || ''}`);
    }
  };

  const actionVal = (companyNextAction) => actionDropDownOptions?.find((item) => (item?.key.includes(companyNextAction)))?.value;

  const returnedFileList = (fileList, oldFileList) => {
    let result = [];
    if (fileList?.[0]?.name) {
      result = fileList;
    } else if (oldFileList?.[0]?.name) {
      result = oldFileList;
    }
    return result;
  };

  const formatDate = (dateValue, donationDueDate) => {
    if (typeof (dateValue) === 'string') {
      return dateValue;
    }
    if (donationDueDate) {
      return moment(donationDueDate).format('YYYY-MM-DD');
    }
  };

  const formatDonationAmount = (donationAmount, currencySymbol) => {
    let result = '';
    if (showEditDonationModal) {
      if (donationAmount?.trim()) {
        result = donationAmount.replace(/,/g, '').replace(`${currencySymbol}`, '').replace(/days/g, '').trim();
      } else {
        result = null;
      }
    }
    return result;
  };

  const formatInitialValDonationAmount = (donationAmount, currencySymbol) => {
    let result = '';
    if (showEditDonationModal) {
      if (donationAmount?.trim()) {
        result = donationAmount.replace(/,/g, '').replace(new RegExp(`${currencySymbol}`, 'g'), '').replace(/days/g, '').trim();
      }
    }
    return result;
  };

  const renderDatePickerOrSpan = (editViewModal, drawerFormValues) => {
    if (showEditDonationModal && editViewModal) {
      return <DatePicker format="YYYY-MM-DD" disabled={!editView && !drawerFormValues.donationOpportunityID} />;
    }
    return <span />;
  };

  const renderInputOrSpan = (editViewModal, paymentReference) => {
    if (showEditDonationModal && editViewModal) {
      return <Input type="text" className="" />;
    }
    return <span>{paymentReference?.trim()}</span>;
  };

  const getAlterCurrencies = (currencyMark, currencyMarkAlter) => (currencyMark !== 'undefined' ? currencyMark : currencyMarkAlter);

  const getCashGivingCurrencyCodeVerify = (donation, cashGivingCurrencyCode) => donation?.length > 0 && cashGivingCurrencyCode ? getCurrencySymbol(cashGivingCurrencyCode) : null;


  const onFinishFailed = (e) => {
    console.log("Failed:", e);
  }

  const getCurrencyMark = (donation, programID) => {
    const cashGivingCurrencyCode = donation?.length > 0 && [
      ...new Map(
        donation.map((item) => [item.programName, item])
      ).values(),
    ].filter((item) => item?.programID === programID)?.[0]?.programOfferings?.cashGivingCurrencyCode;
    return getCashGivingCurrencyCodeVerify(donation, cashGivingCurrencyCode);
  };

  const prepareEditDonationData = (drawerFormValues, selectedOpportunityData, currencyMark, actionVal, actionFields) => {
    const {
      edit: {
        ID, paymentReference, purchaseOrderSubmitted, confirmedDate, donationAmount: donationAmt, companyNextAction, reportedDate
      } = {},
      type, comment, dueDate
    } = selectedOpportunityData || {};

    const {
      donationType, donationAmount, donationCondition, donationDueDate, donationPaymentReference, donationAction, donationReportedDate, donationRound
    } = drawerFormValues || {};

    const donateActionVal = ((!actionFields && donationAction) || actionVal(companyNextAction));
    const dateValue = donationDueDate || dueDate;
    const donationReportedDateExists = donationReportedDate ? moment(donationReportedDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

    return {
      donationID: ID,
      donationAmount: (donationAmount?.replace(/,/g, '').replace(/`${currencyMark}`/g, '').trim() || donationAmt?.replace(/,/g, '').replace(/`${currencyMark}`/g, '').trim()),
      dueDate: formatDate(dateValue, donationDueDate),
      programmeRound: donationRound,
      type: donationType?.trim() || type?.trim(),
      status: donateActionVal === 'Report donation' ? 'Reported' : donateActionVal,
      comment: donationCondition?.trim() || comment?.trim(),
      invoiceFile: returnedFileList(invoiceFileList, selectedOpportunityData.edit?.invoiceFile),
      purchaseOrderFile: returnedFileList(purchasedFileList, selectedOpportunityData.edit?.purchaseOrderFile),
      paymentReference: donationPaymentReference?.trim() || paymentReference?.trim(),
      confirmedDate: confirmedDate?.trim(),
      purchaseOrderSubmitted: purchaseOrderSubmitted?.trim(),
      currencySymbol: currencyMark,
      cashGivingCurrencyCode: currencyMark,
      reportedDate: donateActionVal === 'Report donation' ? donationReportedDateExists : reportedDate?.trim(),
    };
  };

  const prepareCreateDonationData = (drawerFormValues, currencyMark) => {
    const {
      donationProgramID, donationOpportunityID, donationRound, donationType, donationAmount, donationCondition, donationDueDate, donationPaymentReference, donationAction, donationReportedDate
    } = drawerFormValues || {};

    return {
      programID: donationProgramID,
      opportunityID: donationOpportunityID,
      programmeRound: donationRound,
      donationAmount: donationAmount?.replace(/,/g, '').replace(/`${currencyMark}`/g, '').trim(),
      dueDate: donationDueDate ? moment(donationDueDate).format('YYYY-MM-DD') : '',
      type: donationType,
      status: donationAction === 'Report donation' ? 'Reported' : donationAction,
      comment: donationCondition,
      invoiceFile: invoiceFileList?.[0]?.name ? invoiceFileList : [],
      purchaseOrderFile: purchasedFileList?.[0]?.name ? purchasedFileList : [],
      paymentReference: donationPaymentReference || '',
      confirmedDate: '',
      purchaseOrderSubmitted: 'N',
      reportedDate: donationAction === 'Report donation' ? donationReportedDate || moment().format('YYYY-MM-DD') : '',
      inkindDescription: donationType === 'In-Kind' ? donationCondition : undefined,
      cashGivingCurrencyCode: currencyMark,
      currencySymbol: currencyMark
    };
  };

  const onFinish = (e) => {
    const drawerFormValues = drawerForm.getFieldsValue();
    if (showEditDonationModal) {
      const currencyMark = getCurrencyMark(donation, drawerFormValues?.programID);
      const editDonationUrl = Config.dev.url.editDonation;
      const donationData = prepareEditDonationData(drawerFormValues, selectedOpportunityData, currencyMark, actionVal, actionFields);

      dispatch(toggleSpinner(true));
      axios.put(editDonationUrl, postEditDonation, donationData, true);
    } else {
      const currencyMark = getCurrencyMark(donation, drawerFormValues?.donationProgramID);
      const createDonationUrl = Config.dev.url.createDonation;
      const donationData = prepareCreateDonationData(drawerFormValues, currencyMark);

      dispatch(toggleSpinner(true));
      axios.put(createDonationUrl, postCreateDonation, donationData, true);
    }
  };

  const getLabelValue = (condition, optionsOne, optionTwo) => condition ? optionsOne : optionTwo;

  const showEditButton = (editFileView, invoiceFile, purchaseOrderFile) => !editFileView
    && showEditDonationModal && (invoiceFileList?.[0]?.name
      || invoiceFile?.[0]?.name
      || purchasedFileList?.[0]?.name
      || purchaseOrderFile?.[0]?.name);

  const renderCurrencyInput = ({ drawerFormValues, currencyMark, editViewModal, donationAmount, currencyMarkAlter }) => {
    const currencyAddBefore = formValues.donationType === 'In-Kind'
      || formValues.donationType === 'Grant'
      ? getAlterCurrencies(currencyMark, currencyMarkAlter)
      : ''
    return (
      <FormItem label={
        <StyledText as="span" variant="B3d" className="status">
          {getLabelValue(formValues.donationType === 'In-Kind', intl.formatMessage({ id: 'equivalent_value' }), intl.formatMessage({ id: 'amount_title' }))}
        </StyledText>}
        name="donationAmount"
        rules={[
          {
            whitespace: true,
            required: true,
            message: intl.formatMessage({ id: 'please_fill_amount' }),
          },
        ]}
      >
        {
          showEditDonationModal && !editViewModal ? (
            <span>{addCommas(donationAmount)}</span>
          ) : (
            <div className='progHeader'>
              <Input
                addonBefore={
                  drawerFormValues.donationType === 'In-Kind'
                    || drawerFormValues.donationType === 'Grant'
                    ? getAlterCurrencies(currencyMark, currencyMarkAlter)
                    : currencyAddBefore
                }
                disabled={!editView && !drawerFormValues.donationOpportunityID}
                placeholder={intl.formatMessage({ id: 'enter_title' })}
                type="text"
                className=""
                defaultValue={formatInitialValDonationAmount(donationAmount, currencyMark)}
                onKeyUp={(value) => commaFormatted(value)}
              />
              {drawerFormValues.donationType === 'Volunteering' && (
                <span className="ml-20">
                  <strong>{` ${'Days'}`}</strong>
                </span>
              )}
            </div>
          )
        }
      </FormItem>
    )
  }

  const handleChange = (value) => {
    if (!value) {
      setRoundOptions('')
      setListData()
      dispatch(setOpportunitiesDropDownAction([]));
      form.setFieldsValue({ programID: undefined, programmeRound: undefined, opportunityID: undefined, filterID: undefined })
    }
  };

  const handleDrawerChange = (value) => {
    if (!value) {
      setListData()
      form.setFieldsValue({ donationProgramID: undefined, donationRound: undefined, donationOpportunityID: undefined })
    }
  };

  const renderDrawer = () => {
    const drawerFormValues = drawerForm.getFieldsValue();

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const {
      status,
      edit: {
        programName = '', invoiceFile, purchaseOrderFile, paymentReference = '', programmeRound: round
      } = {},
      opportunityName,
      donationAmount,
      type,
      comment,
      dueDate,
      reportedDate,
      companyNextAction,
    } = selectedOpportunityData || {};

    const reportDateTrimmed = reportedDate?.trim()
      ? moment(reportedDate?.trim(), 'YYYY-MM-DD')
      : '';

    const currencyMark = donation?.length > 0 && getCurrencySymbol(
      [
        ...new Map(
          donation.map((item) => [item.programName, item])
        ).values(),
      ].filter((item) => item?.programName
        === programName)?.[0]?.programOfferings?.cashGivingCurrencyCode
    );
    const currencyMarkAlter = donation?.length > 0 && getCurrencySymbol(
      [
        ...new Map(
          donation.map((item) => [item.programID, item])
        ).values(),
      ].filter((item) => item?.programID
        === drawerFormValues.donationProgramID)?.[0]?.programOfferings?.cashGivingCurrencyCode
    );
    const editViewModal = showEditDonationModal && editView;
    const invoiceFileListName = invoiceFileList?.[0] ? invoiceFileList : invoiceFile;
    const purchasedFileListName = purchasedFileList?.[0] ? purchasedFileList : purchaseOrderFile

    return (
      <Drawer
        title={intl.formatMessage({ id: 'donation_title' })}
        width={600}
        onClose={() => {
          setShowDonationModal(false);
          setShowEditDonationModal(false);
          setInvoiceFileList([]);
          setPurchasedFileList([]);
          setRequestInVoiceCheckBox({});
          setSelectedOpportunityData({});
          setActionFields(false);
        }}
        styles={
          {
            body: { paddingBottom: 150 }
          }
        }
        open={showDonationModal || showEditDonationModal}
        className="companyDrawer"
        destroyOnClose
      >
        <Form {...formItemLayout} form={drawerForm} labelAlign="left" onFinish={onFinish} onFinishFailed={onFinishFailed}
          initialValues={{
            donationReportedDate: reportDateTrimmed,
            donationProgramID: programName,
            donationRound: round,
            donationDueDate: dueDate?.trim()
              ? moment(dueDate?.trim(), 'YYYY-MM-DD')
              : '',
            donationPaymentReferenced: paymentReference?.trim() || '',
            donationType: type,
            donationAmount: formatDonationAmount(donationAmount, currencyMark),
            donationCondition: comment,
            donationPurchaseOrder: purchaseOrderFile?.[0]?.name || null,
            donationInvoiceFile: invoiceFile || null,
          }}
          onValuesChange={onFormChange}
        >
          <Form.Item label={
            <StyledText as="span" variant="B3d" className="status">
              {intl.formatMessage({ id: 'status' })}
            </StyledText>}
            name="status"
          >
            {
              getLabelValue(showEditDonationModal ? <span>{status}</span> : <span />)
            }
          </Form.Item>
          <FormItem
            label={
              <StyledText as="span" variant="B3d" className="status">
                {intl.formatMessage({ id: 'programme' })}
              </StyledText>}
            name="donationProgramID"
            rules={[
              {
                whitespace: true,
                required: true,
                message: intl.formatMessage({ id: 'please_select_programme' }),
              },
            ]}
            {...formItemLayout}
          >
            {
              showEditDonationModal ? (
                <span>{programName}</span>
              ) : (
                <Select allowClear type="formSelect" onSelect={setRoundData} onChange={handleDrawerChange} placeholder={intl.formatMessage({ id: 'select_title' })} options={[
                  ...new Map(
                    (donation || []).map((item) => [item.programName, item])
                  ).values(),
                ]?.map((value, index) => ({
                  key: index.toString(),
                  label: value?.programName,
                  value: value.programID
                }))} />
              )
            }
          </FormItem>
          <FormItem
            label={
              <StyledText as="span" variant="B3d" className="status">
                {intl.formatMessage({ id: 'round' })}
              </StyledText>}
            name="donationRound"
            rules={[
              {
                whitespace: false,
                required: true,
                message: intl.formatMessage({ id: 'please_select' }),
              },
            ]}
            {...formItemLayout}
          >
            {
              showEditDonationModal ? (
                <span>{round}</span>
              ) : (
                <Select allowClear disabled={(!editView && !drawerFormValues.donationProgramID) || !drawerRoundOptions?.length} options={drawerRoundOptions?.map((value, index) => ({
                  key: index.toString(),
                  label: value,
                  value: value
                }))} type="formSelect" placeholder={intl.formatMessage({ id: 'round' })} onChange={handleDrawerChange} onSelect={setOpportunitiesData} />
              )
            }
          </FormItem>
          <FormItem label={<StyledText as="span" variant="B3d" className="status">
            {intl.formatMessage({ id: 'opportunity' })}
          </StyledText>}
            name="donationOpportunityID"
            rules={[
              {
                whitespace: true,
                required: showDonationModal,
                message: intl.formatMessage({ id: 'please_select_opportunity' }),
              },
            ]}
          >
            {
              showEditDonationModal ? (
                <span>{opportunityName}</span>
              ) : (
                <Select
                  allowClear
                  type="formSelect"
                  placeholder={intl.formatMessage({ id: 'select_title' })}
                  disabled={!editView && !drawerFormValues.donationProgramID}
                  options={drawerOpportunityOptions?.map((el) => ({
                    val: el.opportunityName,
                    key: el.opportunityID,
                  }))
                    ?.map((item, index) => ({
                      key: index.toString(),
                      label: item.val,
                      value: item.key
                    }
                    ))}
                />
              )
            }
          </FormItem>
          <FormItem label={<StyledText as="span" variant="B3d" className="status">
            {intl.formatMessage({ id: 'type_title' })}
          </StyledText>}
            name="donationType"
            rules={[
              {
                whitespace: true,
                required: true,
                message: intl.formatMessage({ id: 'select_type' }),
              },
            ]}
          >
            {
              showEditDonationModal ? (
                <span>{type}</span>
              ) : (
                <Select allowClear disabled={!editView && !drawerFormValues.donationOpportunityID} type="formSelect" placeholder={intl.formatMessage({ id: 'select_title' })} options={typeDropDownOptions?.map((value, index) => ({
                  key: index.toString(),
                  label: value,
                  value
                }))} />
              )
            }
          </FormItem>
          {renderCurrencyInput({ drawerFormValues, currencyMark, editViewModal, donationAmount, currencyMarkAlter })}
          <Form.Item label={
            <StyledText as="span" variant="B3d" className="status">
              {getLabelValue(drawerFormValues.donationType === 'In-Kind', intl.formatMessage({ id: 'description' }), intl.formatMessage({ id: 'condition_title' }))}
            </StyledText>
          }
            name="donationCondition"
            rules={[
              {
                required: false,
                message: intl.formatMessage({ id: 'please_enter_conditions' }),
              },
            ]}
          >
            {
              showEditDonationModal && !editViewModal ? (
                <span>{comment}</span>
              ) : (
                <Input.TextArea
                  rows={3}
                  disabled={!editView && !drawerFormValues.donationOpportunityID}
                  columns={12}
                  type="text"
                  placeholder={intl.formatMessage({ id: 'please_enter_conditions' })}
                  defaultValue={comment || ''}
                />
              )
            }
          </Form.Item>
          <Form.Item label={
            <StyledText as="span" variant="B3d" className="status">
              {intl.formatMessage({ id: 'due_date' })}
            </StyledText>}
            name="donationDueDate"
            rules={[
              {
                required: false,
                message: intl.formatMessage({ id: 'please_enter_conditions' })
              },
            ]}
          >
            {
              showEditDonationModal && !editViewModal ? (
                <span>
                  {dueDate?.trim()
                    ? moment(dueDate?.trim()).format('YYYY-MM-DD')
                    : ''}
                </span>
              ) : (
                <DatePicker format="YYYY-MM-DD" disabled={!editView && !drawerFormValues.donationOpportunityID} />
              )
            }
          </Form.Item>
          <Form.Item label={
            <StyledText as="span" variant="B3d" className="status">
              {intl.formatMessage({ id: 'payment_reference' })}
            </StyledText>}
            name="donationPaymentReferenced"
          >
            {renderInputOrSpan(editViewModal, paymentReference)}
          </Form.Item>
          {reportedDate?.trim() && showEditDonationModal && (
            <Form.Item label={`${intl.formatMessage({ id: 'payment_date' })}`}
              name="donationReportedDate"
              rules={[
                {
                  required: false,
                  message: intl.formatMessage({ id: 'please_enter_conditions' })
                },
              ]}
            >
              {
                showEditDonationModal && !editViewModal ? (
                  <span>
                    {reportedDate}
                  </span>
                ) : (
                  renderDatePickerOrSpan(editViewModal, drawerFormValues)
                )
              }
            </Form.Item>
          )}
          {!editView && showEditDonationModal && (
            <AntButton
              className='editButtonDrawer'
              onClick={() => setEditView(true)}
              type="primary"
            >
              {intl.formatMessage({ id: 'edit_title' })}
            </AntButton>
          )}
          <div className='filesContainer'>
            <StyledText as="p" variant="H2" className="status">
              {intl.formatMessage({ id: 'files_title' })}
            </StyledText>
            <Form.Item label={
              <StyledText as="span" variant="B3d" className="status">
                {intl.formatMessage({ id: 'purchase_order' })}
              </StyledText>}
              name="donationPurchaseOrder"
              className='fileUploadIcon'
              rules={[
                {
                  required: false,
                  message: 'please enter url Condition',
                },
              ]}
            >
              {
                <> <Button
                  onClick={() => onDownloadFiles(purchasedFileListName)}
                  className='drawerEditButton'
                >
                  {purchasedFileList?.[0]?.name
                    || purchaseOrderFile?.[0]?.name
                    || null}
                </Button>
                  {((editFileView && showEditDonationModal)
                    || showDonationModal)
                    && (purchasedFileList?.[0]?.name
                      || purchaseOrderFile?.[0]?.name) && (
                      <button
                        type="button"
                        className='crossFileIcon'
                        onClick={() => setShowDeletePurchaseFileModal(true)}
                      >
                        <img src={crossCircle} alt="cross-circle" />
                      </button>
                    )}
                </>
              }
            </Form.Item>
            <Form.Item label={
              <StyledText as="span" variant="B3d" className="status">
                {intl.formatMessage({ id: 'invoice_title' })}
              </StyledText>
            }
              className='fileUploadIcon'
              name="donationInvoiceFile"
              rules={[
                {
                  required: false,
                  message: intl.formatMessage({ id: 'please_enter_conditions' }),
                },
              ]}
            >
              {
                <>
                  <Button
                    onClick={() => onDownloadFiles(invoiceFileListName)}
                    className='drawerEditButton'
                  >
                    {invoiceFileList?.[0]?.name || invoiceFile?.[0]?.name || null}
                  </Button>
                  {((editFileView && showEditDonationModal)
                    || showDonationModal)
                    && (invoiceFileList?.[0]?.name || invoiceFile?.[0]?.name) && (
                      <button
                        type="button"
                        className='crossFileIcon'
                        onClick={() => setShowDeleteInvoiceFileModal(true)}
                      >
                        <img src={crossCircle} alt="cross-circle" />
                      </button>
                    )}
                </>
              }
            </Form.Item>
          </div>
          {showEditButton(editFileView, invoiceFile, purchaseOrderFile) && (
            <AntButton
              className='editButtonDrawer'
              onClick={() => setEditFileView(true)}
              type="primary"
            >
              {intl.formatMessage({ id: 'edit_title' })}
            </AntButton>
          )}
          {status !== 'Reported' && (
            <div className='donationCheckboxContainer'>
              <StyledText className="actionsTitle" as="p" variant="H2">
                {intl.formatMessage({ id: 'actions_title' })}
              </StyledText>
              <Form.Item label=""
                name="donationAction"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: intl.formatMessage({ id: 'please_select_action' }),
                  },
                ]}
              >
                {
                  showEditDonationModal ? (
                    <Select allowClear type="formSelect" options={actionDropDownOptions?.map((el, index) => ({
                      key: index.toString(),
                      label: el.key,
                      value: el.value
                    }))} placeholder={intl.formatMessage({ id: 'select_action' })} onChange={() => setActionFields(false)} />
                  ) : (
                    <Select allowClear type="formSelect" options={actionDropDownOptions?.map(
                      (el, index) => !el.editView && ({
                        key: index.toString(),
                        label: el.key,
                        value: el.value
                      })).filter((item) => Boolean(item))
                    } placeholder={intl.formatMessage({ id: 'select_action' })} onChange={() => setActionFields(false)} />
                  )
                }
              </Form.Item>
              {((!actionFields && (formValues.donationAction === 'Report donation' || drawerFormValues.donationAction === 'Report donation')) || (actionFields && showEditDonationModal && companyNextAction === 'Report donation')) && (
                <>
                  <FormItem label={
                    <StyledText as="span" variant="B3d" className="status">
                      {intl.formatMessage({ id: 'payment_reference' })}
                    </StyledText>
                  }
                    name="donationPaymentReference"
                    rules={[
                      {
                        whitespace: true,
                        message: intl.formatMessage({ id: 'please_select_amount' }),
                      },
                    ]}
                  >
                    {
                      <Input
                        placeholder={intl.formatMessage({ id: 'enter_payment_reference' })}
                        type="text"
                        className=""
                      />
                    }
                  </FormItem>
                  <Form.Item label={
                    <StyledText as="span" variant="B3d" className="status">
                      {intl.formatMessage({ id: 'payment_date' })}
                    </StyledText>}
                    name="donationReportedDate"
                    rules={[
                      {
                        required: false,
                        message: intl.formatMessage({ id: 'please_enter_conditions' })
                      },
                    ]}
                  >
                    {
                      <DatePicker format="YYYY-MM-DD" />
                    }
                  </Form.Item>
                </>
              )}

              {((!actionFields && (formValues.donationAction === 'Invoice Requested' || drawerFormValues.donationAction === 'Invoice Requested')) || (actionFields && showEditDonationModal && companyNextAction === 'Request invoice')) && (
                <>
                  <FormItem label=""
                    name="donationUploadPurchase"
                    valuePropName={'checked'}
                  >
                    {
                      <Checkbox
                        value="Upload Purchase Order"
                        onChange={() => setRequestInVoiceCheckBox({
                          ...requestInVoiceCheckBox,
                          donationUploadPurchase:
                            !requestInVoiceCheckBox?.donationUploadPurchase,
                        })}
                        checked={
                          requestInVoiceCheckBox?.donationUploadPurchase
                        }
                      >
                        {intl.formatMessage({ id: 'upload_purchase_order' })}
                      </Checkbox>
                    }
                    {requestInVoiceCheckBox?.donationUploadPurchase
                      && (
                        <Upload
                          {...uploaderProps}
                          fileList={[]}
                          customRequest={processFileUpload}
                          className="companyFileUpload"
                        >
                          <Button icon={<UploadOutlined />} className='uploadButton'>
                            {intl.formatMessage({ id: 'click_to_upload' })}
                          </Button>
                        </Upload>
                      )}
                  </FormItem>
                  <FormItem label=""
                    name="donationCounterpartBehalf"
                    valuePropName={'checked'}
                  >
                    {
                      <>
                        <Checkbox
                          value="Upload on counterpart behalf"
                          onChange={() => setRequestInVoiceCheckBox({
                            ...requestInVoiceCheckBox,
                            donationCounterpartBehalf:
                              !requestInVoiceCheckBox?.donationCounterpartBehalf,
                          })}
                          checked={
                            requestInVoiceCheckBox?.donationCounterpartBehalf
                          }
                        >
                          {intl.formatMessage({ id: 'upload_counterpart_behalf' })}
                        </Checkbox>
                        {requestInVoiceCheckBox?.donationCounterpartBehalf &&
                          (
                            <Upload
                              {...uploaderProps}
                              fileList={[]}
                              customRequest={processFileInvoiceUpload}
                              className="companyFileUpload"
                            >
                              <Button icon={<UploadOutlined />} className='uploadButton'>
                                {intl.formatMessage({ id: 'click_to_upload' })}
                              </Button>
                            </Upload>
                          )}
                      </>
                    }
                  </FormItem>
                </>
              )}
            </div>
          )}
          <div className='donationButtonContainer'>
            {status !== 'Confirmed' && (
              <div>
                <AntButton
                  variant="secondary"
                  className='companyDonationDelete'
                  type='button'
                  disabled={showSpinner}
                  onClick={
                    showDonationModal
                      ? () => {
                        setShowDonationModal(false);
                        resetFormFields();
                        setInvoiceFileList([]);
                        setPurchasedFileList([]);
                        setRequestInVoiceCheckBox({});
                      }
                      : () => {
                        setShowDeleteModal(true);
                      }
                  }
                >
                  {intl.formatMessage({ id: 'delete_title' })}
                </AntButton>
              </div>
            )}
            <div className='donationDrawerActionButton'>
              <Button
                variant="secondary"
                type='button'
                disabled={showSpinner}
                onClick={
                  showDonationModal
                    ? () => {
                      setShowDonationModal(false);
                      setInvoiceFileList([]);
                      setPurchasedFileList([]);
                      setRequestInVoiceCheckBox({});
                      resetFormFields();
                      setActionFields(false);
                    }
                    : () => {
                      setShowCancelModal(true);
                    }
                }
                className='companyDonationCancel'
              >
                {intl.formatMessage({ id: 'cancel_title' })}
              </Button>
              <Button
                variant="primary"
                className='companyDonationButton'
                htmlType="submit"
                disabled={showSpinner}
              >
                {intl.formatMessage({ id: 'save_title' })}
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>
    );
  };

  return (
    <Layout
      className={`${baseClassName} endPage companyDonationTwo pageContainerReview  steadyFormOuter`}
    >
      <Form
        labelAlign="left"
        form={form}
        className="formContainer"
        scrollToFirstError>
        <Row gutter={16}>
          <Col span={4}>
            <FormItem label=""
              name="programID"
              rules={[
                {
                  whitespace: true,
                  required: false,
                  message: intl.formatMessage({ id: 'please_select_programme' }),
                },
              ]}
            >
              {
                <Select allowClear type="formSelect" placeholder={intl.formatMessage({ id: 'all_programmes' })} onSelect={() => {
                  setListData();
                  form.setFieldsValue({
                    programmeRound: undefined,
                    opportunityID: undefined,
                    filterID: undefined,
                  })
                }}
                  onChange={handleChange}
                  options={[
                    { label: intl.formatMessage({ id: 'all_programmes' }), value: '' },
                    ...([...new Map(
                      (donation || [])?.map((item) => [item.programName, item])
                    ).values() || []])?.map((value, index) => ({
                      key: index.toString(),
                      label: value.programName,
                      value: value.programID
                    }))
                  ]}
                />
              }
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label=""
              name="programmeRound"
              rules={[
                {
                  whitespace: true,
                  required: false,
                  message: intl.formatMessage({ id: 'please_select_programme' }),
                },
              ]}
            >
              <Select allowClear disabled={!roundOptions?.length} options={[
                { key: "all", label: "All", value: "All" },
                ...(roundOptions || [])?.map((value, index) => ({
                  key: index.toString(),
                  label: value,
                  value: value
                }))
              ]} type="formSelect" placeholder={intl.formatMessage({ id: 'round' })} onSelect={setListData} onChange={handleChange} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem label=""
              name="opportunityID"
              rules={[
                {
                  whitespace: true,
                  required: false,
                  message: intl.formatMessage({ id: 'please_select_opportunity' }),
                },
              ]}
            >
              {
                <Select
                  allowClear
                  type="formSelect"
                  placeholder="All Opportunities"
                  disabled={!programID || !opportunitiesOptions?.length}
                  onSelect={setListData}
                  options={[
                    { label: intl.formatMessage({ id: 'all_opportunities' }), value: 'all' },
                    ...(opportunitiesOptions || [])?.map((value, index) => ({
                      key: index.toString(),
                      label: value.opportunityName,
                      value: value.opportunityID
                    }))
                  ]}
                  onChange={handleChange}
                />
              }
            </FormItem>
          </Col>
          <Col span={4} offset={8}>
            <FormItem label="" className="filters"
              name="filterID"
              rules={[
                {
                  whitespace: true,
                  required: false,
                  message: intl.formatMessage({ id: 'select_filter' }),
                },
              ]}
            >
              {
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  disabled={!values.programID}
                  className={!values.programID ? 'disabled' : ''}
                >
                  <p>
                    {filterId || 'Filters'}
                    <span className="ant-select-arrow">
                      <i
                        aria-label="icon: down"
                        className="ant-select-arrow-icon company"
                      >
                        <img src={Arrow} alt="arrow" />
                      </i>
                    </span>
                  </p>
                </Dropdown>
              }
            </FormItem>
          </Col>
          {values.programID && (
            <div className='downloadButtonDonation'>
              <Button
                variant="primary"
                onClick={() => setShowDownloadModal(true)}
                type="primary"
              >
                {intl.formatMessage({ id: 'download' })}
              </Button>
            </div>
          )}
        </Row>

        <Button
          variant="primary"
          onClick={() => {
            setShowDonationModal(true);
            setSelectedOpportunityData({});
            drawerForm.setFieldsValue({
              donationProgramID: undefined,
              donationOpportunityID: undefined,
              donationType: undefined,
              donationAmount: undefined,
              donationCondition: undefined,
              donationDueDate: undefined,
              donationPaymentReference: undefined,
              donationAction: undefined,
              donationRound: undefined,
            });
          }}
          className='companyDonationButton buttonAlign'
        >
          +
          {' '}
          {intl.formatMessage({ id: 'donation_title' })}
        </Button>
        {showSpinner && (
          <div className='overlay'>
            <Spin className='spinner' size="large" spinning={showSpinner} />
          </div>
        )}
        {!reload && <Layout className='coverImageContainer'>{renderTable()}</Layout>}
        <Modal
          className="deletePopup"
          title={intl.formatMessage({ id: 'download' })}
          open={showDownloadModal}
          onOk={() => submitDownload()}
          onCancel={() => setShowDownloadModal(false)}
          okText={intl.formatMessage({ id: 'download' })}
          showFooter
        >
          <Form form={downloadForm} className='dropDownBackground'>
            <FormItem className='donationStatus'
              name="donationDownloadStatus"
              rules={[
                {
                  whitespace: true,
                  required: false,
                  message: 'Please select a donation status',
                },
              ]}
            >
              {
                <Select allowClear type="formSelect" options={downloadOptions?.map((item, index) => ({
                  key: index.toString(),
                  label: item.value,
                  value: item.key
                }))} placeholder={intl.formatMessage({ id: 'select_donation_status' })} />
              }
            </FormItem>
          </Form>
        </Modal>
        <Modal
          className="deletePopup removeDonationOverlay"
          title={intl.formatMessage({ id: 'delete_file' })}
          open={showDeletePurchaseFileModal}
          onOk={() => {
            setShowCancelModal(false);
            deleteUploadedFiles(
              selectedOpportunityData?.edit?.purchaseOrderFile?.[0]?.fileKey
              || purchasedFileList?.[0]?.fileKey,
              true
            );
          }}
          onCancel={() => setShowDeletePurchaseFileModal(false)}
          okText={intl.formatMessage({ id: 'delete_title' })}
          showFooter
        >
          <Layout className='dropDownBackground'>
            <p>{intl.formatMessage({ id: 'deleting_file_para' })}</p>
          </Layout>
        </Modal>
        <Modal
          className="deletePopup removeDonationOverlay"
          title={intl.formatMessage({ id: 'delete_donation' })}
          open={showDeleteModal}
          onOk={() => deleteDonation()}
          onCancel={() => { setShowDeleteModal(false); setActionFields(false); }}
          okText={intl.formatMessage({ id: 'delete_title' })}
          showFooter
        >
          <Layout className='dropDownBackground'>
            <p>
              {intl.formatMessage({ id: 'delete_donation_para' })}
            </p>
          </Layout>
        </Modal>
        <Modal
          className="deletePopup removeDonationOverlay"
          title={intl.formatMessage({ id: 'cancel_changes' })}
          open={showCancelModal}
          onOk={() => {
            setShowCancelModal(false);
            resetFormFieldsAndOverlay();
            setActionFields(false);
          }}
          onCancel={() => setShowCancelModal(false)}
          okText={intl.formatMessage({ id: 'remove_title' })}
          showFooter
        >
          <Layout className='dropDownBackground'>
            <p>{intl.formatMessage({ id: 'will_remove_changes_to_donation' })}</p>
          </Layout>
        </Modal>
        <Modal
          className="deletePopup removeDonationOverlay"
          title={intl.formatMessage({ id: 'delete_file' })}
          open={showDeleteInvoiceFileModal}
          onOk={() => {
            deleteUploadedFiles(
              selectedOpportunityData?.edit?.invoiceFile?.[0]?.fileKey
              || invoiceFileList?.[0]?.fileKey,
              false
            );
          }}
          onCancel={() => setShowDeleteInvoiceFileModal(false)}
          okText={intl.formatMessage({ id: 'delete_title' })}
          showFooter
        >
          <Layout className='dropDownBackground'>
            <p>{intl.formatMessage({ id: 'deleting_file_para' })}</p>
          </Layout>
        </Modal>
        {renderDrawer()}
      </Form>
    </Layout>
  );
};

const StyledCompanyDonationHomePage = styled(CompanyDonationHomePage)`
&.companyDonationTwo{
  background: ${(props) => props.theme.backgroundCanvas};
    .progOppContainer {
    padding: 10px 15px;
    }
    .formContainer {
      .downloadButtonDonation{
      margin-left: 90%
    }
    }

    .crossFileIcon{
      border-radius: 50%;
      border: 0px;
      background: transparent;
      cursor: pointer;
    }

    .crossFileIcon img {
      height: 15px;
    }

    .editButtonDrawer {
      color: #5271FF !important;
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      font-weight: bold !important;
      margin-left: 90%;
      border: none;
      font-family: sans-serif !important;
    }

    .editButton {
      color: #5271FF !important;
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      font-weight: bold !important;
      border: none;
      font-family: sans-serif !important;
    }

    .companyDonationButton {
      background: #5271FF;
      color: #ffffff;
      font-weight: bold;
      border: 1px solid #5271FF;
    }

    .companyDonationButton:hover{
      background: #5271FF;
      color: #ffffff;
      font-weight: bold;
      border: 1px solid #5271FF;
    }

    .companyDonationCancel {
      background: #ffffff;
      font-weight: bold;
      border: 1px solid;
      margin-right: 10px;
    }

    .companyDonationCancel:hover{
      background: #ffffff;
      font-weight: bold;
      border: 1px solid;
      margin-right: 10px;
    }

    .companyDonationButton[ant-click-animating-without-extra-node=false]{
      background: #5271FF;
      color: #ffffff;
      font-weight: bold;
      border: 1px solid #5271FF;
    }

    .buttonAlign {
      margin: 20px 15px;
    }

    .textAlert{
      color: #ff1616;
    }

    .progOppDropdownContainer>div {
      float: left;
      width: 20%;
      margin-bottom: 0;
    }

    .progOppDropdownContainer>div>div:nth-child(2) {
      width: 90%;
    }

    .coverImageContainer {
      width: 100%;
      background-color: #f1eded !important;
      height: auto;
      min-height: 80px;
    }

    .progImageDiv {
      height: 7.5%;
      width: 12%;
      margin-left: 1%;
    }

    .progImage {
      height: 76px;
      width: 140px;
      margin-top: 5px;
    }

    .progHeaderSection {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .progName {
      width: 20%;
      padding-top: 32px;
      text-align: center;
      padding-right: 10px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: bold;
      font-size: 16px;
    }

    .coverImageDiv {
      display: flex;
    }

    .disabledInputDiv {
      width: 12%;
      margin-right: 20px;
      margin-top: 22px;
    }

    .supportTypeNameDiv1 {
      width: 33%;
    }

    .supportTypeNameDiv2 {
      width: 13.4%;
      padding-left: 4.5%;
      color: rgba(0, 0, 0, 0.65);
      font-weight: bold;
      font-size: 16px;
    }

    .supportTypeNameDiv3 {
      width: 13.4%;
      padding-left: 4.5%;
      color: rgba(0, 0, 0, 0.65);
      font-weight: bold;
      font-size: 16px;
    }

    .supportTypeNameDiv4 {
      width: 13.4%;
      padding-left: 3.8%;
      color: rgba(0, 0, 0, 0.65);
      font-weight: bold;
      font-size: 16px;
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.45);
    }

    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    .oppHeaderSection {
      width: 100%;
      height: auto;
      min-height: 55px;
      background-color: #00aa9d !important;
    }

    .oppHeaderSectionGap {
      margin-top: 15px;
    }

    .oppHeader {
      display: flex;
    }

    .oppHeaderDiv1 {
      font-size: 15px;
      font-weight: 600;
      color: #ffffff;
      width: 11%;
      text-align: center;
      padding-top: 22px;
      padding-bottom: 22px;
    }

    .oppHeaderDiv2 {
      font-size: 15px;
      font-weight: 600;
      color: #ffffff;
      width: 11%;
      text-align: center;
      padding-top: 22px;
      padding-bottom: 22px;
    }

    .oppHeaderDiv3 {
      font-size: 15px;
      font-weight: 600;
      color: #ffffff;
      width: 11%;
      text-align: center;
      padding-top: 22px;
      padding-bottom: 22px;
    }

    .oppHeaderDiv4 {
      width: 12%;
      margin-right: 20px;
      margin-top: 15px;
      height: 55px;
    }

    .oppHeaderDiv5 {
      width: 12%;
      margin-right: 20px;
      margin-top: 15px;
      height: 55px;
    }

    .oppHeaderDiv6 {
      width: 12%;
      margin-right: 20px;
      margin-top: 15px;
      height: 55px;
    }

    .oppHeaderDiv7 {
      width: 25%;
      margin-top: 18px;
      height: 50px;
      padding-left: 15%;
    }

    .supportTypeHeaderSection {
      width: 100%;
      height: auto;
      min-height: 55px;
      background-color: #a4e7e1 !important;
    }

    .donationDiv {
      color: rgba(0, 0, 0, 0.65) !important;
      font-weight: 600;
      text-align: center;
      padding-top: 16px;
    }

    .supportTypeHeaderGap {
      margin-bottom: 10px;
    }

    .cashDonationDiv {
      color: rgba(0, 0, 0, 0.65) !important;
      text-align: center;
      padding-top: 16px;
    }

    .volunteeringDonationDiv {
      color: rgba(0, 0, 0, 0.65) !important;
      text-align: center;
      padding-top: 16px;
    }

    .inKindDonationDiv {
      color: rgba(0, 0, 0, 0.65) !important;
      text-align: center;
      padding-top: 16px;
    }

    .volSkills {
      padding-left: 2%;
    }

    .inKindNoOfItem {
      padding-right: 1%;
    }

    .inKindEquivalentValue {
      padding-left: 1%;
    }

    .highlightRow {
      border-style: solid;
      border-color: #5271ff;
      border-width: 2px;
      border-radius: 15px;
    }

    .supportTypeBodySection {
      width: 100%;
      height: auto;
      min-height: 55px;
    }

    .viewLink {
      color: #00aa9d;
      font-weight: 600;
      font-size: 16px;
    }

    .viewLink:hover {
      text-decoration: underline;
    }

    .editIcon {
      font-size: 22px;
    }

    .companyDonationDelete {
      background: #ED6C2D !important;
      font-weight: bold !important;
      color: #ffffff !important;
      border: 1px solid !important;
    }

    .localDeleteButton,
    .localDeleteButton:hover {
      margin-left: 0px !important;
    }

    .inputDisplay1 {
      display: none !important;
    }

    .inputDisplay2 {
      display: block !important;
      margin-top: 5px;
    }

    .layoutItem {
      background: none;
      padding-bottom: 8px;
    }

    .popupText {
      content: "\A";
      white-space: pre;
    }

    .bdSubHeader {
      display: block;
    }

    .bvalues {
      font-weight: bold;
    }

    .bdFormat {
      display: block;
    }

    .sbdSubHeader {
      display: block;
      margin-top: 10px;
      margin-bottom: 0px;
    }

    .downloadButton>div {
      margin-right: 92%;
    }

    .dropDownBackground {
      background: none;
    }

    .ant-select-arrow{
      height: 16px;
      color: #242424 !important;
      img{
        height: 13px !important;
      }
    }

    .fileType>div>div>span>div {
      display: block !important;
    }

    .donationStatus>div>div>span>div {
      display: block !important;
    }

    @media only screen and (max-width: 1024px) {
      .steadyForm {
        width: 150%;
      }

      .steadyFormOuter {
        overflow-x: auto;
      }
    }

    @media only screen and (max-width: 960px) {
      .steadyForm {
        width: 200%;
      }
    }

    @media only screen and (max-width: 767px) {
      .progOppDropdownContainer {
        width: 93%;
      }

      .progOppDropdownContainer>div {
        width: 100%;
      }

      .progOppDropdownContainer>div>div:nth-child(1) {
        min-width: 140px;
      }

      .progOppDropdownContainer>div>div:nth-child(2) {
        float: left;
        width: 100%;
      }

      .collapseSection {
        width: 500%;
        overflow: auto;
      }

      .steadyForm {
        width: 485%;
      }
    }

    @media only screen and (max-width: 280px) {
      .steadyForm {
        width: 1000%;
      }
    }
}
`;

export default StyledCompanyDonationHomePage;
