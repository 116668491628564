import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Radio, Spin, Steps } from "antd";
import axios from "../../utils/axios";
import { CameraOutlined, CheckCircleFilled } from "@ant-design/icons";
import { getLocalStorageItem, setLocalStorageItem, getSecuredUrl } from "../../utils/utilities";
import styled from "styled-components";
import Config from "../../config";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import ProgStartData from './bizGiveThemeImageData';
import ImageCrop from "../../components/ImageCrop/ImageCrop";
import { createOpportunity, addInteraction, getProgramById, editOpportunity, setAddInteraction, getOpportunity, setEditOpportunitiesData, toggleSpinner } from "../../redux/actions";
import { programDataSelector, createOpportunitySelector, addInteractionSelector, opportunityDataSelector, editOpportunityData, toggleSpinnerSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CreateName = ({ className }) => {
    const [bizGiveThemeImageData, setBizGiveThemeImageData] = useState([]);
    const [selectedBizGiveImage, setSelectedBizGiveImage] = useState({});
    const [logoS3URL, setLogoS3URL] = useState('');
    const [logoFileNameKey, setLogoFileNameKey] = useState('');
    const [coverImageType, setCoverImageType] = useState(null);
    const [savedClicked, setSavedClicked] = useState(false);
    const intl = useIntl();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector);
    const createOpportunityData = useAppSelector(createOpportunitySelector) || {};
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const addInteractionData = useAppSelector(addInteractionSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const editOpp = useAppSelector(editOpportunityData) || {};
    const { opportunityID } = editOpp || {};
    const { opportunity } = opportunityData || {};

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''))
            navigate("/impact-maker/applications/create/description");
            dispatch(toggleSpinner(false));
        }
    }, [opportunityID])

    useEffect(() => {
        if (opportunity?.opportunityName) {
            form.setFieldsValue({
                opportunityName: opportunity?.opportunityName,
                coverImageType: opportunity?.coverImageType,
                coverImage: opportunity?.coverImage,
            });
            if (opportunity?.coverImageType === '1') {
                setCoverImageType(opportunity?.coverImageType);
            } else {
                setCoverImageType(opportunity?.coverImageType);
                setLogoFileNameKey(opportunity?.coverImage);
            }

            if (opportunity?.coverImage) {
                const selectedImage = ProgStartData.bizGiveThemeImageData.find(el => el.id === opportunity?.coverImage);
                if (opportunity?.coverImageType === '2') {
                    setLogoImage(opportunity?.coverImage).then(savedCover => setSelectedBizGiveImage(savedCover));
                } else {
                    setSelectedBizGiveImage(selectedImage);
                }
                ProgStartData.bizGiveThemeImageData.forEach(item => {
                    if (item.id === opportunity?.coverImage) item.checked = true;
                });
            }
        }
    }, [opportunity]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
        ProgStartData.bizGiveThemeImageData.forEach(item => item.checked = false);
        setBizGiveThemeImageData(ProgStartData.bizGiveThemeImageData);
    }, [dispatch, programID]);

    useEffect(() => {
        if (!opportunityId && programID && createOpportunityData?.opportunityID) {
            dispatch(addInteraction({ programID, opportunityID: createOpportunityData?.opportunityID, action: "pendingApplication" }));
            setLocalStorageItem('programReviewDetails', JSON.stringify({ programID, opportunityId: createOpportunityData?.opportunityID }));
        } else if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [createOpportunityData?.opportunityID, programID]);

    useEffect(() => {
        if (addInteractionData) {
            dispatch(setAddInteraction(''));
            if (!savedClicked) {
                navigate("/impact-maker/applications/create/description");
            }
        }
    }, [addInteractionData]);

    const setLogoImage = async fileKey => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL(securedURL);
        return fileKey ? securedURL : '';
    };

    const handleProceed = () => {
        const formData = form.getFieldsValue();
        const data = {
            opportunityName: formData.opportunityName,
            defaultCurrency: programData.cashGivingCurrencyCode,
            application: "Y",
            programID,
            coverImage: coverImageType === '1' ? selectedBizGiveImage.id : logoFileNameKey,
            coverImageType,
            cashTargetCurrencyCode: programData.currencySymbol,
            companyID: programData.companyID
        };
        dispatch(toggleSpinner(true));
        if (!opportunityId && programID) {
            dispatch(createOpportunity(data));
        } else {
            dispatch(editOpportunity({ ...data, opportunityID: opportunityId }));
        }
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    }

    const handlePrevious = () => navigate("/impact-maker/applications/create/start");

    const renderCoverImage = () => {
        if (selectedBizGiveImage?.path && typeof selectedBizGiveImage?.path === 'string') return <img alt="covercc" className="cameraIcon" src={selectedBizGiveImage.path} />;
        if (logoS3URL && typeof logoS3URL === 'string') return <img alt="coverdd" className="cameraIcon" src={logoS3URL} />;
        return <CameraOutlined className="cameraIcon" />;
    };

    const onBizGiveThemeImageClick = (item, pos) => {
        if (selectedBizGiveImage.path) bizGiveThemeImageData[selectedBizGiveImage.id - 1].checked = false;
        item.checked = true;
        bizGiveThemeImageData[pos].checked = true;
        setSelectedBizGiveImage(item);
    };

    const setSelectedFile = croppedImage => setLogoS3URL(croppedImage);

    const setUploadedFileDetails = (fileKey) => {
        if (coverImageType === 2 && logoS3URL && !fileKey) {
            axios.put(Config.dev.url.deleteURL, {}, { data: { bucketName: 'bg-uploaded-media', fileName: programData.coverImage } });
        }
        setLogoFileNameKey(fileKey);
        const url = fileKey ? setLogoImage(fileKey) : '';
        setLogoS3URL(url);
    };

    return (
        <div className={clsx("createProfile", className)}>
            <Steps
                current={0}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "your_application" })}
            </StyledText>
            <StyledText as="p" variant="B3" className='mainTitle'>
                {intl.formatMessage({ id: "please_complete_each_screen" })}
            </StyledText>
            <div>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    className="formContainer"
                    scrollToFirstError
                >
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "what_is_the_name_of_your_project" })}
                    </StyledText>
                    <Form.Item
                        name="opportunityName"
                        className="formItem"
                        rules={[
                            { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                        ]}
                    >
                        <Input placeholder={intl.formatMessage({ id: "project_name" })} />
                    </Form.Item>
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "add_a_banner_image" })}
                    </StyledText>
                    <div className="coverPreview">
                        {renderCoverImage()}
                    </div>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "this_will_show_at_the_top_of_your_application_form" })}
                    </StyledText>
                    {!logoS3URL && <>
                        <Form.Item
                            name="coverImageType"
                            rules={[
                                { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                            ]}
                        >
                            <Radio.Group className="radioGroup" onChange={e => {
                                setCoverImageType(e.target.value);
                                setSelectedBizGiveImage({});
                                setLogoFileNameKey('');
                                setLogoS3URL('');
                            }}>
                                <Radio className="radio" value="1">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "choose_an_image_from_bizGive" })}
                                    </StyledText>
                                </Radio>
                                <Radio className="radio" value="2">
                                    <StyledText as="p" variant="B3">
                                        {intl.formatMessage({ id: "upload_your_own_banner_image" })}
                                    </StyledText>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {coverImageType === '1' && (
                            <>
                                <StyledText as="span" variant="B3d">
                                    {intl.formatMessage({ id: "bizGive_images" })}
                                    <StyledText as="span" variant="B3a">
                                        {` (${intl.formatMessage({ id: "optional" })})`}
                                    </StyledText>
                                </StyledText>
                                <div className='bizgiveImageContainer'>
                                    {bizGiveThemeImageData.map((item, pos) => (
                                        <div
                                            key={item.path}
                                            className="imageContainer"
                                            onClick={() => onBizGiveThemeImageClick(item, pos)}
                                        >
                                            {item.path && <img src={item.path} alt="img" fetchPriority='high' loading='eager' />}
                                            {item.checked && <CheckCircleFilled className='checkedCircle' style={{ color: "#04ac9c" }} />}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </>}
                    {coverImageType === '2' && (
                        <>
                            {!logoS3URL && <StyledText as="span" variant="B3d">
                                {intl.formatMessage({ id: "additional_images" })}
                                <StyledText as="span" variant="B3a">
                                    {` (${intl.formatMessage({ id: "optional" })})`}
                                </StyledText>
                            </StyledText>}
                            <ImageCrop
                                setSelectedFile={setSelectedFile}
                                setUploadedFileDetails={setUploadedFileDetails}
                                uploaded={opportunity?.coverImageType === '2' && opportunity?.coverImage} />
                        </>
                    )}
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button variant="secondary" type="button" onClick={handlePrevious}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button variant="primary" htmlType="submit">
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button variant="secondary" type="button" onClick={handleSaveAndExit}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

const StyledCreateName = styled(CreateName)`
    &.createProfile {
        background: white;
        display: flex;
        padding: 20px;
        flex-direction: column;
        min-height: 150svh;
        .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
            .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }

        .mainTitle {
            margin-bottom: 20px;
        }

        .formContainer {
            display: flex;
            gap: 20px;
            flex-direction: column;
            width: 1000px;
        }

        .buttonContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: fixed;
            width: 100%;
            bottom: 0px;
            background: white;
            margin-left: -80px;

            .buttonContentContainer {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;

                .saveAndExitButton {
                    position: absolute;
                    right: 0;
                    margin-bottom: 15px;
                }

                .nextAndPreviousContainer {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 15px;
                }
            }
        }

        .cameraIcon {
            font-size: 185px;
            display: block;
            position: relative;
            height: 250px;
        }

        .coverPreview {
            width: 100%;
            overflow: hidden;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            height: 250px;
            img {
                width: 100%;
            }
        }

        .radioGroup {
            display: flex;
            flex-direction: column;
        }

        .bizgiveImageContainer {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .imageContainer {
                position: relative;

                &:hover {
                    transform: scale(2);
                    transition: transform .2s, -webkit-transform .2s;
                    z-index: 99;
                }

                img {
                    height: 40px;
                    width: 120px;
                }

                .checkedCircle {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
            }
        }
    }
`;

export default StyledCreateName;
