import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { Divider, Form, DatePicker, Spin, Steps } from "antd";
import styled from "styled-components";
import { editOpportunity, getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem } from "../../utils/utilities";
import { opportunityDataSelector, programDataSelector, editOpportunityData, toggleSpinnerSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CharityProjectDates = ({ className }) => {
    const baseClassName = clsx("charityProjectDates", className);
    const intl = useIntl();
    const [nextClicked, setNextClicked] = useState(false);
    const [savedClicked, setSavedClicked] = useState(false);
    const opportunityData = useAppSelector(opportunityDataSelector);
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunityLengthMonths = 0, opportunityStartWithinMonths = 0, decisionStartDate, decisionEndDate, decisionPeriod } = programData;
    const editOpp = useAppSelector(editOpportunityData) || {};

    const { opportunityID } = editOpp || {};
    const [hasNegativeAlert, setHasNegativeAlert] = useState(false);

    const { opportunity } = opportunityData || {};
    useEffect(() => {
        if (opportunityId) dispatch(getOpportunity(opportunityId));
        if (programID) {
            dispatch(setEditOpportunitiesData(''));
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            }); dispatch(getProgramById(programID))
        };
    }, [opportunityId, programID, dispatch]);

    useEffect(() => {
        if (opportunityID && !savedClicked && nextClicked) {
            dispatch(setEditOpportunitiesData(''));
            dispatch(toggleSpinner(false));

            navigate("/impact-maker/applications/create/request");
        }
    }, [opportunityID, nextClicked]);

    useEffect(() => {
        if (opportunity?.opportunityName) {
            form.setFieldsValue({
                startDate: opportunity?.startDate?.trim() ? dayjs(opportunity?.startDate?.trim()) : undefined,
                endDate: opportunity?.endDate?.trim() ? dayjs(opportunity?.endDate?.trim()) : undefined,
                donationDeadLineDate: opportunity?.donationDeadLineDate?.trim() ? dayjs(opportunity?.donationDeadLineDate?.trim()) : undefined,
            });
        }
    }, [opportunity, form]);


    useEffect(() => {
        const checkDates = () => {
            const formData = form.getFieldsValue();
            const startDate = dayjs(formData?.startDate);
            const endDate = dayjs(formData?.endDate);
            const today = dayjs();

            const startWithinDays = opportunityStartWithinMonths * 30;
            const lengthDays = opportunityLengthMonths * 30;

            const daysUntilStart = startDate.diff(today, 'day');
            const duration = endDate.diff(startDate, 'day');

            setHasNegativeAlert(daysUntilStart >= startWithinDays || duration >= lengthDays);
        };

        checkDates();
    }, [form, opportunityStartWithinMonths, opportunityLengthMonths]);

    const validateStartDate = (_, value) => {
        const today = dayjs();
        const daysUntilStart = value?.diff(today, 'day');
        const maxDays = opportunityStartWithinMonths * 30;

        if (value && daysUntilStart > maxDays) {
            const errorMessage = intl
                .formatMessage({ id: "please_enter_the_dates_within_the_above_mentioned_limits" })
            return Promise.reject(new Error(errorMessage));
        }
        return Promise.resolve();
    };

    const validateEndDate = (_, value) => {
        const startDate = form.getFieldValue('startDate');
        const duration = value?.diff(startDate, 'day');
        const maxDays = opportunityLengthMonths * 30;

        if (startDate && value && duration > maxDays) {
            const errorMessage = intl
                .formatMessage({ id: 'please_enter_the_dates_within_the_above_mentioned_limits' })
            return Promise.reject(new Error(errorMessage));
        }
        return Promise.resolve();
    };


    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = () => {
        const formData = form.getFieldsValue();
        const data = {
            startDate: formData?.startDate ? dayjs(formData?.startDate).format('YYYYMMDD') : undefined,
            endDate: formData?.endDate ? dayjs(formData?.endDate).format('YYYYMMDD') : undefined,
            donationDeadLineDate: formData?.donationDeadLineDate ? dayjs(formData?.donationDeadLineDate).format('YYYYMMDD') : undefined,
            opportunityID: opportunityId,
            programID
        };
        dispatch(toggleSpinner(true));
        dispatch(editOpportunity(data));
        setNextClicked(true)
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    };

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/location");
    };

    const disableEndDate = (current) => {
        const startDate = form.getFieldValue('startDate');
        return startDate && current && current < dayjs(startDate).endOf('day');
    };

    const formatMessage = (id, replacement) => {
        const message = intl.formatMessage({ id });
        return (
            <>
                {message.split(replacement.placeholder).map((part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        {index < message.split(replacement.placeholder).length - 1 && replacement.component}
                    </React.Fragment>
                ))}
            </>
        );
    };

    const items = [
        {
            id: 'projects_must_start_within_12_months',
            placeholder: 'opportunityStartWithinMonths',
            component: <StyledText as="p" variant="B3d" className='msgText'>{opportunityStartWithinMonths}</StyledText>
        },
        {
            id: 'projects_must_not_be_longer_than_6_months',
            placeholder: 'opportunityLengthMonths',
            component: <StyledText as="p" variant="B3d" className='msgText'>{opportunityLengthMonths}</StyledText>
        },
        {
            id: 'expected_decision_date',
            component: (
                <>
                    {`${intl.formatMessage({ id: 'expected_decision_date' })} `}
                    <StyledText as="p" variant="B3d" className='msgText'>
                        {decisionEndDate?.trim() && decisionStartDate?.trim() ? `${decisionStartDate} - ${decisionEndDate}` : decisionPeriod}
                    </StyledText>
                </>
            )
        }
    ];

    return (
        <div className={baseClassName}>
            <Steps
                current={0}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "project_dates" })}
            </StyledText>
            <StyledText as="p" variant="B3d" className='mainTitle'>
                {intl.formatMessage({ id: "when_is_your_project_happening" })}
            </StyledText>
            <StyledText as="p" variant="B3" className='mainTitle'>
                {intl.formatMessage({ id: "this_can_be_an_estimate" })}
            </StyledText>
            <div className={`projectTimelines ${hasNegativeAlert ? 'negative' : ''}`}>
                <ul>
                    {items.map(({ id, placeholder, component }) => (
                        <li key={id}>
                            <div className='msg'>{placeholder ? formatMessage(id, { placeholder, component }) : component}</div>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    form={form}
                    onFinish={handleProceed}
                    onFinishFailed={onFinishFailed}
                    className="formContainer"
                    scrollToFirstError
                >
                    <Form.Item
                        label={<StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "estimated_start" })}
                        </StyledText>}
                        name="startDate"
                        className="formItem"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                            { validator: validateStartDate }
                        ]}
                    >
                        <DatePicker
                        format="YYYY-MM-DD"
                        placeholder={intl.formatMessage({ id: "estimated_start" })}
                        disabledDate={(current) => current && current < dayjs().startOf('day')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "estimated_finish" })}
                        </StyledText>}
                        name="endDate"
                        className="formItem"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: "enter" }) },
                            { validator: validateEndDate }
                        ]}
                    >
                        <DatePicker format="YYYY-MM-DD" placeholder={intl.formatMessage({ id: "estimated_finish" })} disabledDate={disableEndDate} />
                    </Form.Item>
                    <StyledText as="span" variant="B3d">
                        {intl.formatMessage({ id: "is_there_a_deadline_when_you_need_to_receive_a_donation_by" })}
                        <StyledText as="span" variant="B3a">
                            {` (${intl.formatMessage({ id: "optional" })})`}
                        </StyledText>
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "if_not_please_leave_this_blank" })}
                    </StyledText>
                    <Form.Item
                        name="donationDeadLineDate"
                        className="formItem"
                    >
                        <DatePicker format="YYYY-MM-DD" placeholder={intl.formatMessage({ id: "enter_date" })} />
                    </Form.Item>
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={handlePrevious}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    htmlType="submit"
                                >
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={handleSaveAndExit}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

const StyledCharityProjectDates = styled(CharityProjectDates)`
&.charityProjectDates {
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 140vh;
    .msg{
        display: flex;
        align-items: center;
        gap: 3px;
    }
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.45);
    }
    .spinner {
        z-index: 2000;
        margin: auto;
        left: 50%;
        right: 50%;
        position: absolute;
        bottom: 50%;
    }
    .mainTitle {
        margin-bottom: 20px;
    }
    .ant-form-item-label.ant-col-6{
        max-width: max-content !important;
    }
    .formContainer {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
    .projectTimelines {
        background: #D7FEFB;
        border: 1px solid #04ac9c;
        margin-bottom: 15px;
        max-width: max-content;
        padding: 10px;
        &.negative {
            background: #fff7f5;
            border: 1px solid #ED6C2D;
        }
        ul {
            padding-left: 20px;
            list-style-type: disc;
        }
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton {
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer {
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
}`;

export default StyledCharityProjectDates;
