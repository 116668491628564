import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Checkbox, Avatar, Spin, Space, Button as AntdButton, Steps, message } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { editOpportunity, getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner, getIndicatorAnswers } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem, numericValidator } from "../../utils/utilities";
import { opportunityDataSelector, programDataSelector, editOpportunityData, toggleSpinnerSelector, indicatorDataSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import Select from "../../components/CustomSelect"
import lightbulb from '../../assets/lightbulb.svg'

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 6 },
};

const beneficiariesList = [
    'Older People',
    'Younger People',
    'Children and Babies',
    'Females',
    'Males',
    'Learning disabilities',
    'Physical disabilities',
    'Mental health challenges',
    'Physical health challenges',
    'Particular ethnic or racial origin',
    'Migrants',
    'LBGTQ+',
    'Educationally or economically disadvantaged',
    'Long-term unemployed',
    'Ex-homeless',
    'Ex-offenders',
    'Ex-service personnel',
    'Academic Institutions',
    'Other charities or voluntary bodies',
    'The General Public/Mankind',
    'Wildlife',
    'Other'
];

const checkboxData = [{ label: 'Social', value: 'beneficiarySocial' }, { label: 'Environmental', value: 'beneficiaryEnvironment' }]

const CharityImpactProgram = ({ className }) => {
    const baseClassName = clsx("charityImpactProgram", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [formListError, setFormListError] = useState(null);
    const [savedClicked, setSavedClicked] = useState(false);
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector) || {};
    const editOpp = useAppSelector(editOpportunityData) || {};
    const indicators = useAppSelector(indicatorDataSelector) || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunityID } = editOpp || {};
    const { opportunity } = opportunityData || {};
    const { outcomeMethod } = programData || {};

    const categories = [
        {
            category: intl.formatMessage({ id: 'habitats' }),
            subCategory: [
                intl.formatMessage({ id: 'forest' }),
                intl.formatMessage({ id: 'grassland' }),
                intl.formatMessage({ id: 'deserts' }),
                intl.formatMessage({ id: 'mountains' }),
                intl.formatMessage({ id: 'coastal' }),
                intl.formatMessage({ id: 'marine' }),
                intl.formatMessage({ id: 'freshwater' }),
                intl.formatMessage({ id: 'polar' }),
                intl.formatMessage({ id: 'wetlands' }),
                intl.formatMessage({ id: 'rainforest' }),
                intl.formatMessage({ id: 'arctic' }),
                intl.formatMessage({ id: 'tundra' }),
            ],
            actions: [
                intl.formatMessage({ id: 'restored' }),
                intl.formatMessage({ id: 'protected' }),
                intl.formatMessage({ id: 'created' }),
            ],
            units: [
                intl.formatMessage({ id: 'number' }),
                intl.formatMessage({ id: 'hectares' }),
            ],
        },
        {
            category: intl.formatMessage({ id: 'species' }),
            subCategory: [
                intl.formatMessage({ id: 'terrestrial_animals' }),
                intl.formatMessage({ id: 'aquatic_animals' }),
                intl.formatMessage({ id: 'terrestrial_plants' }),
                intl.formatMessage({ id: 'aquatic_plants' }),
            ],
            actions: [
                intl.formatMessage({ id: 'protected' }),
                intl.formatMessage({ id: 'established' }),
                intl.formatMessage({ id: 'benefitted' }),
                intl.formatMessage({ id: 'saved' }),
            ],
            units: [intl.formatMessage({ id: 'number' })],
        },
        {
            category: intl.formatMessage({ id: 'emissions' }),
            subCategory: [
                intl.formatMessage({ id: 'greenhouse_gases' }),
            ],
            actions: [
                intl.formatMessage({ id: 'reduced' }),
                intl.formatMessage({ id: 'captured' }),
                intl.formatMessage({ id: 'prevented' }),
            ],
            units: [
                intl.formatMessage({ id: 'tonnes_of_CO2_equivalents_C02e' }),
            ],
        },
    ];

    useEffect(() => {
        const validateFormList = () => {
            if (formData?.checkboxGroup?.includes('beneficiaryEnvironment') && !formData?.environmentalMeasure?.length) {
                setFormListError('Please enter at least 1 row');
            } else {
                setFormListError(null);
            }
        };

        validateFormList();
    }, [form, formData]);

    useEffect(() => {
        if (opportunity?.opportunityName) {
            const group = [];
            if (opportunity?.beneficiaryEnvironment === 'Y') group.push('beneficiaryEnvironment');
            if (opportunity?.beneficiarySocial === 'Y') group.push('beneficiarySocial');

            const initialValues = {
                checkboxGroup: group,
                opportunityBeneficiaries: opportunity?.opportunityBeneficiaries,
                directBeneficiaries: opportunity.directBeneficiaries || '',
                indirectBeneficiaries: opportunity.indirectBeneficiaries || '',
                environmentalMeasure: opportunity.environmentalMeasure || [],
            };

            form.setFieldsValue(initialValues);
            setFormData(initialValues)
        }
    }, [opportunity?.opportunityName]);

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''))
            dispatch(toggleSpinner(false));
            if (indicators?.length > 0) {
                navigate("/impact-maker/applications/create/indicators");
            } else {
                navigate("/impact-maker/applications/create/custom-questions");
            }
        }
    }, [opportunityID])

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getIndicatorAnswers({
                programID,
                opportunityID: opportunityId
            }))
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const handleProceed = () => {
        const values = form.getFieldsValue();
        if (values.checkboxGroup.includes('beneficiaryEnvironment') && !values.environmentalMeasure?.length) {
            message.error('At least one environmentalMeasure needs to be added');
            return
        }
        const data = {
            opportunityBeneficiaries: values.opportunityBeneficiaries || [],
            directBeneficiaries: values.directBeneficiaries,
            indirectBeneficiaries: values.indirectBeneficiaries,
            application: "Y",
            programID,
            opportunityID: opportunityId,
            beneficiaryEnvironment: values.checkboxGroup.includes('beneficiaryEnvironment') ? 'Y' : 'N',
            beneficiarySocial: values.checkboxGroup.includes('beneficiarySocial') ? 'Y' : 'N',
            environmentalMeasure: values.environmentalMeasure || []
        };
        dispatch(toggleSpinner(true));
        dispatch(editOpportunity(data));
    };

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    };

    const handlePrevious = () => {
        if (outcomeMethod === "Opportunity Custom2") {
            navigate("/impact-maker/applications/create/impact-project-custom");
        } else {
            navigate("/impact-maker/applications/create/impact-programme-custom");
        }
    };

    return (
        <div className={baseClassName}>
            <Steps
                current={2}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <div className='contentContainer'>
                <div>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        onValuesChange={onFormChange}
                        scrollToFirstError
                    >
                        <StyledText as="p" variant="H1" className='mainTitle'>
                            {intl.formatMessage({ id: "beneficiaries" })}
                        </StyledText>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "who_or_what_will_benefit_from_your_project" })}
                        </StyledText>
                        <Form.Item
                            name="checkboxGroup"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select',
                                },
                            ]}
                        >
                            <Checkbox.Group className='checkboxGroup'>
                                {checkboxData?.map(el => (
                                    <Checkbox key={el.label} value={el.value}>
                                        <StyledText as="p" variant="B3e">
                                            {el.label}
                                        </StyledText>
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>
                        {formData.checkboxGroup?.includes("beneficiarySocial") && <>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "who_are_the_social_beneficiaries" })}
                            </StyledText>
                            <Form.Item label="" name="opportunityBeneficiaries" rules={[
                                {
                                    required: true,
                                    message: 'Please select',
                                },
                            ]}>
                                <Select
                                    allowClear
                                    placeholder={intl.formatMessage({ id: "select_beneficiary_type" })}
                                    type="formSelect"
                                    mode="multiple"
                                    options={beneficiariesList.map((value, index) => ({
                                        key: value,
                                        label: value,
                                        value: value
                                    }))
                                    }
                                />
                            </Form.Item>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "how_many_people_will_benefit" })}
                            </StyledText>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "direct_beneficiaries" })}
                            </StyledText>
                            <Form.Item
                                name="directBeneficiaries"
                                className="formItem smallInput"
                                rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: intl.formatMessage({ id: "please_enter" }),
                                        },

                                     { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                ]}
                            >
                                <Input
                                    placeholder={intl.formatMessage({ id: "enter_estimated_number" })}
                                    type="text"
                                />
                            </Form.Item>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "indirect_beneficiaries" })}
                                <StyledText as="span" variant="B3a">
                                    {` (${intl.formatMessage({ id: "optional" })})`}
                                </StyledText>
                            </StyledText>
                            <Form.Item
                                name="indirectBeneficiaries"
                                className="formItem smallInput"
                                rules={[
                                    { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                ]}
                            >
                                <Input
                                    placeholder={intl.formatMessage({ id: "enter_estimated_number" })}
                                    type="text"
                                />
                            </Form.Item>
                        </>}
                        {formData.checkboxGroup?.includes("beneficiaryEnvironment") && <>
                            <StyledText as="p" variant="B3d">
                                {intl.formatMessage({ id: "how_will_the_environment_benefit" })}
                            </StyledText>
                            <StyledText as="p" variant="B3">
                                {intl.formatMessage({ id: "for_example_species_of_new_plants_introduced_ppm_of_CO2_reduced_terrestrial_animals_protected" })}
                            </StyledText>
                            <Form.List name="environmentalMeasure">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name }, index) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 0, flexWrap: 'wrap' }} className='optionsContainer'>
                                                <Form.Item label="" name={[name, 'category']} rules={[
                                                    {
                                                        whitespace: true,
                                                        required: true,
                                                        message: intl.formatMessage({ id: "please_select" }),
                                                    },
                                                ]}>
                                                    <Select
                                                        allowClear
                                                        placeholder={intl.formatMessage({ id: "select_type_title" })}
                                                        type="formSelect"
                                                        options={categories.map((value, index) => ({
                                                            key: value?.category,
                                                            label: value?.category,
                                                            value: value?.category
                                                        }))
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item label="" name={[name, 'subCategory']} rules={[
                                                    {
                                                        whitespace: true,
                                                        required: true,
                                                        message: intl.formatMessage({ id: "please_select" }),
                                                    },
                                                ]}>
                                                    <Select
                                                        allowClear
                                                        placeholder={intl.formatMessage({ id: "select_sub_type" })}
                                                        type="formSelect"
                                                        options={categories
                                                            ?.find(
                                                                (item) => item.category
                                                                    === formData?.environmentalMeasure?.[index]?.category)
                                                            ?.subCategory?.map((el) => ({
                                                                key: el,
                                                                label: el,
                                                                value: el
                                                            }))}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="" name={[name, 'description']} rules={[
                                                    {
                                                        whitespace: true,
                                                        required: true,
                                                        message: intl.formatMessage({ id: "please_select" }),
                                                    },
                                                ]}>
                                                    <Select
                                                        allowClear
                                                        placeholder={intl.formatMessage({ id: "select_action" })}
                                                        type="formSelect"
                                                        options={categories
                                                            ?.find(
                                                                (item) => item.category
                                                                    === formData?.environmentalMeasure?.[index]?.category)
                                                            ?.actions?.map((el) => ({
                                                                key: el,
                                                                label: el,
                                                                value: el
                                                            }))}
                                                    />
                                                </Form.Item>
                                                <Form.Item className='inputElement' name={[name, 'amount']} rules={[
                                                    {
                                                        whitespace: true,
                                                        required: true,
                                                        message: intl.formatMessage({ id: "please_enter" }),
                                                    },
                                                    { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                ]}>
                                                    <Input
                                                        placeholder={intl.formatMessage({ id: "enter_amount" })}
                                                    />
                                                </Form.Item>
                                                <Form.Item label="" name={[name, 'unit']} rules={[
                                                    {
                                                        whitespace: true,
                                                        required: true,
                                                        message: intl.formatMessage({ id: "please_select" }),
                                                    },
                                                ]}>
                                                    <Select
                                                        allowClear
                                                        placeholder={intl.formatMessage({ id: "select_units" })}
                                                        type="formSelect"

                                                        options={categories
                                                            ?.find(
                                                                (item) => item.category
                                                                    === formData?.environmentalMeasure?.[index]?.category)
                                                            ?.units?.map((el) => ({
                                                                key: el,
                                                                label: el,
                                                                value: el
                                                            }))}
                                                    />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ fontSize: '20px', color: '#04ac9c' }} className='for' />
                                            </Space>
                                        ))}
                                        {formListError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {formListError}
                                            </StyledText>
                                        )}
                                        <Form.Item>
                                            <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>

                                                <StyledText as="p" variant="B3">
                                                    {intl.formatMessage({ id: "add" })}
                                                </StyledText>
                                            </AntdButton>
                                        </Form.Item>
                                        {formData.environmentalMeasure?.map((el) => (
                                            el?.amount && el?.unit && el?.subCategory && el?.category && el?.description ? (
                                                <StyledText as="p" key={el?.amount} variant="B3b">
                                                    {`${el.amount} ${(el.category === 'Habitats' || el.category === 'Species') && el.unit !== 'Number' ? el.unit : ''} ${el.unit === 'Hectares' ? 'of ' : ''}${el.subCategory} ${el.category === 'Habitats' ? `${intl.formatMessage({ id: 'habitats' })} ` : ''} ${el.description}`}
                                                </StyledText>
                                            ) : null
                                        ))}
                                    </>
                                )}
                            </Form.List>
                            <></>
                        </>}
                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={handlePrevious}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={handleSaveAndExit}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className='tipsContainer'>
                    <div className='titleContainer'>
                        <Avatar src={lightbulb} />
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "tips" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "environmental_beneficiaries" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "the_environmental_beneficiaries_drop_down_fields_enable_you_to_share_additional_details" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "select_type_title_head" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "choose_whether_your_project_supports_habitats_such_as_forests_or_woodlands" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "select_sub_type_head" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "choose_the_type_of_habitat_species_or_building_you_are_supporting" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "select_action_head" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "choose_the_type_of_action_you_re_taking_to_support_the_species" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "enter_amount_head" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "for_species_estimate_how_many_plants_or_animals_you_re_supporting_through_your_project" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "for_habitats_estimate_how_many_habitats_you_re_supporting_number_or_the_total_area_in_hectares" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "for_buildings_estimate_how_many_buildings_you_re_supporting" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "objective_protect_and_improve_the_local_environment" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "outcome_improve_fish_populations_through_river_restoration" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "we_will_remove_excess_silt" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "select_units_head" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "number_is_an_estimate_of_the_total_number_of_habitats_species_or_buildings_you_plan_to_support" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "hectares_is_an_estimate_of_the_total_area_of_a_habitat_youre_supporting" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "social_beneficiaries" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "select_the_type_of_beneficiaries_your_project_supports" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "direct_beneficiaries_head" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "the_number_of_people_who_directly_benefit_from_your_opportunity" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "indirect_beneficiaries_head" })}
                        </StyledText>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "the_number_of_people_whose_benefits_can_be_attributed_to_a_direct_beneficiary" })}
                        </StyledText>
                    </div>
                    <div>
                        <StyledText as="p" variant="B3">
                            {intl.formatMessage({ id: "for_example_if_your_project_supports_children_with_learning_disabilitie" })}
                        </StyledText>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default styled(CharityImpactProgram)`
&.charityImpactProgram{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    .smallInput{
        width: 18%;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 250vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .ant-form{
        width: 50vw;
        display: flex;
        flex-direction: column;
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .formItemTextFieldPart{
        margin-bottom: 5px !important;
        width: 800px;
        .ant-form-item-control{
            width: 300px;
        }
    }
    .ant-col-6{
        max-width: 100%;
    }
    .for {
        margin-bottom: 20px;
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }
    .formContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
    }

    .outcomeUNSDGImagesContainer{
        display: flex;
        gap: 20px;
        .ant-avatar{
            border-radius: 0 !important;
            height: 50px !important;
            width: 50px !important;
        }
    }

    .checkboxGroup{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .objectiveUNSDGImagesContainer{
            margin-left: 25px;
            display: flex;
            gap: 20px;
            .ant-avatar{
                border-radius: 0 !important;
                height: 50px !important;
                width: 50px !important;
            }
        }
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
}
`;
