import en from './en';
import fi from './fi';
import es from './es';

const locale = {
  en,
  fi,
  es
};

export default locale;