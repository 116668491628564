import React from "react";
import { Tag } from "antd";
import styled from "styled-components";
import { theme } from '../theme/ThemeConfig';

const StyledAntDTag = styled(Tag)`
  background: ${(props) => theme?.tags?.[props?.type]?.background} !important;
  border: ${(props) => theme?.tags?.[props?.type]?.border} !important;
  border-style: solid !important;
  border-width: ${(props) => theme?.tags?.[props?.type]?.borderSize} !important;
  border-radius: ${(props) => theme?.tags?.[props?.type]?.edges} !important;
  color: ${(props) => theme?.tags?.[props?.type]?.textColor} !important;
`;

const CustomTag = ({ type, children }) => {
  return <StyledAntDTag type={type}>{children}</StyledAntDTag>;
};

export default CustomTag;