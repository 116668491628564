import React from 'react';
import styled from "styled-components";
import clsx from "clsx";
import StyledText from "./StyledText";

function getMaxValue(number) {
    const digitCount = number.toString().length;
    const baseValue = Math.pow(10, digitCount - 1);
    const maxValue = baseValue * 10;

    return maxValue;
}

const HorizontalGraph = ({
    className, name = '', percentage = 0, style, containerStyle, background, titleStyle, maxValue
}) => {
    const maxValuePercentage = maxValue || getMaxValue(percentage);
    const filledWidth = `${(percentage / maxValuePercentage) * 100}%`;
    const baseClassName = clsx("horizontalGraphContainer", className);

    return (
        <div className={baseClassName} style={containerStyle}>
            <StyledText as="p" variant="B3" className="title" style={titleStyle}>
                {name}
            </StyledText>
            <div className="graphContainer" style={style}>
                <div className="graphFilled" style={{ width: filledWidth, background }} />
            </div>
            <StyledText as="p" variant="B3" className="number">
                {percentage}
            </StyledText>
        </div>
    );
};

const StyledHorizontalGraph = styled(HorizontalGraph)`
  &.horizontalGraphContainer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: max-content;
    width: 100%;
    .graphContainer {
      width: 50%;
      height: 20px;
      background-color: #e0e0e0;
      border-radius: 4px;
      overflow: hidden;
    }
    .graphFilled {
      height: 100%;
      background-color: #04ac9c;
    }
    .number {
      margin-left: 10px;
    }
    .title {
      margin-right: 10px;
      width: 50px;
    }
  }
`;

export default StyledHorizontalGraph;
