import React, { useState, useEffect } from "react";
import { Alert } from "antd";
import clsx from "clsx";
import styled from "styled-components";
import StyledText from "./StyledText";

const CustomAlert = ({ className, message, visible, showAfterTime, ...rest }) => {
    const baseClassName = clsx("customAlert", className);
    const [alertVisible, setAlertVisible] = useState(visible);

    useEffect(() => {
        if (!showAfterTime) {
            if (visible) {
                const timer = setTimeout(() => {
                    setAlertVisible(false);
                }, 3000);

                return () => clearTimeout(timer);
            } else {
                setAlertVisible(false);
            }
        }
    }, [visible]);

    return alertVisible ? (
        <Alert
            className={baseClassName}
            message={
                <StyledText as="p" variant="CTA" >
                    {message}
                </StyledText>
            }
            type="success"
            showIcon
            {...rest}
        />
    ) : null;
};

const StyledCustomAlert = styled(CustomAlert)`
&.customAlert{
    position: ${props => props.position || 'fixed'};
    top: ${props => props.top || 0};
}
`

export default StyledCustomAlert;
