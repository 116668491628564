import React from 'react';
import { Carousel } from 'antd';
import clsx from "clsx";
import styled from "styled-components";
import StyledText from './StyledText';
import 'antd/dist/reset.css';

const CustomCarousel = ({ className, data, ...rest }) => {
    const baseClassName = clsx("customCarousel", className);
    return (
        <Carousel
            {...rest}
            className={baseClassName}
        >
            {data?.map((item, index) => (
                <div key={item.url} className='carouselItem'>
                    <img
                        src={item.url}
                        alt={item.name}
                        className='carouselImage'
                    />
                    {item?.mediaDescription && (
                        <div className='mediaDescriptionContainer'>
                            <StyledText
                                as="p"
                                variant="B3"
                                className='mediaDescription'
                            >
                                {item?.mediaDescription}
                            </StyledText>
                        </div>
                    )}
                </div>
            ))}
        </Carousel>
    );
};

const StyledCustomCarousel = styled(CustomCarousel)`
  &.customCarousel {
    .carouselItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .carouselImage {
        width: 100%;
    height: 350px;
    object-fit: contain;
    }

    .mediaDescriptionContainer {
      margin-top: 15px;
      padding: 0 10px;
      width: 100%;
    }

    .mediaDescription {
      word-wrap: break-word;
      white-space: pre-wrap;
      text-align: center;
      max-width: 75vw;
    }
  }
`;

export default StyledCustomCarousel;
