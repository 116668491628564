import styled from 'styled-components';
import { theme } from '../theme/ThemeConfig';

const BaseText = styled.div`
  font-family: 'Poppins', sans-serif;
  margin: 0;
`;

const StyledText = styled(BaseText)`
  font-size: ${(props) => theme.textStyles[props.variant]?.fontSize}px;
  font-weight: ${(props) => theme.textStyles[props.variant]?.fontWeight};
  color: ${(props) => theme.textStyles[props.variant]?.color};
  line-height: ${(props) => theme.textStyles[props.variant]?.lineHeight}px;
  letter-spacing: ${(props) => theme.textStyles[props.variant]?.letterSpacing};
  opacity: ${(props) => theme.textStyles[props.variant]?.opacity};
  font-family: ${(props) => theme.textStyles[props.variant]?.fontFamily};
  max-width: 70ch;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export default StyledText;