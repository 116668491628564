const INITIAL_STATE = {
  loggedIn: false,
  userImages: [],
}

const handleDefaultCase = (state, action) => {
  const { payload, key: stateKey } = action;
  if (
    payload &&
    typeof payload === "object" &&
    Object.keys(payload).length === 1 &&
    !stateKey
  ) {
    const [key, value] = Object.entries(payload)[0];
    return { ...state, [key]: value };
  } else if (payload !== undefined) {
    return {
      ...state,
      [stateKey]: payload,
    };
  }
  return state;
};

const reducer = (state = INITIAL_STATE, { payload, key: stateKey, type } = {}) => {
  switch (type) {
    case 'RESET_REDUX_STATE':
      return state;
    case 'SET_USER_IMAGE':
      return { ...state, userImages: [...state.userImages, payload] };
    default:
      return handleDefaultCase(state, { payload, key: stateKey, type });
  }
};

export default reducer;
