import React, { useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import styled from "styled-components";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import {
    useAppDispatch,
} from "../../../redux/store";
import { getProfileDataCompany } from "../../../redux/actions";

const CreateProfileStart = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getProfileDataCompany())
    }, [])

    const handleProceed = () => {
        navigate("/company/profile/create/entity")
    }

    return (
        <div className={baseClassName}>
            <div className="contentContainer">
                <StyledText as="p" variant="H1">
                    {intl.formatMessage({ id: "profile_create" })}
                </StyledText>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "lets_get_started" })}
                </StyledText>
            </div>

            <div className='buttonContainer'>
                <Divider />
                <Button
                    className='proceedButton'
                    variant="primary"
                    type="button"
                    onClick={() => handleProceed()}>
                    {intl.formatMessage({ id: "proceed" })}
                </Button>
            </div>
        </div>
    )
}

const StyledCreateProfile = styled(CreateProfileStart)`
&.createProfile{
    background: white;
    min-height: 94vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}`;

export default StyledCreateProfile;