import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { Space, Table, Button as AntButton, Input, Form, message } from "antd";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
    useAppSelector,
    useAppDispatch
} from "../../../redux/store";
import { getUser, editUserProfile } from "../../../redux/actions"
import { usersSelector, editUserProfileSelector } from "../../../redux/selectors";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import Tag from "../../../components/Tag";
import CustomModal from "../../../components/Modal";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const getUserActionsAndStatus = (userStatus, role) => {
    if (userStatus === "CONFIRMED" && role === "Regular") {
        return { actions: ["Make Admin", "Delete"], status: "REGULAR" };
    } else if (userStatus === "CONFIRMED" && role === "Admin") {
        return { actions: ["Remove Admin", "Delete"], status: "ADMIN" };
    } else {
        return { actions: ["", "Delete"], status: "INVITED" };
    }
};

const UserProfile = ({ className }) => {
    const baseClassName = clsx("userProfile", className);
    const [form] = Form.useForm();
    const [inviteUserPopUp, setInviteUserPopUp] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [makeAdmin, setMakeAdmin] = useState(false);
    const [removeOrganization, setRemoveOrganization] = useState(false);
    const [removeAdmin, setRemoveAdmin] = useState(false);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const users = useAppSelector(usersSelector) || {};
    const editUserData = useAppSelector(editUserProfileSelector);
    const [messageApi, contextHolder] = message.useMessage();

    const triggerError = () => {
        messageApi.open({
            type: 'error',
            content: intl.formatMessage({ id: "the_invited_user_already_has_an_account_linked_to_this_email_address" }),
            className: 'custom-class-error',
            style: { marginTop: '10vh' },
        });
    };

    const onFinish = (values) => {
        const data = {
            action: "invite",
            invitedUsers: [
                values.email
            ]
        };
        dispatch(editUserProfile(data))
    };

    const handleMakeAdmin = (values) => {
        const data = {
            action: "admin",
            email: currentUser.email,
            role: currentUser?.role,
            userID: currentUser?.userID
        };
        dispatch(editUserProfile(data))
    };

    const handleRemoveUser = () => {
        const data = {
            action: "delete",
            userID: [
                currentUser?.userID
            ]
        }
        dispatch(editUserProfile(data))
    }

    useEffect(() => {
        dispatch(getUser())
    }, []);

    useEffect(() => {
        if (editUserData) {
            if (Object.values(editUserData?.message)?.[0].includes('The invited user already has an account linked to this email address')) {
                triggerError()
            }
            setCurrentUser('')
            setInviteUserPopUp(false);
            setMakeAdmin(false);
            setRemoveAdmin(false);
            setRemoveOrganization(false);
            dispatch(getUser());
            form.resetFields();
        }
    }, [editUserData]);

    const columns = [
        {
            title: intl.formatMessage({ id: "user_name" }),
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: intl.formatMessage({ id: "email" }),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: intl.formatMessage({ id: "job_title" }),
            dataIndex: 'jobTitle',
            key: 'jobTitle',
        },
        {
            title: intl.formatMessage({ id: "status" }),
            key: 'status',
            dataIndex: 'status',
            render: (_, { UserStatus, role }) => {
                return (<Tag key={UserStatus} type={getUserActionsAndStatus(UserStatus, role)?.status?.toLowerCase()}>
                    {getUserActionsAndStatus(UserStatus, role)?.status}
                </Tag>)
            }
            ,
        },
        {
            title: intl.formatMessage({ id: "actions" }),
            key: 'actions',
            render: (_, report) => (
                <Space size="middle">
                    <button className='button' onClick={() => {
                        if (getUserActionsAndStatus(report?.UserStatus, report?.role)?.actions?.[0] === "Remove Admin") {
                            setRemoveAdmin(true)
                        } else {
                            setMakeAdmin(true)
                        }
                        setCurrentUser(report)
                    }}>{getUserActionsAndStatus(report?.UserStatus, report?.role)?.actions?.[0]}</button>
                    <button className='button' onClick={() => { setRemoveOrganization(true); setCurrentUser(report); }}>{getUserActionsAndStatus(report?.UserStatus, report?.role)?.actions?.[1]}</button>
                </Space>
            ),
        },
    ];

    const getTableData = (list) => {
        if (list?.length > 0) {
            return list?.map((item, idx) => {
                const {
                    username,
                    email,
                    jobTitle,
                    UserStatus,
                    ["custom:role"]: role,
                    userID,
                } = item || {};
                return {
                    key: idx,
                    username,
                    email,
                    jobTitle,
                    UserStatus,
                    role,
                    userID,
                };
            });
        }
        return null;
    }

    return (
        <div className={baseClassName}>
            <StyledText as="p" variant="H1">
                {intl.formatMessage({ id: "users" })}
            </StyledText>
            {contextHolder}
            <div className='contentContainer'>
                <Button className='inviteUserButton' variant="primary" type="button" onClick={() => setInviteUserPopUp(true)}>{intl.formatMessage({ id: "invite_new_user" })}</Button>
                <div className='subtitleContainer'>
                    <StyledText as="p" variant="B3d" className='title'>
                        {`${intl.formatMessage({ id: "regular_users" })} - `}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "can_not_view_or_edit_anything_by_default" })}
                    </StyledText>
                </div>
                <div className='subtitleContainer'>
                    <StyledText as="p" variant="B3d" className='title'>
                        {`${intl.formatMessage({ id: "organization_admins" })} - `}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "can_access_and_edit_everything_in_the_platform" })}
                    </StyledText>
                </div>
                {users?.length > 0 && <div className='subContentContainer'>
                    <Table columns={columns} dataSource={getTableData(users)} />
                </div>}
                <CustomModal
                    title={intl.formatMessage({ id: 'invite_new_user' })}
                    open={inviteUserPopUp}
                    onOk={() => setInviteUserPopUp(false)}
                    onCancel={() => setInviteUserPopUp(false)}
                    closable={false}
                    footer={null}>
                    <Form
                        {...formItemLayout}
                        form={form}
                        className="formContainer"
                        name="register"
                        onFinish={onFinish}
                        scrollToFirstError
                    >
                        <Form.Item name="email" hasFeedback rules={[
                            {
                                type: 'email',
                                message: intl.formatMessage({ id: 'the_input_is_not_valid_email' }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({ id: 'please_enter_email' }),
                            },
                        ]}
                        >
                            <Input placeholder={intl.formatMessage({ id: 'enter_email_address' })} />
                        </Form.Item>
                        <Form.Item name="confirmEmail" hasFeedback dependencies={['email']} rules={[
                            {
                                type: 'email',
                                message: intl.formatMessage({ id: 'the_input_is_not_valid_email' }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({ id: 'please_enter_email' }),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('email') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(`${intl.formatMessage({ id: 'the_new_email_that_you_entered_do_not_match' })}`));
                                },
                            }),
                        ]}
                        >
                            <Input placeholder={intl.formatMessage({ id: 'confirm_email_address' })} />
                        </Form.Item>
                        <div className='modalFooterButtonCenter'>
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={() => setInviteUserPopUp(false)}>
                                {intl.formatMessage({ id: "cancel" })}
                            </Button>
                            <Button
                                variant="primary"
                                htmlType="submit"
                                className='deleteButton'>
                                {intl.formatMessage({ id: "invite" })}
                            </Button>
                        </div>
                    </Form>
                </CustomModal>
                <CustomModal
                    title={intl.formatMessage({ id: 'make_admin' })}
                    open={makeAdmin}
                    onOk={() => setMakeAdmin(false)}
                    onCancel={() => { setMakeAdmin(false); setCurrentUser('') }}
                    closable={false}
                    footer={null}>
                    <>
                        <div className='textContainerModal'>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'make' })}
                            </StyledText>
                            <StyledText as="p" variant="B3d" className="title">
                                {currentUser.email}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'an_organization_admin' })}
                            </StyledText>
                        </div>
                        <StyledText as="p" variant="B3" className="title">
                            {intl.formatMessage({ id: 'they_will_be_able_to_view_and_edit_everything_in_your_organization' })}
                        </StyledText>
                        <div>
                            <div className='modalFooterButtonCenter'>
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => { setMakeAdmin(false); setCurrentUser('') }}>
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    htmlType="button"
                                    className='deleteButton'
                                    onClick={() => handleMakeAdmin()}>
                                    {intl.formatMessage({ id: "add_admin_rights" })}
                                </Button>
                            </div>
                        </div>
                    </>
                </CustomModal>
                <CustomModal
                    title={intl.formatMessage({ id: 'remove_admin_rights' })}
                    open={removeAdmin}
                    onOk={() => setRemoveAdmin(false)}
                    onCancel={() => { setRemoveAdmin(false); setCurrentUser('') }}
                    closable={false}
                    footer={null}>
                    <>
                        <div className='textContainerModal'>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'remove' })}
                            </StyledText>
                            <StyledText as="p" variant="B3d" className="title">
                                {currentUser.email}
                            </StyledText>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'as_an_Organization_Admin' })}
                            </StyledText>
                        </div>
                        <StyledText as="p" variant="B3" className="title">
                            {intl.formatMessage({ id: 'they_will_then_only_be_able_to_view_and_edit_programmes_that_they_manage' })}
                        </StyledText>
                        <div>
                            <div className='modalFooterButtonCenter'>
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => { setRemoveAdmin(false); setCurrentUser('') }}>
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                                <AntButton
                                    variant="primary"
                                    htmlType="button"
                                    className='deleteButton'
                                    onClick={() => handleMakeAdmin()}>
                                    {intl.formatMessage({ id: "remove_admin_rights" })}
                                </AntButton>
                            </div>
                        </div>
                    </>
                </CustomModal>
                <CustomModal
                    title={intl.formatMessage({ id: 'remove_user_from_your_organization' })}
                    open={removeOrganization}
                    onOk={() => setRemoveOrganization(false)}
                    onCancel={() => { setRemoveOrganization(false); setCurrentUser('') }}
                    closable={false}
                    footer={null}>
                    <>
                        <StyledText as="p" variant="B3" className="title">
                            {(`${intl.formatMessage({ id: 'please_confirm_you_are_to_permanently_remove_this_user' })}`)}
                        </StyledText>
                        <StyledText as="p" variant="B3" className="title">
                            {intl.formatMessage({ id: 'none_of_their_programmes_or_interaction_data_will_be_removed' })}
                        </StyledText>
                        <div>
                            <div className='modalFooterButtonCenter'>
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => { setRemoveOrganization(false); setCurrentUser('') }}>
                                    {intl.formatMessage({ id: "cancel" })}
                                </Button>
                                <AntButton
                                    variant="primary"
                                    htmlType="button"
                                    className='deleteButton'
                                    onClick={() => handleRemoveUser()}>
                                    {intl.formatMessage({ id: "remove_user" })}
                                </AntButton>
                            </div>
                        </div>
                    </>
                </CustomModal>
            </div>
        </div >
    )
}

const StyledUserProfile = styled(UserProfile)`
&.userProfile{
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .textContainerModal{
        display: flex;
        gap: 4px;
    }
    .button{
        border: 0;
        background: transparent;
        color: #5550F7;
        cursor: pointer;
        min-width: 100px;
    }
    .contentContainer{
        margin-block: 20px;
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 10px;
        .title{
            white-space: nowrap;
        }
        .subtitleContainer{
        display: flex;
        align-items: flex-start;
        gap: 4px;
       }
       .inviteUserButton{
        width: max-content;
       }
        .titleContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .subContentContainer{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}`;

export default StyledUserProfile;