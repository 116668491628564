import React from "react";
import { useIntl } from "react-intl";
import styled from 'styled-components';
import { Modal } from "antd";
import StyledText from "./StyledText";
import * as constants from "../constants";
import clsx from "clsx";

const TimeOutModal = ({
    remainingTime,
    showTimeoutModal,
    className,
    handleLogoutClick,
    handleStayLoggedInClick
}) => {
    const intl = useIntl();
    const baseClassName = clsx("timeOutModal", className);

    return (
        <Modal
            className={baseClassName}
            title={<StyledText as="h1" variant="H1" className="status">
                {intl.formatMessage({ id: 'your_session_will_expire_in' })}
            </StyledText>}
            open={showTimeoutModal}
            okText={intl.formatMessage({ id: 'stay_logged_in' })}
            cancelText={intl.formatMessage({ id: 'log_out' })}
            closable={false}
            footer={null}
        >
            <StyledText as="h2" variant="H2" className="remainingTime">
                {remainingTime}
            </StyledText>
            <StyledText as="p" variant="B3" className="status">
                {intl.formatMessage({ id: 'select_whether_you_would_like_to_stay_logged_in_or_log_out' })}
            </StyledText>
            <div className="buttonContainer">
                <button type="button" className="logout" onClick={handleLogoutClick}>{intl.formatMessage({ id: 'log_out' })}</button>
                <button type="button" className="signIn" onClick={handleStayLoggedInClick}>{intl.formatMessage({ id: 'stay_logged_in' })}</button>
            </div>
        </Modal>
    );
};

const StyledTimeOutModal = styled(TimeOutModal)`
  &.timeOutModal {
    .remainingTime{
            margin-block: 15px;
        }
    .buttonContainer{
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-top: 20px;

        button{
            cursor: pointer;
            padding-inline: 0;
            border: 1px solid transparent;
            background: transparent;
            font-size: 12px;
            font-weight: 500;
        }
        .signIn{
            color: white;
            background: ${constants.PRIMARY_DEFAULT_MENU_COLOR};
            border-width: ${constants.BORDER_SIZE_DEFAULT};
            border-radius: ${constants.BORDER_RADIUS_DEFAULT};
            font-size: 14px;
            border-color: ${constants.PRIMARY_DEFAULT_MENU_COLOR};
            padding: ${constants.BORDER_RADIUS_DEFAULT};
        }
        .logout{
            color: ${constants.PRIMARY_DEFAULT_MENU_COLOR};
            border-width: ${constants.BORDER_SIZE_DEFAULT};
            border-radius: ${constants.BORDER_RADIUS_DEFAULT};
            font-size: 14px;
            border-color: ${constants.PRIMARY_DEFAULT_MENU_COLOR};
            padding: ${constants.BORDER_RADIUS_DEFAULT};
        }
        }
    }
`;

export default StyledTimeOutModal;