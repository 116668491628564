import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { jwtDecode } from 'jwt-decode';
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ImgCrop from 'antd-img-crop';
import { Divider, Upload, Input, Form, Button as AntButton } from "antd";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import { getProfileDataCompany, editUserProfile } from "../../../redux/actions";
import {
    useAppDispatch,
} from "../../../redux/store";
import Config from "../../../config"
import { processPicture, uploadFile, get, formatTimestamp, getLocalStorageItem, getSecuredUrl, onFileRemovedHandler } from "../../../utils/utilities";
import CustomModal from "../../../components/Modal";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const EditMyProfile = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [processedLogoS3URL, setProcessedLogoS3URL] = useState([]);
    const [picture, setPicture] = useState([]);
    const [pictureData, setPictureData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const decodeToken = (getLocalStorageItem('id_Token') && jwtDecode(getLocalStorageItem('id_Token'))) || {};

    useEffect(() => {
        dispatch(getProfileDataCompany())
    }, [])

    useEffect(() => {
        if (picture) {
            const fetchProcessedLogoS3URL = async () => {
                const processedFiles = await processPicture(picture);
                setProcessedLogoS3URL(processedFiles);
            };
            fetchProcessedLogoS3URL();
        }
    }, [picture]);

    const afterFileRemoval = () => {
        setPicture([]);
        setIsModalOpen(false)
    }

    const onDelete = () => {
        onFileRemovedHandler(pictureData, afterFileRemoval)
    }

    const onChange = ({ fileList: newFileList }) => {
        if (newFileList?.length === 0) {
            setIsModalOpen(true)
            return
        }
        setPicture(newFileList)
    };

    const setLogoImage = async (fileKey) => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setPicture([securedURL]);
    };

    useEffect(() => {
        const decodedToken = (getLocalStorageItem('id_Token') && jwtDecode(getLocalStorageItem('id_Token'))) || {};
        form.setFieldsValue({
            name: decodedToken?.name,
            phoneNumber: decodedToken?.['custom:phoneNumber'],
            jobTitle: decodedToken?.["custom:job"]
        });
    }, [decodeToken])

    useEffect(() => {
        if (decodeToken?.picture) {
            setLogoImage(decodeToken?.picture);
            setPictureData(decodeToken?.picture)
        }
    }, [decodeToken?.picture]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const data = {
            name: e?.name,
            action: 'update',
            phoneNumber: e?.phoneNumber,
            jobTitle: e?.jobTitle,
            picture: pictureData?.fields?.key,
        }
        dispatch(editUserProfile(data));
        setTimeout(() => {
            navigate("/company/my-profile/edit");
        }, 500)
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadFileToS3 = async (presignedPostData, file, onProgress) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },

            };
            setPictureData(presignedPostData?.fields?.key)
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });

            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const getPresignedPostData = async (selectedFile) =>
        new Promise((resolve, reject) => {
            const fileName = (selectedFile.name).replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
            const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
            get(url, (res, err) => {
                if (res && res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(err.response);
                }
            });
        });

    const processFileUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file, onProgress);
            onSuccess(null, file);
        } catch (e) {
            onError(e);
        }
    };

    return (
        <div className={baseClassName}>
            <Form
                {...formItemLayout}
                labelAlign="left"
                onFinish={handleProceed}
                onFinishFailed={onFinishFailed}
                form={form}
                className="formContainer"
                scrollToFirstError
            >
                <div className="mainContentContainer">
                    <div className="contentContainer">
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "my_details" })}
                        </StyledText>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "your_name" })}
                        </StyledText>
                        <Form.Item
                            name="name"
                            className="formItem"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "enter",
                                })}
                            />
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "phone_number" })}
                        </StyledText>
                        <Form.Item
                            name="phoneNumber"
                            className="formItem"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "enter",
                                })}
                            />
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "job_title" })}
                        </StyledText>
                        <Form.Item
                            name="jobTitle"
                            className="formItem"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "enter",
                                })}
                            />
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "member_since" })}
                        </StyledText>
                        {decodeToken?.["custom:dateCreated"] &&
                            <StyledText as="p" variant="B3">
                                {formatTimestamp(decodeToken?.["custom:dateCreated"])}
                            </StyledText>}
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "add_your_social_media_details" })}
                            <StyledText as="span" variant="B3a">
                                {` (${intl.formatMessage({ id: "optional" })})`}
                            </StyledText>
                        </StyledText>
                        <ImgCrop rotationSlider>
                            <Upload
                                maxCount={1}
                                listType="picture-circle"
                                onChange={onChange}
                                fileList={processedLogoS3URL}
                                onPreview={onPreview}
                                customRequest={({
                                    onSuccess, onError, file, onProgress
                                }) => processFileUpload({
                                    onSuccess, onError, file, onProgress
                                })}
                            >
                                {processedLogoS3URL?.length < 1 && `+ ${intl.formatMessage({ id: "upload" })}`}
                            </Upload>
                        </ImgCrop>
                        <CustomModal
                            title={intl.formatMessage({ id: 'delete_file_confirmation' })}
                            open={isModalOpen}
                            onOk={() => setIsModalOpen(false)}
                            onCancel={() => setIsModalOpen(false)}
                            closable={false}
                            footer={null}>
                            <>
                                <StyledText as="p" variant="B3" className="title">
                                    {intl.formatMessage({ id: 'please_confirm_you_are_happy_to_permanently_delete_this_file' })}
                                </StyledText>
                                <div>
                                    <div className='modalFooterButton'>
                                        <Button
                                            variant="secondary"
                                            htmlType="button"
                                            onClick={() => setIsModalOpen(false)}>
                                            {intl.formatMessage({ id: "cancel" })}
                                        </Button>
                                        <AntButton
                                            variant="primary"
                                            htmlType="button"
                                            className='deleteButton'
                                            onClick={() => onDelete()}>
                                            {intl.formatMessage({ id: "delete" })}
                                        </AntButton>
                                    </div>
                                </div>
                            </>
                        </CustomModal>
                    </div>
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="primary"
                                    htmlType="submit"
                                >
                                    {intl.formatMessage({ id: "complete" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

const StyledEditMyProfile = styled(EditMyProfile)`
&.createProfile{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 15px;
    .formContainer {
        display: grid;
        gap: 20px;
    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 90vh;
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 15px;

            .formItem {
                width: 400px;
                margin-bottom: 0;
            }
        }
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
            }
        }
    }
    .linkContainer{
        display: flex;
        align-items: center;
        gap: 20px;
       }
    }
}`;

export default StyledEditMyProfile;