import image1 from '../../assets/stockImagesSmall/01_500.jpg';
import image2 from '../../assets/stockImagesSmall/02_500.jpg';
import image3 from '../../assets/stockImagesSmall/03_500.jpg';
import image4 from '../../assets/stockImagesSmall/04_500.jpg';
import image5 from '../../assets/stockImagesSmall/05_500.jpg';
import image6 from '../../assets/stockImagesSmall/06_500.jpg';
import image7 from '../../assets/stockImagesSmall/07_500.jpg';
import image8 from '../../assets/stockImagesSmall/08_500.jpg';
import image9 from '../../assets/stockImagesSmall/09_500.jpg';
import image10 from '../../assets/stockImagesSmall/10_500.jpg';
import image11 from '../../assets/stockImagesSmall/11_500.jpg';
import image12 from '../../assets/stockImagesSmall/12_500.jpg';
import image13 from '../../assets/stockImagesSmall/13_500.jpg';
import image14 from '../../assets/stockImagesSmall/14_500.jpg';
import image15 from '../../assets/stockImagesSmall/15_500.jpg';
import image16 from '../../assets/stockImagesSmall/16_500.jpg';
import image17 from '../../assets/stockImagesSmall/17_500.jpg';
import image18 from '../../assets/stockImagesSmall/18_500.jpg';
import image19 from '../../assets/stockImagesSmall/19_500.jpg';
import image20 from '../../assets/stockImagesSmall/20_500.jpg';
import image22 from '../../assets/stockImagesSmall/22_500.jpg';
import image23 from '../../assets/stockImagesSmall/23_500.jpg';
import image24 from '../../assets/stockImagesSmall/24_500.jpg';
import image25 from '../../assets/stockImagesSmall/25_500.jpg';
import image26 from '../../assets/stockImagesSmall/26_500.jpg';
import image27 from '../../assets/stockImagesSmall/27_500.jpg';
import image28 from '../../assets/stockImagesSmall/28_500.jpg';
import image29 from '../../assets/stockImagesSmall/29_500.jpg';

const bizGiveThemeImageData = [
  {
    title: 'one', id: 1, path: image1, checked: false
  },
  {
    title: 'two', id: 2, path: image2, checked: false
  },
  {
    title: 'three', id: 3, path: image3, checked: false
  },
  {
    title: 'four', id: 4, path: image4, checked: false
  },
  {
    title: 'five', id: 5, path: image5, checked: false
  },
  {
    title: 'six', id: 6, path: image6, checked: false
  },
  {
    title: 'seven', id: 7, path: image7, checked: false
  },
  {
    title: 'eight', id: 8, path: image8, checked: false
  },
  {
    title: 'nine', id: 9, path: image9, checked: false
  },
  {
    title: 'ten', id: 10, path: image10, checked: false
  },
  {
    title: 'eleven', id: 11, path: image11, checked: false
  },
  {
    title: 'tweleve', id: 12, path: image12, checked: false
  },

  {
    title: 'sevthreen', id: 13, path: image13, checked: false
  },
  {
    title: 'eight', id: 14, path: image14, checked: false
  },
  {
    title: 'nine', id: 15, path: image15, checked: false
  },
  {
    title: 'ten', id: 16, path: image16, checked: false
  },
  {
    title: 'eleven', id: 17, path: image17, checked: false
  },
  {
    title: 'seven', id: 18, path: image18, checked: false
  },
  {
    title: 'eight', id: 19, path: image19, checked: false
  },
  {
    title: 'nine', id: 20, path: image20, checked: false
  },
  {
    title: 'eleven', id: 22, path: image22, checked: false
  },
  {
    title: 'tweleve', id: 23, path: image23, checked: false
  },

  {
    title: 'sevthreen', id: 24, path: image24, checked: false
  },
  {
    title: 'eight', id: 25, path: image25, checked: false
  },
  {
    title: 'nine', id: 26, path: image26, checked: false
  },
  {
    title: 'ten', id: 27, path: image27, checked: false
  },
  {
    title: 'eleven', id: 28, path: image28, checked: false
  },
  {
    title: 'eleven', id: 29, path: image29, checked: false
  },
];


const ProgStartData = {
  bizGiveThemeImageData
};

export default ProgStartData;
