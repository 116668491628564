import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Divider, Form, Input, Steps, Avatar } from "antd";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import { editProfile, getProfileDataCompany } from "../../../redux/actions";
import { profileSelector } from "../../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";
import LinkedinIcon from "../../../assets/linkedin.png";
import InstaGramIcon from "../../../assets/instagram.png";
import TwitterIcon from "../../../assets/twitter.png";
import FacebookIcon from "../../../assets/facebook.png";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CreateProfileMedia = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(profileSelector)
    const [form] = Form.useForm();
    const [saveAndExit, setSaveAndExit] = useState(false);

    const {
        company: {
            legalName,
            tradingName,
            registeredAddress,
            description,
            ID,
            socialMedia: {
                linkedInURL,
                facebookURL,
                instagramURL,
                xURL,
                twitterURL
            } = {},
            webURI,
            logoS3URL
        } = {}
    } = profile || {};

    useEffect(() => {
        dispatch(getProfileDataCompany())
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            linkedInURL,
            facebookURL,
            instagramURL,
            xURL: twitterURL || xURL,
            webURI
        });
    }, [ID])

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            ID: ID,
            legalName,
            tradingName,
            registeredAddress,
            description,
            socialMedia: {
                linkedInURL: formData.linkedInURL,
                facebookURL: formData.facebookURL,
                instagramURL: formData.instagramURL,
                xURL: formData.xURL,
            },
            webURI: formData.webURI,
            logoS3URL
        };
        dispatch(editProfile(data));
        setTimeout(() => {
            if (saveAndExit) {
                setSaveAndExit(false);
                navigate("/company/my-profile/edit");
            } else {
                navigate("/company/profile/create/logo");
            }
        }, 500)
    }

    const handleSaveAndExit = () => {
        setSaveAndExit(true);
        form.submit();
    }

    const handlePrevious = () => {
        navigate("/company/profile/create/entity")
    }

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    return (
        <div className={baseClassName}>
            <Steps
                current={1}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                onFinish={handleProceed}
                onFinishFailed={onFinishFailed}
                className="formContainer"
                scrollToFirstError
            >
                <div className="mainContentContainer">
                    <div className="contentContainer">
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "media" })}
                        </StyledText>
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "add_your_social_media_details" })}
                            <StyledText as="span" variant="B3a">
                                {` (${intl.formatMessage({ id: "optional" })})`}
                            </StyledText>
                        </StyledText>

                        <div className='linkContainer'>
                            <Avatar src={LinkedinIcon} />
                            <Form.Item
                                name="linkedInURL"
                                className="formItem"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: intl.formatMessage({ id: "enter" }),
                                    },
                                ]}
                            >
                                <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                            </Form.Item>
                        </div>
                        <div className='linkContainer'>
                            <Avatar src={FacebookIcon} />
                            <Form.Item
                                name="facebookURL"
                                className="formItem"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: intl.formatMessage({ id: "enter" }),
                                    },
                                ]}
                            >
                                <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                            </Form.Item>
                        </div>
                        <div className='linkContainer'>
                            <Avatar src={InstaGramIcon} />
                            <Form.Item
                                name="instagramURL"
                                className="formItem"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: intl.formatMessage({ id: "enter" }),
                                    },
                                ]}
                            >
                                <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                            </Form.Item>
                        </div>
                        <div className='linkContainer'>
                            <Avatar src={TwitterIcon} />
                            <Form.Item
                                name="xURL"
                                className="formItem"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: intl.formatMessage({ id: "enter" }),
                                    },
                                ]}
                            >
                                <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                            </Form.Item>
                        </div>
                        <StyledText as="span" variant="B3d">
                            {intl.formatMessage({ id: "what_is_your_website" })}
                        </StyledText>
                        <Form.Item
                            name="webURI"
                            className="formItem"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <Input addonBefore="https://" placeholder={intl.formatMessage({ id: "enter" })} />
                        </Form.Item>
                    </div>
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => handlePrevious()}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    htmlType="submit">
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => handleSaveAndExit()}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

const StyledCreateProfileMedia = styled(CreateProfileMedia)`
&.createProfile{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    .formContainer {
    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 83vh;
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;
            display: grid;
            gap: 20px;
            .formItem {
                width: 450px;
                margin-bottom: 0;
            }
        }
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
            }
        }
    }
    .linkContainer{
        display: flex;
        align-items: center;
        gap: 20px;
       }
    }
}`;

export default StyledCreateProfileMedia;
