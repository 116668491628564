import React, { useCallback, useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Divider, Form, Input, Alert, Spin, Steps } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { editOpportunity, getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem, removeDuplicatesByKey, debounce } from "../../utils/utilities";
import MapBoxComponent from "../../components/MapBoxComponent";
import { opportunityDataSelector, programDataSelector, editOpportunityData, toggleSpinnerSelector } from "../../redux/selectors";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const { TextArea } = Input;

const flattenFeatures = (featureCollection) => {
    const featureType = featureCollection?.type;
    const flattenedFeatures = featureCollection?.features?.flat();
    return {
        type: featureType,
        features: flattenedFeatures
    };
};

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 6 },
};

const CharityProjectLocation = ({ className }) => {
    const baseClassName = clsx("charityProjectLocation", className);
    const [markers, setMarkers] = useState([]);
    const [showError, setShowError] = useState('');
    const [savedClicked, setSavedClicked] = useState(false);
    const [showErrorBox, setShowErrorBox] = useState(false);
    const [opportunityOutsidePolygonReason, setOpportunityOutsidePolygonReason] = useState(undefined);
    const [opportunityOutsidePolygonReasonFinal, setOpportunityOutsidePolygonReasonFinal] = useState(undefined);
    const intl = useIntl();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const opportunityData = useAppSelector(opportunityDataSelector) || {};
    const editOpp = useAppSelector(editOpportunityData) || {};
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { programmeLocation } = programData || {};
    const { opportunity: { opportunityIsInProgrammePolygon = '', opportunityLocation2: { features } = {} } = {} } = opportunityData || {};
    const { opportunityID } = editOpp || {}

    useEffect(() => {
        if (!features?.length) return
        setMarkers(features);
    }, [features]);

    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (opportunityID) {
            dispatch(getOpportunity(opportunityID));
        }
    }, [opportunityID]);

    useEffect(() => {
        if (!savedClicked && opportunityID && opportunityIsInProgrammePolygon === 'Y') {
            dispatch(setEditOpportunitiesData(''));
            dispatch(toggleSpinner(false));
            setTimeout(() => {
                navigate("/impact-maker/applications/create/dates");
            }, 500);
        } else if (opportunityIsInProgrammePolygon === 'Y' && savedClicked && opportunityID) {
            dispatch(setEditOpportunitiesData(''));
            dispatch(toggleSpinner(false));
            setTimeout(() => {
                navigate(`/impact-maker/programme/${programID}/${opportunityID}`);
            }, 500);
        } else if (opportunityIsInProgrammePolygon === 'N' && !showErrorBox && opportunityID) {
            dispatch(setEditOpportunitiesData(''));
            dispatch(toggleSpinner(false));
            setShowErrorBox(true);
        } else if (showErrorBox && opportunityOutsidePolygonReasonFinal && opportunityIsInProgrammePolygon === 'N') {
            dispatch(setEditOpportunitiesData(''));
            dispatch(toggleSpinner(false));
            setTimeout(() => {
                navigate("/impact-maker/applications/create/dates");
            }, 500);
        } else {
            dispatch(toggleSpinner(false));
        }
    }, [opportunityIsInProgrammePolygon, navigate, programID, opportunityID, savedClicked, opportunityOutsidePolygonReasonFinal]);

    const handleMarkerChange = useCallback((updatedMarkers) => {
        setMarkers(prev => {
            const existingMarkers = new Map(prev.map(marker => [marker.id, marker]));
            updatedMarkers.forEach(marker => {
                existingMarkers.set(marker.id, marker);
            });
            return Array.from(existingMarkers.values());
        });
    }, []);

    const debouncedHandleLocationChange = useCallback(debounce((e, markerId) => {
        const newLocationMapData = markers.map((location) =>
            location.id === markerId
                ? {
                    ...location,
                    place_name: e.target.value,
                    text: e.target.value,
                }
                : location
        );
        setMarkers(removeDuplicatesByKey(newLocationMapData, "id"));
    }, 3000), [markers]);

    const handleSaveAndExit = useCallback(() => {
        setSavedClicked(true);
        form.submit();
        dispatch(toggleSpinner(false));
    }, [form]);

    const handleProceed = () => {
        if(!markers.length) return setShowError(intl.formatMessage({ id: "please_save_a_location" }))
        const data = {
            opportunityLocation2: {
                type: "FeatureCollection",
                features: markers,
            },
            opportunityOutsidePolygonReason,
            programID,
            opportunityID: opportunityId,
        };
        dispatch(editOpportunity(data));
        dispatch(toggleSpinner(true));
        if ((!savedClicked && opportunityIsInProgrammePolygon !== 'N') || opportunityOutsidePolygonReason) {
            if (!showErrorBox) {
                setShowErrorBox(true);
            } else {
                dispatch(toggleSpinner(true));
                if (opportunityOutsidePolygonReason && showErrorBox) {
                    setOpportunityOutsidePolygonReasonFinal(opportunityOutsidePolygonReason)
                    dispatch(setEditOpportunitiesData(''));
                }
            }

        }
    };

    const handlePrevious = useCallback(() => {
        navigate("/impact-maker/applications/create/description");
    }, [navigate]);

    const onFinishFailed = useCallback((e) => {
        console.log("Failed:", e);
    }, []);

    const removeMarker = useCallback((id) => {
        setMarkers(prev => prev.filter(marker => marker.id !== id));
    }, []);

    useEffect(() => {
        if (markers) {
            markers.forEach((map) => {
                if (map?.id && map.place_name) {
                    form.setFieldsValue({
                        [map.id]: map.place_name,
                    });
                }
            });
            setOpportunityOutsidePolygonReasonFinal(undefined);
            setShowError('')
        }
    }, [markers, form]);

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(getOpportunity(opportunityID));
        }
    }, [opportunityID, dispatch]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID, dispatch]);

    const debouncedHandleTextFieldChange = useCallback(debounce((e) => {
        setOpportunityOutsidePolygonReason(e.target.value);
    }, 3000), []);

    return (
        <div className={baseClassName}>
            <Steps
                current={0}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "project_location" })}
            </StyledText>
            <StyledText as="p" variant="B3d" className='mainTitle'>
                {intl.formatMessage({ id: "where_is_your_project_located" })}
            </StyledText>
            <StyledText as="p" variant="B3" className='mainTitle'>
                {`1) ${intl.formatMessage({ id: "search_for_and_select_the_address_of_your_location" })}`} <strong>{intl.formatMessage({ id: "click" })}</strong> {intl.formatMessage({ id: "any_place_or_point_on_the_map_to_select_it" })}
            </StyledText>
            <StyledText as="p" variant="B3" className='mainTitle'>
                {`2) ${intl.formatMessage({ id: "add_or_Edit_the_name_for_your_location_and_press" })}`}
            </StyledText>
            {showError && <StyledText as="p" variant="B3" className='error'>
                {showError}
            </StyledText>}   
            <div className="mapBoxContainer">
                <MapBoxComponent
                    zoom={4}
                    defaultStyle="MonoCrome"
                    id="submission"
                    showSearchBox
                    markers={markers}
                    onMarkerChange={handleMarkerChange}
                    geoJsonData={(opportunityIsInProgrammePolygon !== 'any') && flattenFeatures(programmeLocation)}
                    hideStyles
                />
            </div>
            <div className='markersContainer'>
                <Form
                    {...formItemLayout}
                    labelAlign="left"
                    onFinish={handleProceed}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    className="formContainer"
                    scrollToFirstError
                >
                    {markers.map((marker) => (
                        <Form.Item
                            key={marker.id}
                            name={marker.id}
                            className='customFormItem'
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: "please_enter" }),
                                },
                            ]}
                        >
                            <Input
                                id={marker.id}
                                defaultValue={marker.placeName || marker.place_name}
                                className="locationNameInput"
                                onChange={(e) => debouncedHandleLocationChange(e, marker.id)}
                                placeholder={intl.formatMessage({ id: "enter" })}
                            />
                            <button className="removeIcon" onClick={() => removeMarker(marker.id)}><MinusCircleOutlined style={{ fontSize: '20px', color: '#04ac9c' }} /></button>
                        </Form.Item>
                    ))}
                    {markers?.length > 0 && showErrorBox && (
                        <>
                            <div className='alert'>
                                <Alert className='customAlert' message={
                                    <StyledText as="p" variant="CTA">
                                        {intl.formatMessage({ id: "one_or_more_project_locations_are_located_outside_of_the_programme_area" })}
                                    </StyledText>
                                }
                                    type="error"
                                    showIcon />
                                <StyledText as="p" variant="B3">
                                    {intl.formatMessage({ id: "please_change_your_project_location_or_explain_how_your_project_will_benefit_the_programme_area_below" })}
                                </StyledText>
                            </div>
                            <Form.Item
                                name={`map-textField`}
                                className='customFormItem'
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({ id: "please_enter" }),
                                    },
                                ]}
                            >
                                <TextArea
                                    className="locationNameInput"
                                    onChange={debouncedHandleTextFieldChange}
                                    placeholder={intl.formatMessage({ id: "enter" })}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </div>
            <div>
                <div className='buttonContainer'>
                    <Divider />
                    <div className='buttonContentContainer'>
                        <div className='nextAndPreviousContainer'>
                            <Button
                                variant="secondary"
                                type="button"
                                onClick={handlePrevious}>
                                {intl.formatMessage({ id: "previous" })}
                            </Button>
                            <Button
                                variant="primary"
                                htmlType="submit"
                                onClick={handleProceed}
                            >
                                {intl.formatMessage({ id: "next" })}
                            </Button>
                        </div>
                        <div className="saveAndExitButton">
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={handleSaveAndExit}>
                                {intl.formatMessage({ id: "save_n_exit" })}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const StyledCharityProjectLocation = styled(CharityProjectLocation)`
&.charityProjectLocation {
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 130vh;
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
            .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }
    .mainTitle {
        margin-bottom: 20px;
    }
    .error{
        color: red !important;
    }
    .formContainer {
        display: flex;
        gap: 0px;
        flex-direction: column;
        .customFormItem{
            .ant-form-item-control-input-content{
                display: flex !important;
            }  
        }
    }
    .alert{
        width: max-content;
        .ant-alert {
            margin-bottom: 15px;
        }
    }
    .mapBoxContainer {
        position: relative;
        height: 50vh;
        width: 50vw;
    }
    .markersContainer{
        margin-top: 20px;
    }
    .removeIcon{
        background: transparent !important;
        border: none !important;
        cursor: pointer !important;
    }
    .buttonContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton {
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer {
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
}`;

export default StyledCharityProjectLocation;
