import React, { useState, useEffect, Fragment } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    Divider, Form, Input, Spin, message,
    Upload,
    Steps
} from "antd";
import styled from "styled-components";
import axios from '../../utils/axios';
import Config from '../../config';
import { editOpportunity, getOpportunity, getProgramById, toggleSpinner, setEditOpportunitiesData, getIndicatorAnswers } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem, uploadFile, removeDuplicatesByKey } from "../../utils/utilities";
import {
    opportunityDataSelector,
    toggleSpinnerSelector,
    editOpportunityData,
    programDataSelector,
    indicatorDataSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CreateDescription = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [fileErrors, setFileErrors] = useState({});
    const [savedClicked, setSavedClicked] = useState(false);
    const [pictures, setPictures] = useState([]); // Allow multiple pictures
    const opportunityData = useAppSelector(opportunityDataSelector) || {}
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const indicators = useAppSelector(indicatorDataSelector) || {};
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { opportunity } = opportunityData || {};
    const [opportunitySummary, setOpportunitySummary] = useState('');
    const editOpp = useAppSelector(editOpportunityData) || {};
    const programData = useAppSelector(programDataSelector) || {};
    const { customQuestion, requiredDocuments } = programData || {};
    const { opportunityID } = editOpp || {};

    useEffect(() => {
        if (opportunity?.opportunityName) {
            // Prepare custom question answers
            const customQuestionAnswers = opportunity?.customQuestionAnswer.reduce((acc, { question, answer }, index) => {
                acc[`customQuestionAnswer_${index}`] = answer;
                return acc;
            }, {});

            // Map documents to include docName
            const documentsWithNames = (opportunity.document || []).map((doc, index) => {
                const docName = requiredDocuments?.find((requiredDoc, idx) => index === idx) || '';
                return { ...doc, docName };
            });

            const data = {
                ...customQuestionAnswers,
                opportunityID: opportunity.opportunityID,
                programID: opportunity.programID,
            };

            form.setFieldsValue(data);
            setPictures(documentsWithNames); // Set documents with docName
            setFormData(data);
        }
    }, [opportunity?.opportunityName, requiredDocuments]);


    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''))
            dispatch(toggleSpinner(false));
            navigate("/impact-maker/applications/create/award-criteria");
        }
    }, [opportunityID])


    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
            dispatch(getIndicatorAnswers({
                programID,
                opportunityID: opportunityId
            }))
        }
    }, [programID]);

    const validateFiles = async (fileList) => {
        try {
            const errors = {};
            for (const doc of requiredDocuments) {
                const fileExists = fileList.some(file => file.docName === doc);
                if (!fileExists) {
                    errors[doc] = `Please upload the ${doc}`;
                }
            }
            setFileErrors(errors);
            return Object.keys(errors).length === 0;
        } catch (error) {
            console.error('Error validating files:', error);
            setFileErrors({});
            return false;
        }
    };

    const onChange = ({ fileList: newFileList }, doc) => {
        const errors = validateFiles(newFileList)
        if (!errors) return;
        const updatedPictures = newFileList.map(async (file) => {
            if (file.status === 'uploading' && !file.key) {
                try {
                    const presignedPostData = await getPresignedPostData(file);
                    return {
                        ...file,
                        ...presignedPostData.data?.fields,
                        status: 'done',
                    };
                } catch (e) {
                    return file;
                }
            }
            return file;
        });

        Promise.all(updatedPictures).then(newFiles => {
            setPictures(prevPictures => {
                const mergedFiles = [...prevPictures, ...newFiles.map(file => ({ ...file, docName: doc }))];
                const uniqueFiles = mergedFiles.filter(
                    (file, index, self) => index === self.findIndex(f => f.uid === file.uid)
                );

                return uniqueFiles;
            });
        });
        setFileErrors({})
    };

    const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
        const fileName = selectedFile.name.replace(
            /(?:\.(?![^.]+$)|[^\w.])+/g,
            ''
        );
        const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
        axios.get(url, (res, err) => {
            if (res && res.status === 200) {
                resolve(res.data);
            } else {
                reject(err.response);
            }
        });
    });

    const uploadFileToS3 = async (presignedPostData, file, onProgress) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const processFileUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file, onProgress);
            onSuccess(null, file);
        } catch (e) {
            message.error(`File upload failed. ${e.message || ''}`);
            onError(e);
        }
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = async () => {
        try {
            const formData = form.getFieldsValue();
            const isNotValid = await validateFiles(pictures);
            if (!isNotValid && Object.keys(fileErrors).length) return;
            const customQuestionAnswer = customQuestion?.map((el, index) => ({
                question: el.question,
                answer: formData[`customQuestionAnswer_${index}`],
            }));

            // Prepare the data object
            const data = {
                customQuestionAnswer,
                opportunityID: opportunityId,
                document: removeDuplicatesByKey(pictures, 'uid'),
                programID
            };

            dispatch(editOpportunity(data));
            dispatch(toggleSpinner(true));

        } catch (error) {
            console.error('Error in handleProceed:', error);
        }
    };


    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            dispatch(toggleSpinner(false));
            form.submit();
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    }

    const handlePrevious = () => {
        if (indicators?.length > 0) {
            navigate("/impact-maker/applications/create/indicators");
        } else {
            navigate("/impact-maker/applications/create/beneficiaries");
        }
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(word => word.length > 0).length;
    };

    const handleTextChange = (key, maxWords) => (e) => {
        const text = e.target.value;
        if (countWords(text) <= maxWords) {
            setFormData(prev => ({
                ...prev,
                [key]: text
            }));
            form.setFieldsValue({
                [key]: text
            });
        }
    };

    const onDelete = (file) => {
        setPictures((prevPictures) => prevPictures.filter(picture => picture.uid !== file.uid));
    }

    const onRemove = async (file) => {
        const { deleteURL } = Config.dev.url;
        const data = { bucketName: 'bg-uploaded-media', fileName: file.key };
        axios.put(deleteURL, () => onDelete(file), data, true);
    };

    return (
        <div className={baseClassName}>
            <Steps
                current={3}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <StyledText as="p" variant="H1" className='mainTitle'>
                {intl.formatMessage({ id: "additional_questions" })}
            </StyledText>
            <Form
                {...formItemLayout}
                labelAlign="left"
                form={form}
                onFinish={handleProceed}
                onFinishFailed={onFinishFailed}
                className="formContainer"
                onValuesChange={onFormChange}
                scrollToFirstError
            >
                <StyledText as="p" variant="B3d">
                    {intl.formatMessage({ id: "required_documents" })}
                </StyledText>
                {requiredDocuments?.map((doc, index) => (
                    <Fragment key={`${doc}-${index}`}>
                        <StyledText as="p" variant="B3">
                            {doc}
                        </StyledText>
                        <div className='uploadWrapper'>
                            <div>
                                <Upload
                                    customRequest={processFileUpload}
                                    listType="picture-card"
                                    onPreview={onPreview}
                                    fileList={pictures?.filter(el => el.docName === doc) || []}
                                    onChange={({ fileList }) => onChange({ fileList }, doc)}
                                    onRemove={onRemove}
                                    maxCount={1}
                                >
                                    {pictures?.filter(el => el.docName === doc).length < 1 && '+ Upload'}
                                </Upload>
                                {fileErrors[doc] && (
                                    <StyledText as="p" variant="B3" className="error">
                                        {fileErrors[doc]}
                                    </StyledText>
                                )}
                            </div>
                            {pictures?.find(el => el.docName === doc)?.name && (
                                <div className='uploadInputWrapper'>
                                    <StyledText as="p" variant="B3">
                                        {pictures?.find(el => el.docName === doc)?.name}
                                    </StyledText>
                                </div>
                            )}
                        </div>
                    </Fragment>
                ))}
                {customQuestion?.map((el, index) => (
                    <>
                        <StyledText as="p" variant="B3d">
                            {el?.question}
                        </StyledText>
                        <div>
                            <Form.Item
                                name={`customQuestionAnswer_${index}`}
                                className="formItem"
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: intl.formatMessage({ id: "enter" }),
                                    },
                                ]}
                            >
                                <div className='formTextField'>
                                    <TextArea
                                        name={`customQuestionAnswer_${index}`}
                                        rows={2}
                                        placeholder={intl.formatMessage({ id: "enter_project_summary" })}
                                        value={formData?.[`customQuestionAnswer_${index}`]}
                                        onChange={handleTextChange(setOpportunitySummary, el?.questionCharLimit)}
                                    />
                                    <div className={`wordCount ${countWords(formData[`customQuestionAnswer_${index}`] || '') === el?.questionCharLimit ? 'error' : ''}`}>
                                        {countWords(formData[`customQuestionAnswer_${index}`] || '')}/{el?.questionCharLimit} words
                                    </div>
                                </div>
                            </Form.Item>

                        </div>
                    </>
                ))}
                <div className='buttonContainer'>
                    <Divider />
                    <div className='buttonContentContainer'>
                        <div className='nextAndPreviousContainer'>
                            <Button
                                variant="secondary"
                                type="button"
                                onClick={() => handlePrevious()}>
                                {intl.formatMessage({ id: "previous" })}
                            </Button>
                            <Button
                                variant="primary"
                                htmlType="submit"
                            >
                                {intl.formatMessage({ id: "next" })}
                            </Button>
                        </div>
                        <div className="saveAndExitButton">
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={() => handleSaveAndExit()}>
                                {intl.formatMessage({ id: "save_n_exit" })}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}

const StyledCreateDescription = styled(CreateDescription)`
&.createProfile{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 100vh;
    .error{
        color: red !important;
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
            .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
        }
        .formTextField{
        width: 100%;
        .ant-form-item{
            margin-bottom: 5px;
        }
    }
    .mainTitle{
        margin-bottom: 20px;
    }
    .formContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
    }

    .wordCount{
        text-align: right;
        &.error{
            color: red;
        }
    }
    .uploadWrapper{
        display: flex;
    gap: 20px;
    align-items: center;
    }

    .uploadWrapper .ant-form-item-control-input-content{
        display: flex !important;
        width: 100%;
        .ant-upload-list{
        display: flex;
        flex-direction: column !important;
    }
    .uploadInputWrapper{
        gap: 56px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 30px;
        width: 100%;
    }
    }

    .formItem .ant-form-item-explain-error{
        margin-top: -20px !important;
    }
    .ant-form-item{
        margin-bottom: 0px;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
}`;

export default StyledCreateDescription;