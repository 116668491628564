/* eslint-disable no-restricted-syntax */
const config = {
  dev: { url: {} },
};

export const mapBoxToken =
  "pk.eyJ1IjoiYW1qcDIwIiwiYSI6ImNsbzJmMXlzdTF2ajAycnFvOTU0azV3NHIifQ.HS4cNjDddR_DcCKm7WAL0w";

const newConfig = {
  dev: {
    host: "https://api.dev.bizgive.app",
    media: "https://media.dev.bizgive.app/",
    clouds3URL: "https://media.dev.bizgive.app/",
    platformURL: "https://dev.bizgive.app/#/",
  },
  qa: {
    host: "https://api.qa.bizgive.app",
    media: "https://media.qa.bizgive.app/",
    clouds3URL: "https://media.qa.bizgive.app/",
    platformURL: "https://qa.bizgive.app/#/",
  },
  prod: {
    host: "https://api.bizgive.app",
    media: "https://media.bizgive.app/",
    clouds3URL: "https://media.bizgive.app/",
    platformURL: "https://www.bizgive.app/#/",
  },
  dr: {
    host: "https://api.dr.bizgive.app",
    media: "https://media.dr.bizgive.app/",
    clouds3URL: "https://media.dr.bizgive.app/",
    platformURL: "https://www.bizgive.app/#/",
  },

  path: {
    signIn: '/api/company/signin',
    getProfile: '/api/company/get-profile',
    getComProfileById: '/api/company/get-profile?companyID=',
    createProfile: '/api/company/create-profile',
    editProfile: '/api/company/edit-profile',
    clouds3URL: 'https://d6yfmcefj0ziu.cloudfront.net/',
    forgetPasswordEmailSent: '/api/forgot-password',
    resetPassword: '/api/confirm-new-password',
    createPassword: '/api/new-password-challenge',
    getProgram: '/api/program/get-list?status=',
    getPortfolio: '/api/interactions/program-analytics-dashboard',
    getMyProgramList: '/api/program/get-list?type=my',
    getProgramById: '/api/program/get?programID=',
    programApi: '/api/program/create',
    updateProgram: '/api/program/update',
    getReportRelatedList: '/api/program/get-report-related-list',


    getAssets: '/api/program/get-assets',
    assetApi: '/api/program/create-asset',
    updateAsset: '/api/program/update-asset',
    getAssetById: '/api/program/get-asset?assetID=',
    getAssetMetalist: '/api/program/get-assetmeta-list',
    createAssetMetalist: '/api/program/update-asset',

    getNewTokenUrl: '/api/get-new-token',
    imageURL: '/dev/',
    socialMediaDetails: '/api/company/get-social-media',
    charitySocialMediaDetails: '/api/charity/get-social-media',
    getAdminDetail: '/api/company/get-admin-details',
    getAdminDetailCharity: '/api/charity/get-admin-details',
    getUser: '/api/get-users',
    getCharityProfile: '/api/charity/get-profile',
    getCharityProfileById: '/api/charity/get-profile?charityID=',
    signup: '/api/charity/signup',

    createCharityProfile: '/api/charity/create-profile',
    editCharityProfile: '/api/charity/edit-profile',
    companyLogoUploadUrl: '/api/get-s3-signed-url',
    createOpportunity: '/api/opportunity/create',
    editOpportunity: '/api/opportunity/edit',
    getOpportunityList: '/api/opportunity/get-list',
    getMyOpportunityList: '/api/opportunity/get-list?type=my',
    getOpportunity: '/api/opportunity/get?opportunityID=',
    getDiscoverData: '/api/program/discover',
    getInteraction: '/api/interactions/get-interactions2',
    addInteraction: '/api/interactions/add-interaction',
    getOpportunityDiscoverData: '/api/opportunity/discover',
    deleteOpportunity: '/api/delete',
    editUserProfile: '/api/edit-user-profile',
    getMilestone: '/api/interactions/get-timeline',
    getConversation: '/api/interactions/get-message',
    sendConversation: '/api/interactions/send-message',
    getUserImage: '/api/get-picture?userID=',
    checkDuplicateProgName: '/api/is-present?programName=',
    checkDuplicateOppName: '/api/is-present?opportunityName=',
    sharePreApproved: '/api/share-pre-approved',
    voteOpportunity: '/api/vote-opportunity',
    getContent: '/api/interactions/get-content',
    addContent: '/api/interactions/add-content',
    getDonation: '/api/donation/get-donations',
    getDonationList: '/api/donation/get-donation-list',
    addDonation: '/api/donation/add-donation',
    deleteDonation: '/api/donation/delete-donation',
    getDashboardData: '/api/interactions/operation-dashboard',
    getImpactDashboardData: '/api/interactions/impact-dashboard',
    getAnalyticsDashboard: '/api/interactions/analytics-dashboard',
    downloadDonation: '/api/donation/export-donation-list',
    downloadContent: '/api/interactions/content-export',
    baseURL: '/#/',
    unsdgtree: '/api/program/get-unsdgtree',
    indicatorStatusList: '/api/opportunity/get-list',
    createIndicator: '/api/opportunity/create-indicator',
    updateteIndicator: '/api/opportunity/edit-indicator',
    deleteIndicator: '/api/opportunity/delete-indicator',
    getAllIndicator: '/api/opportunity/get-indicators',
    getSingleIndicator: '/api/opportunity/get-indicator',
    StatusConfirmation: '/api/interactions/add-interaction',
    getMyReportData: '/api/program/get-report-list',
    getReportSubmissionList: '/api/program/get-report-submission-list',
    manageReportPopUpData: '/api/program/get-report-related-list',
    createReport: '/api/program/create-report',
    updateReport: '/api/program/update-report',
    reportSubmission: '/api/program/report-submission',
    getReportSubmission: '/api/program/get-report-activity',
    getDonationsTwo: '/api/donation/get-donation-list',
    createDonation: '/api/donation/create-donation',
    removeDonation: '/api/donation/remove-donation',
    editDonation: '/api/donation/edit-donation',
    downloadDonationData: '/api/donation/export-donation-data',
    getDonationBudgetHistory: '/api/donation/get-donation-budget-history',
    downloadProgrammeData: '/api/interactions/export-application-data',
    monitorDashboardRequireData: 'api/interactions/operation-dashboard',
    editContent: '/api/interactions/edit-content',
    getIndicatorAnswers: '/api/opportunity/get-indicator-answers',
    getEditIndicatorAnswers: '/api/opportunity/get-indicator-answer',
    confirmShareVerificationLink: '/api/interactions/confirm-share-verification-link',
    editIndicatorAnswer: '/api/opportunity/edit-indicator-answer',
    getImpactStrategyList: '/api/interactions/get-impactstrategy-list',
    getImpactStrategyListProgram: '/api/program/get-impactstrategy-list',
    getAllImpactStrategyList: '/api/program/get-impactstrategy-list',
    contentHomePage: '/api/interactions/get-content-list',
    createImpactStrategy: '/api/program/create-impact-strategy',
    getceAssets: "/api/ceasset/getceAssets",
    getAssetAnalytics: "/api/ceasset/getAssetAnalytics",
    getSubmissions: "/api/ceasset/getSubmissions",
    addCeSubmissionReview: "/api/ceasset/addCeSubmissionReview",
    getCeSubmissionReview: "/api/ceasset/getCeSubmissionReview",
    deleteURL: '/api/delete-s3-file'
  }
};

const buildenv = process.env.REACT_APP_BUILD_ENV || "dev";
const { host, media, platformURL } = newConfig[buildenv];

for (const [key, path] of Object.entries(newConfig.path)) {
  const url = `${host}${path}`;
  config.dev.url[key] = url;
  config.dev.url.imageURL = media;
  config.dev.url.baseURL = platformURL;
}

export default config;
