import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useIntl } from 'react-intl';
import clsx from "clsx";
import {
  Layout,
  Table,
  Button as AntButton,
  message,
  Upload,
  Spin,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import Modal from "../../components/Modal"
import axios from '../../utils/axios';
import Config from '../../config';
import crossCircle from '../../assets/crossCircle.svg';
import {
  donationListSelector,
  toggleSpinnerSelector
} from "../../redux/selectors";
import {
  useAppDispatch,
  useAppSelector
} from "../../redux/store";
import {
  setDonationListData as setDonationList,
  toggleSpinner
} from '../../redux/actions';
import StyledText from '../../components/StyledText';

message.config({
  top: 100,
  duration: 6,
  maxCount: 3,
});

const uploaderProps = {
  name: 'file',
  method: 'POST',
  disabled: false,
  showUploadList: {
    showPreviewIcon: true,
    showRemoveIcon: true,
    showDownloadIcon: false,
  },
  defaultUploadFileList: [],
  listType: 'text',
};

const getColumnData = (list) => {
  if (list?.length > 0) {
    return list?.map((item, idx) => {
      const {
        programName,
        opportunityName,
        donationAmount,
        invoiceFile,
        type,
        comment,
        dueDate,
        reportedDate,
        status,
        charityNextAction,
        currencySymbol
      } = item || {};
      const amount = donationAmount ? `${currencySymbol} ${donationAmount}` : ''
      return {
        key: idx,
        programName,
        opportunityName,
        donationAmount: type === 'Volunteering' && donationAmount ? `${donationAmount} days` : amount,
        invoiceFile,
        type,
        comment,
        dueDate: dueDate?.trim()
          ? moment(dueDate?.trim()).format('YYYY-MM-DD')
          : '',
        reportedDate: reportedDate?.trim()
          ? moment(reportedDate?.trim()).format('YYYY-MM-DD')
          : '',
        status,
        charityNextAction,
        edit: { ...item },
        currencySymbol
      };
    });
  }
  return null;
};

const CharityDonationHomePage = ({
  className,
}) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [fileDeletionReload, setFileDeletionReload] = useState(false);
  const [programData, setProgramData] = useState({});
  const [invoiceFileList, setInvoiceFileList] = useState('');
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const showSpinner = useAppSelector(toggleSpinnerSelector);
  const progListData = useAppSelector(donationListSelector);

  const { edit: { ID = '', invoiceFile = [] } = {} } = programData || {};

  const handleUploadInvoice = (text, report) => {
    if (text === 'Upload invoice') {
      setInvoiceFileList('');
      setUploadModal(true);
    } else {
      setReceiptModal(true);
    }
    setProgramData(report);
  };

  const getSecuredUrl = async (fileKey, name) => new Promise((resolve, reject) => {
    const url = `${Config.dev.url.imageURL}${fileKey}`;
    axios.getImage(
      url,
      (res, obj, err) => {
        if (res) {
          resolve(res);
        } else {
          reject(err);
        }
      },
      null,
      name
    );
  });

  const onDownloadFile = async (data) => {
    const { name, fileKey = '' } = data?.[0] || {};

    const getSecuredURL = await getSecuredUrl(fileKey, name);
    const securedURL = URL.createObjectURL(getSecuredURL);
    const fileLink = document.createElement('a');
    fileLink.href = securedURL;
    fileLink.download = name || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(securedURL);
        fileLink.removeEventListener('click', clickHandler);
      }, 150);
    };

    fileLink.addEventListener('click', clickHandler, false);
    fileLink.click();
    return fileLink;
  };

  const shortenString = (str = '') => {
    if (str?.length <= 15) {
      return str;
    }
    return `${str?.substring(0, 15)}...`;
  };

  const programColumns = [
    {
      title: intl.formatMessage({ id: 'opportunity' }),
      dataIndex: 'opportunityName',
      key: 'opportunityName',
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'programme' }),
      dataIndex: 'programName',
      key: 'programName',
    },
    {
      title: intl.formatMessage({ id: 'award_title' }),
      dataIndex: 'donationAmount',
      key: 'donationAmount',
    },
    {
      title: intl.formatMessage({ id: 'invoice_title' }),
      dataIndex: 'invoiceFile',
      key: 'invoiceFile',
      render: (text) => (text ? (
        <AntButton
          className="editButton"
          onClick={() => onDownloadFile(text)}
        >
          {shortenString(text?.[0]?.name)}
        </AntButton>
      ) : null),
    },
    {
      title: intl.formatMessage({ id: 'type_title' }),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: intl.formatMessage({ id: 'comment_title' }),
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: intl.formatMessage({ id: 'due_date' }),
      dataIndex: 'dueDate',
      key: 'dueDate',
    },
    {
      title: intl.formatMessage({ id: 'payment_date' }),
      dataIndex: 'reportedDate',
      key: 'reportedDate',
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: intl.formatMessage({ id: 'next_action_title' }),
      dataIndex: 'charityNextAction',
      key: 'charityNextAction',
      render: (text, report) => ((text === 'Upload invoice' || text === 'Confirm receipt')
        && report?.status !== 'Confirmed' ? (
        <AntButton
          onClick={() => { setFileDeletionReload(false); handleUploadInvoice(text, report); }}
          className="companyDonationButton"
        >
          {text}
        </AntButton>
      ) : (
        <span className="textAlert">{text}</span>
      )),
      fixed: 'right',
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, report) => (report.status === 'Invoice Received' && report.status !== 'Reported' ? (
        <AntButton
          className="editButton"
          onClick={() => { setFileDeletionReload(false); handleUploadInvoice('Upload invoice', report); }}
          type="primary"
        >
          {intl.formatMessage({ id: 'edit_title' })}
        </AntButton>
      ) : null),
    },
  ];

  const setData = () => {
    dispatch(toggleSpinner(true));
    const progUrl = Config.dev.url.getDonationsTwo;
    axios.put(progUrl, setProgramListData, true);
  };

  const setProgramDataFromApi = async (responseData) => {
    const list = responseData?.data?.data;
    if (list) {
      dispatch(setDonationList(list));
    }
  };

  const setProgramListData = (responseData) => {
    if (responseData) {
      setProgramDataFromApi(responseData);
      dispatch(toggleSpinner(false));
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  useEffect(() => {
    dispatch(toggleSpinner(true));
    setData();
  }, []);

  const deleteUploadedFiles = async () => {
    const editDonationUrl = Config.dev.url.editDonation;
    try {
      new Promise((resolve) => {
        axios.put(
          editDonationUrl,
          (res) => {
            if (
              res?.status === 200
              && res?.data?.message === 'S3 object deleted  successfully'
            ) {
              setFileDeletionReload(true);
              handleConfirmReceipt('Invoice Requested', '', true);
              setInvoiceFileList('');
              message.success('File Deleted');
              resolve(res.data);
            } else {
              message.error('File deletionFailed failed.');
            }
          },
          {
            bucketName: 'bg-uploaded-media',
            fileName: invoiceFileList?.[0]?.fileKey || invoiceFile?.[0]?.fileKey,
            action: 'deleteDonationFile',
          },
          true
        );
      });
    } catch (e) {
      message.error(`File deletionFailed failed. ${e.message || ''}`);
    }
  };

  const getPresignedPostData = async (selectedFile) => new Promise((resolve, reject) => {
    const fileName = selectedFile.name.replace(
      /(?:\.(?![^.]+$)|[^\w.])+/g,
      ''
    );
    const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
    axios.get(url, (res, err) => {
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject(err.response);
      }
    });
  });

  const uploadFileToS3 = async (presignedPostData, file, onProgress) => new Promise((resolve, reject) => {
    const formData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent <= 90) {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      },
    };
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);
    const {
      size = '', name = '', uid = '', type = ''
    } = file || {};
    const { fields: { key = '' } = {}, url = '' } = presignedPostData || {};
    const data = {
      fileKey: key, size, name, uid, fileLoaded: true, mediaDescription: '', type, url, thumbUrl: url
    };
    axios.uploadFile(
      presignedPostData.url,
      formData,
      (res, err) => {
        if (res && res.status === 204) {
          setInvoiceFileList([{ ...data }]);
          setTimeout(() => {
            handleConfirmReceipt('Invoice Received', [{ ...data }]);
          });
          setFileDeletionReload(false);
          resolve(res.data);
        } else {
          reject(err.response);
        }
      },
      config
    );
  });

  const processFileUpload = async ({
    onSuccess,
    onError,
    file,
    onProgress,
  }) => {
    try {
      let presignedPostData = await getPresignedPostData(file);
      presignedPostData = await presignedPostData.data;
      await uploadFileToS3(presignedPostData, file, onProgress);
      onSuccess(null, file);
    } catch (e) {
      message.error(`File upload failed. ${e.message || ''}`);
      onError(e);
    }
  };

  const postEditDonation = (response) => {
    if (response) {
      setTimeout(() => {
        setData();
      }, 1000);
      setReceiptModal(false);
      if (!uploadModal) {
        message.success(intl.formatMessage({ id: 'success_donation_received' }));
      }
    } else {
      dispatch(toggleSpinner(false));
    }
  };

  const handleConfirmReceipt = (status, fileName, deleteItem) => {
    dispatch(toggleSpinner(true));
    const editDonationUrl = Config.dev.url.editDonation;
    axios.put(
      editDonationUrl,
      postEditDonation,
      {
        donationID: ID,
        status: status || 'Confirmed',
        invoiceFile: deleteItem ? '' : fileName || invoiceFileList || invoiceFile || '',
        confirmedDate: !status ? moment().format('YYYY-MM-DD') : '',
      },
      true
    );
  };

  const baseClassName = clsx("endPage charityDonationTwo pageContainerReview steadyFormOuter", className);

  return (
    <Layout
      className={baseClassName}
    >
      {showSpinner && (
        <div className="overlay">
          <Spin className="spinner" size="large" spinning={showSpinner} />
        </div>
      )}
      <Layout className="coverImageContainer">
        <Table
          className="components-table-demo-nested"
          columns={programColumns}
          dataSource={getColumnData(progListData)}
          pagination={false}
        />
      </Layout>
      <Modal
        className="uploadModal"
        title={intl.formatMessage({ id: 'upload_invoice' })}
        visible={uploadModal}
        onOk={() => { setUploadModal(false); setInvoiceFileList(''); }}
        onCancel={() => { setUploadModal(false); setInvoiceFileList(''); }}
        okText={intl.formatMessage({ id: 'upload_title' })}
        okButtonProps={{ disabled: !invoiceFileList?.[0]?.name }}
        showFooter
      >
        {((invoiceFile?.[0]?.name || invoiceFileList?.[0]?.name) && !fileDeletionReload) ? (
          <div className='progHeader'>
            <span>{invoiceFileList?.[0]?.name || invoiceFile?.[0]?.name || null}</span>
            <button
              type="button"
              className="crossFileIcon"
              onClick={() => deleteUploadedFiles()}
            >
              <img src={crossCircle} alt="cross-circle" />
            </button>
          </div>
        ) : (
          <Upload
            customRequest={processFileUpload}
            className="companyFileUpload"
            {...uploaderProps}
            fileList={[]}
          >
            <AntButton variant="primary" icon={<UploadOutlined />} className='uploadButton'>
              {intl.formatMessage({ id: 'click_to_upload' })}
            </AntButton>
          </Upload>
        )}
      </Modal>
      <Modal
        className="uploadModal"
        title={intl.formatMessage({ id: 'confirm_receipt' })}
        visible={receiptModal}
        onOk={() => handleConfirmReceipt()}
        onCancel={() => setReceiptModal(false)}
        okText={intl.formatMessage({ id: 'received_title' })}
        showFooter
      >
        <StyledText as="span" variant="B3" className="status">
          {intl.formatMessage({ id: 'please_confirm_donation_received' })}
        </StyledText>
      </Modal>
    </Layout>
  );
};

const StyledCompanyDonationHomePage = styled(CharityDonationHomePage)`
&.charityDonationTwo{
  .textAlert{
  color: #ff1616;
}

.editButton {
  color: #5271FF !important;
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  font-weight: bold !important;
  border: none;
}

.crossFileIcon{
  border-radius: 50%;
  border: 0px;
  background: transparent;
  cursor: pointer;
}

.crossFileIcon img {
  height: 15px;
}

.companyDonationButton {
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
}

.companyDonationButton:hover{
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
}

.companyDonationButton:focus{
  background: #5271FF;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #5271FF;
}

.coverImageContainer {
  width: 100%;
  background-color: #f1eded !important;
  height: auto;
  min-height: 80px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.45);
}
.spinner {
  z-index: 2000;
  margin: auto;
  left: 50%;
  right: 50%;
  position: absolute;
  bottom: 50%;
}

@media only screen and (max-width: 1024px) {
  .steadyForm {
    width: 150%;
  }
  .steadyFormOuter {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 960px) {
  .steadyForm {
    width: 200%;
  }
}

@media only screen and (max-width: 767px) {
  .collapseSection {
    width: 500%;
    overflow: auto;
  }
  .steadyForm {
    width: 485%;
  }
}

@media only screen and (max-width: 280px) {
  .steadyForm {
    width: 1000%;
  }
}
}
`

export default StyledCompanyDonationHomePage;
