import React from "react";
import { Form, Input } from "antd";
import clsx from "clsx";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import StyledText from "../../components/StyledText";
import { getPassword } from '../../redux/actions';
import { setLocalStorageItem, passwordValidator } from './../../utils/utilities';
import {
  useAppDispatch,
} from "../../redux/store";
import Button from "../../components/Button";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ForgetPassword = ({ className }) => {
  const baseClassName = clsx("forgetPasswordPage", className);
  const intl = useIntl();
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const emailId = queryParams.get("email");
  const code = queryParams.get("confirmation_code");


  const onFinish = (values) => {
    const data = { email: emailId, resetCode: code, newPassword: values.password };
    dispatch(getPassword(data))
    navigate(`/login`);
    setLocalStorageItem('forgot_password', true);
  };

  return (
    <div className={baseClassName}>
      <div className="containerBox">
        <StyledText as="p" variant="H1" className="title">
          {intl.formatMessage({ id: "change_password" })}
        </StyledText>
      </div>
      <div className="changePassword">
        <Form
          {...formItemLayout}
          form={form}
          className="formContainer"
          name="register"
          onFinish={onFinish}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >
          <Form.Item
            name="password"
            validateTrigger="onBlur"
            className="formItem"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "please_enter_password" }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (passwordValidator(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(intl.formatMessage({ id: "please_enter_a_valid_password" }))
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password placeholder={intl.formatMessage({ id: 'password' })} />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={["password"]}
            className="formItem"
            hasFeedback
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "please_confirm_your_password" }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`${intl.formatMessage({ id: 'the_new_password_that_you_entered_do_not_match' })}`)
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder={intl.formatMessage({ id: 'confirm_password' })} />
          </Form.Item>
          <StyledText as="p" variant="B3" className="title">
            {intl.formatMessage({ id: 'password_must_include' })}
          </StyledText>
          <StyledText as="p" variant="B3" className="title">
            {intl.formatMessage({ id: 'more_than_eight_characters' })}
          </StyledText>
          <StyledText as="p" variant="B3" className="title">
            {intl.formatMessage({ id: 'an_upper_and_lower_case_character' })}
          </StyledText>
          <StyledText as="p" variant="B3" className="title">
            {intl.formatMessage({ id: 'a_number' })}
          </StyledText>
          <StyledText as="p" variant="B3" className="title">
            {intl.formatMessage({ id: 'a_special_character' })}
          </StyledText>
          <div className="submitButton">
            <Button variant="primary" htmlType="submit" className="passwordBtn">
              {intl.formatMessage({ id: "change_password" })}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const StyledForgetPassword = styled(ForgetPassword)`
  &.forgetPasswordPage {
    background: white;
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .containerBox {
      text-align: center;
      margin-top: 70px;
    }
    .changePassword {
      border-radius: 6px;
      display: flex;
      justify-content: center;
      padding: 40px 20px;
      margin: 0 auto;
      width: 450px;
      height: max-content;
      margin-top: 30px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
        .ant-form-item-control-input{
          width: 250px;
        }
        .submitButton{
          display: flex;
          justify-content: center;
          .passwordBtn {
           margin-top: 5%;
        }
        }
      .paraStyles {
        font-size: 11px;
        color: #00aa9d;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .formContainer{
        max-width: 600;
        justify-content: center;
        flex-direction: column;
        .formItem{
          width: 200px !important;
        }
      }
    }
  }
`;

export default StyledForgetPassword;
