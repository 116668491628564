import React from "react";
import clsx from "clsx";
import { Card, Avatar } from "antd";
import { useIntl } from "react-intl";
import styled from "styled-components";
import StyledText from "../../components/StyledText";
import { getReviewStatusColor } from "./Data";
import * as constants from "../../constants";
import locationIcon from "../../assets/location.svg"

const DataCard = ({ className, comment, location, selectedButton, feedbackType, onButtonClick, reviewStatus, selectedCard, onCardClick }) => {
    const baseClassName = clsx("cardContainer", className);
    const intl = useIntl();

    const submissionButtonClassName = clsx("mapButton", { "selectedMapButton": selectedButton?.comment === comment && selectedButton.mapType === 'submission' });
    const ideaButtonClassName = clsx("mapButton", { "selectedMapButton": selectedButton?.comment === comment && selectedButton.mapType === 'feedback' });

    const handleLocation = (event, type) => {
        event.stopPropagation();
        onButtonClick(type);
    }

    return (
        <Card className={`${baseClassName} ${selectedCard ? 'selectedCard' : ''}`} onClick={onCardClick}>
            <StyledText as="p" variant="B2a" className="subTitle">
                <span className="feedbackTitle">{`${intl.formatMessage({ id: "feedback" })}: `}</span>{`${comment}`}
            </StyledText>
            <div className="categoryContainer">
                <div className="categorySubContainer">{
                    feedbackType?.length > 0 && feedbackType?.map((feedback) => <StyledText key={feedback} as="p" variant="B3" className="category">
                        {feedback}
                    </StyledText>)
                }
                </div>
                {location?.length > 0 && <div className="locationContainer">
                    <button className={submissionButtonClassName} onClick={(event) => handleLocation(event, 'submission')}>
                        {intl.formatMessage({ id: "submitter" })}<Avatar src={locationIcon} className="userIcon" />
                    </button>
                    <button className={ideaButtonClassName} onClick={(event) => handleLocation(event, 'feedback')}>
                        {intl.formatMessage({ id: "feedback" })}<Avatar src={locationIcon} className="userIcon" />
                    </button>
                </div>}
            </div>
            <StyledText as="p" variant="H2" className={`status ${getReviewStatusColor(reviewStatus)}`}>
                {reviewStatus}
            </StyledText>
        </Card>
    );
};

const StyledDataCard = styled(DataCard)`
&.cardContainer {
    &.ant-card{
        position: relative;
        .status{
            position: absolute;
            top: 4px;
            right: 24px;
        }
        &.selectedCard{
            background: ${constants.PRIMARY_COLOR_2};
            border: 2px solid #33307b;
        }
        .textContainer{
            display: flex;
        }
        .subTitle {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            white-space: pre-wrap;
            .feedbackTitle{
                font-weight: bold;
            }
        }
        }
        .categoryContainer{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 20px;
            flex-wrap: wrap;
            .categorySubContainer{
                width: 70%;
                display: flex;
                gap: 5px 10px;
                flex-wrap: wrap;
            }
            .category{
                padding: 0 5px;
                background: ${constants.PRIMARY_COLOR_2};  
            }
        }
        .locationContainer{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 5px;
            .mapButton{
                border-radius: 4px;
                background: #FFFFFF;
                border: 1px solid #242424;
                padding-inline: 5px;
                width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 25px;
                cursor: pointer;
                &.selectedMapButton{
                background: #FFF7F5;
                border: 1px solid #ED6C2D;
                }
            }
            span {
                scale: 0.6;
                position: absolute;
                right: 24px;
            }
        }
    .reviewed{
        color: #6DBCB6!important;
    }
    .inProgress{
        color: #FDB228!important;
    }
    .defaultProgress {
        color: #FDB228!important;
    }
}
`;

export default StyledDataCard;
