import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { jwtDecode } from 'jwt-decode'
import { useLocation } from "react-router-dom";
import { Table, Space } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
  useAppDispatch,
  useAppSelector
} from "../../redux/store";
import {
  actionInteractionSelector,
  searchFieldValueSelector
} from "../../redux/selectors";
import {
  getActionInteraction
} from "../../redux/actions";
import { formatDate, getSecuredUrl, getLocalStorageItem } from "../../utils/utilities";
import Tag from "../../components/Tag";

const ApplicationAction = ({ className }) => {
  const intl = useIntl();
  const baseClassName = clsx("applicationAction", className);
  const searchFieldValue = useAppSelector(searchFieldValueSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [allInteractions, setAllInteractions] = useState([]);
  const dispatch = useAppDispatch();
  const actionInteraction = useAppSelector(actionInteractionSelector);
  const location = useLocation();
  const decodeToken = (getLocalStorageItem('id_Token') && jwtDecode(getLocalStorageItem('id_Token'))) || {};

  const savedProgramId = sessionStorage.getItem('savedProgramId');
  const savedRoundId = sessionStorage.getItem('savedRoundId');

  const getAllInteractions = async () => {
    const checkLocation = location.pathname === '/company/applications/actions'
    if (isLoading || !checkLocation) return;
    setIsLoading(true);
    try {
      const uniqueImages = new Set();
      const uniqueVotes = actionInteraction.filter(item => {
        if (!uniqueImages.has(item.opportunityCoverImage)) {
          uniqueImages.add(item.opportunityCoverImage);
          return true;
        }
        return false;
      });

      const fetchSecuredUrls = async () => {
        const updatedInteractions = await Promise.all(
          uniqueVotes.map(async (item) => {
            try {
              const securedURI = await getSecuredUrl(item.opportunityCoverImage);
              const securedURL = URL.createObjectURL(securedURI);
              return { ...item, opportunityCoverImage: securedURL };
            } catch (error) {
              console.error('Error fetching secured URL:', error);
              return item;
            }
          })
        );
        setAllInteractions(updatedInteractions);
      };

      await fetchSecuredUrls();
    } catch (error) {
      console.error('Error in getAllInteractions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkLocation = location.pathname === '/company/applications/actions'

  useEffect(() => {
    const verifyLocation = location.pathname === '/company/applications/actions'
    if (verifyLocation) {
      setTimeout(() => {
        dispatch(getActionInteraction({
          sortBy: "programName",
          asc: "true",
          filterBy: {
            status: [
              "Applied"
            ],
            ...(savedProgramId !== "All" && { programID: savedProgramId, companyID: decodeToken?.companyID, programmeRound: savedRoundId || undefined })
          },
          limit: "100"
        }));
      })
    }
  }, [checkLocation]);

  useEffect(() => {
    if (actionInteraction?.length) {
      getAllInteractions()
    } else if (Array.isArray(actionInteraction) && !actionInteraction?.length) {
      setAllInteractions(null);
    }
  }, [actionInteraction]);

  useEffect(() => {
    if (searchFieldValue) {
      const filterTable = allInteractions?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(searchFieldValue.toLowerCase())));
      const val = filterTable?.length ? filterTable : null;
      setAllInteractions(val);
    } else {
      getAllInteractions();
    }
  }, [searchFieldValue]);

  const handleSort = (sortId) => {
    if (['programName', 'charityName', 'opportunityName', 'updateDate'].includes(sortId.key)) {
      dispatch(getActionInteraction({
        sortBy: sortId?.key,
        asc: "true",
        filterBy: {
          status: [
            "Applied"
          ],
          ...(savedProgramId !== "All" && { programID: savedProgramId, companyID: decodeToken?.companyID, programmeRound: savedRoundId || undefined })
        }
      }));
    }
  }

  const getTableData = (list) => {
    if (list?.length > 0) {
      return list?.map((item, idx) => {
        const {
          programName,
          charityName,
          opportunityName,
          updateDate,
          status,
          userID,
          opportunityCoverImage
        } = item || {};
        return {
          key: idx,
          programName,
          charityName,
          opportunityName,
          updateDate: formatDate(updateDate),
          status,
          userID,
          opportunityCoverImage
        };
      });
    }
    return null;
  }

  const columns = [
    {
      title: intl.formatMessage({ id: "programme" }),
      dataIndex: 'programName',
      key: 'programName',
      render: (_, { programName, opportunityCoverImage }) => (
        <div className="nameContainer">
          <img src={opportunityCoverImage} alt="cover" />
          <div className="blockWithText">
            {programName}
          </div>
        </div>
      ),
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
    },
    {
      title: intl.formatMessage({ id: "entity" }),
      dataIndex: 'charityName',
      key: 'charityName',
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
    },
    {
      title: intl.formatMessage({ id: "opportunity" }),
      dataIndex: 'opportunityName',
      key: 'opportunityName',
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
    },
    {
      title: intl.formatMessage({ id: "date_applied" }),
      dataIndex: 'updateDate',
      key: 'updateDate',
      onHeaderCell: (column) => {
        return {
          onClick: () => handleSort(column),
        };
      },
      sorter: () => { },
    },
    {
      title: intl.formatMessage({ id: "status" }),
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => {
        return (<Tag key={status} type='regular'>
          {status?.toUpperCase()}
        </Tag>)
      }
      ,
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      key: 'actions',
      dataIndex: 'status',
      render: (_, report) => (
        <Space size="middle">
          <button className='button' onClick={() => {
          }}>{intl.formatMessage({ id: "review" })}</button>
        </Space>
      ),
    },
  ];

  const isValid = Array.isArray(allInteractions) && allInteractions?.length === 0

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Applications Page</title>
      </HelmetAsync>
      <div className='subContentContainer'>
        <Table columns={columns} loading={isValid} dataSource={getTableData(allInteractions)} />
      </div>
    </div>
  );
};

const StyledApplicationAction = styled(ApplicationAction)`
  &.applicationAction {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .nameContainer{
      display: flex;
      gap: 10px;
    }
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
    .button{
        border: 0;
        background: transparent;
        color: #5550F7;
        cursor: pointer;
        min-width: 100px;
    }
  }
`;

export default StyledApplicationAction;
