import React, { useEffect } from "react";
import { Col, Card, Spin } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Line } from '@ant-design/plots';
import clsx from "clsx";
import * as constants from "../../constants";

import {
  tokenSelector,
  totalVisitsSelector,
  ideaProgressAnalyticsSelector,
  feedbackProgressAnalyticsSelector,
  collaborateProgressAnalyticsSelector,
  ideasPendingActionsSelector,
  feedbackPendingActionsSelector,
  collaboratePendingActionsSelector,
  historicalVisitorsSelector
} from "../../redux/selectors";
import {
  useAppDispatch,
  useAppSelector
} from "../../redux/store";
import {
  getAllAssets,
  getSubmissionTotalAnalytics,
  getIdeaProgressAnalytics,
  getFeedbackProgressAnalytics,
  getCollaborateProgressAnalytics,
  getIdeasPendingActions,
  getFeedbackPendingActions,
  getCollaboratePendingActions,
  getHistoricalVisitors
} from "../../redux/actions";
import StyledText from "../../components/StyledText";

const sortByTypeDescending = (data) => {
  if (data?.length && Array.isArray(data)) {
    data.sort((a, b) => {
      const numA = parseInt(a.type.replace(/\D/g, ""));
      const numB = parseInt(b.type.replace(/\D/g, ""));
      return numB - numA;
    });
  }
  return data;
}

const Insights = ({ className }) => {
  const intl = useIntl();
  const baseClassName = clsx("insights", className);
  const assetId = sessionStorage.getItem('assetID');
  const phase = sessionStorage.getItem('phaseId');
  const submissionTypeSaved = sessionStorage.getItem('submissionType');
  const dispatch = useAppDispatch();
  const token = useAppSelector(tokenSelector) || localStorage.getItem("id_Token");
  const totalVisits = useAppSelector(totalVisitsSelector)?.totalVisits;
  const ideaProgressAnalytics = useAppSelector(ideaProgressAnalyticsSelector)?.All;
  const feedbackProgressAnalytics = useAppSelector(feedbackProgressAnalyticsSelector)?.All;
  const collaborateProgressAnalytics = useAppSelector(collaborateProgressAnalyticsSelector)?.All;
  const ideasPendingActions = useAppSelector(ideasPendingActionsSelector)?.idea;
  const feedbackPendingActions = useAppSelector(feedbackPendingActionsSelector)?.feedback;
  const collaboratePendingActions = useAppSelector(collaboratePendingActionsSelector)?.collaborate;
  const historicalVisitors = useAppSelector(historicalVisitorsSelector);

  const historyChartData = sortByTypeDescending(formatAnalyticsData(historicalVisitors));

  const progressAnalytics = {
    totalVisits,
    ideaProgressAnalytics,
    feedbackProgressAnalytics,
    collaborateProgressAnalytics
  }

  const pendingActions = {
    ideasPendingActions,
    feedbackPendingActions,
    collaboratePendingActions
  }

  const HISTORY_VISITORS_CHART_CONFIG = {
    data: historyChartData,
    xField: 'type',
    yField: 'value',
    point: {
      shapeField: 'square',
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
    width: 370,
  };

  useEffect(() => {
    if (token) {
      dispatch(getAllAssets());
    }
  }, [token]);

  useEffect(() => {
    const data = {
      dataType: "visitors",
      assetID: assetId,
      ...(phase !== 'All' && { phase })
    };
    if (assetId) {
      dispatch(getSubmissionTotalAnalytics({ ...data, assetID: assetId, resultType: "totalVisits" }));
      dispatch(getIdeaProgressAnalytics({ ...data, resultType: "reviewStatusByType", dataType: "submissions", type: "idea" }));
      dispatch(getFeedbackProgressAnalytics({ ...data, resultType: "reviewStatusByType", dataType: "submissions", type: "feedback" }));
      dispatch(getCollaborateProgressAnalytics({ ...data, resultType: "reviewStatusByType", dataType: "submissions", type: "collaborate" }));
      dispatch(getIdeasPendingActions({ ...data, resultType: "numberSubmissions", dataType: "submissions", type: "idea" }));
      dispatch(getFeedbackPendingActions({ ...data, resultType: "numberSubmissions", dataType: "submissions", type: "feedback" }));
      dispatch(getCollaboratePendingActions({ ...data, resultType: "numberSubmissions", dataType: "submissions", type: "collaborate" }));
      dispatch(getHistoricalVisitors({ ...data, assetID: assetId, resultType: "visitsByMonth" }))
    }
  }, [phase, assetId, submissionTypeSaved])

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Insights Page</title>
      </HelmetAsync>
      {(assetId && !(progressAnalytics && Object.keys(progressAnalytics)?.length > 0)) ? (
        <div className="spinner">
          <Spin size="large" />
        </div>
      ) : <StyledEqualHeightCards>
        {Object.keys(progressAnalytics)?.length > 0 && <Col Col span={8}>
          <Card>
            <StyledText as="p" variant="H2" className="title">
              {intl.formatMessage({ id: "progress" })}
            </StyledText>
            <div className="contentWrapper">
              {renderContentContainer(intl, progressAnalytics)}
            </div>
          </Card>
        </Col>}
        {Object.keys(progressAnalytics)?.length > 0 && <Col Col span={8}>
          <Card>
            <StyledText as="p" variant="H2" className="title">
              {intl.formatMessage({ id: "pending_actions" })}
            </StyledText>
            <div className="contentWrapper">
              {renderPendingContentContainer(intl, pendingActions)}
            </div>
          </Card>
        </Col>}
        {historyChartData?.length > 0 && <Col span={8}>
          <Card>
            <StyledText as="p" variant="H2" className="title">
              {intl.formatMessage({ id: "three_month_historical_visitors" })}
            </StyledText>
            <div className="contentWrapper">
              <Line {...HISTORY_VISITORS_CHART_CONFIG} />
            </div>
          </Card>
        </Col>}
      </StyledEqualHeightCards>}
    </div >
  );
};

const renderContentContainer = (intl, analytics) => {
  return (<>
    {renderContent(intl, "total_visitors", analytics?.totalVisits, "#4D88C9")}
    {renderContent(intl, "feedback_submitted", analytics?.feedbackProgressAnalytics, "#4D88C9")}
    {renderContent(intl, "ideas_submitted", analytics?.ideaProgressAnalytics, "#4D88C9")}
    {renderContent(intl, "collaborations_submitted", analytics?.collaborateProgressAnalytics, "#4D88C9")}
  </>)
};

const renderPendingContentContainer = (intl, analytics) => {
  return (<>
    {renderContent(intl, "feedback", analytics?.feedbackPendingActions, "#ED6C2D")}
    {renderContent(intl, "ideas", analytics?.ideasPendingActions, "#ED6C2D")}
    {renderContent(intl, "collaborations", analytics?.collaboratePendingActions, "#ED6C2D")}
  </>)
};

const renderContent = (intl, labelId, value, backgroundColor) => (value > 0 && <div className="contentContainer">
  <StyledText as="p" variant="H3">
    {intl.formatMessage({ id: labelId })}
  </StyledText>
  <StyledText as="p" variant="H3" className="progressIdeaValue" style={{ background: backgroundColor }}>
    {value}
  </StyledText>
</div>);

const formatAnalyticsData = (data) => (
  data && Object.entries(data)?.map(([type, value]) => ({
    type,
    value
  }))
);

const StyledEqualHeightCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  > .ant-col {
    flex-grow: 1;
    margin-bottom: 16px;
    min-width: 0;
  }
`;

const StyledInsights = styled(Insights)`
  &.insights {
    background: ${(props) => props.theme.backgroundCanvas};
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 15px;
    }
    .contentWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      gap: 10px;
      .contentContainer{
        display: flex;
        width: 100%;
        justify-content: space-between;

        .progressIdeaValue, .progressReviewedValue, .progressInProgessValue, .progressPendingReviewValue {
          color: ${constants.COLOR_WHITE};
          padding: 5px;
          width: 80px;
          text-align: center;
        }
        .progressIdeaValue{
          background: #4D88C9;
        }
        .progressReviewedValue{
          background: #6DBCB6;
        }
        .progressInProgessValue{
          background: #FDB228;
        }
        .progressPendingReviewValue{
          background: #EF5644;
        }
      }
    }
    .ant-card{
      min-height: max-content; 
    }
    .insightsGraphContainer{
      margin: 30px 0;
    }
  }
`;

export default StyledInsights;
