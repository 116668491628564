import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Divider, Form, Input, Steps } from "antd";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import { editProfile, createProfile, getProfileDataCompany } from "../../../redux/actions";
import { profileSelector } from "../../../redux/selectors";
import GooglePlaceApiInput from "../../../components/GoogleInput";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const CreateProfileEntity = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const [registeredAddress, setRegisteredAddress] = useState();
    const [saveAndExit, setSaveAndExit] = useState(false);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(profileSelector)
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const { company: {
        legalName,
        tradingName,
        registeredAddress: savedRegisteredAddress,
        description,
        ID,
        socialMedia: {
            linkedInURL,
            facebookURL,
            instagramURL,
            xURL,
            twitterURL
        } = {},
        webURI,
        logoS3URL
    } = {}
    } = profile || {};

    useEffect(() => {
        dispatch(getProfileDataCompany())
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            legalName,
            tradingName,
            description
        });
        setRegisteredAddress(savedRegisteredAddress)
    }, [ID])


    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        const data = {
            legalName: formData?.legalName,
            tradingName: formData?.tradingName,
            registeredAddress,
            description: formData?.description,

        }
        if (!ID) {
            dispatch(createProfile(data))
        } else {
            dispatch(editProfile({
                ...data,
                ID,
                socialMedia: {
                    linkedInURL,
                    facebookURL,
                    instagramURL,
                    xURL: twitterURL || xURL,
                },
                webURI,
                logoS3URL,
            }));
        }
        dispatch(editProfile(data));
        setTimeout(() => {
            if (saveAndExit) {
                setSaveAndExit(false);
                navigate("/company/my-profile/edit");
            } else {
                navigate("/company/profile/create/media");
            }
        }, 500)
    }

    const handleSaveAndExit = () => {
        setSaveAndExit(true);
        form.submit();
    }

    const handlePrevious = () => {
        navigate("/company/profile/create/start")
    }

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const onSelectOfAddress = (address) => {
        setRegisteredAddress(address?.formatted_address)
    }

    return (
        <div className={baseClassName}>
            <Steps
                current={0}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            <Form
                {...formItemLayout}
                labelAlign="left"
                onFinish={handleProceed}
                onFinishFailed={onFinishFailed}
                form={form}
                className="formContainer"
                scrollToFirstError
            >
                <div className="mainContentContainer">
                    <div className="contentContainer">
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "entity" })}
                        </StyledText>
                        <StyledText as="p" variant="H2">
                            {intl.formatMessage({ id: "name_and_location" })}
                        </StyledText>

                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_is_the_legal_Name_of_your_entity" })}
                        </StyledText>
                        <Form.Item
                            name="legalName"
                            className="formItem"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "enter",
                                })}
                            />
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_entity_name_would_like_to_be_displayed" })}
                        </StyledText>
                        <Form.Item
                            name="tradingName"
                            className="formItem"
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage({
                                    id: "enter",
                                })}
                            />
                        </Form.Item>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "what_is_your_registered_address" })}
                        </StyledText>
                        <GooglePlaceApiInput
                            className="formItem googlePlaceInputItem"
                            placeholder={intl.formatMessage({
                                id: "enter",
                            })}
                            defaultValue={registeredAddress?.addressLine1 || registeredAddress}
                            onSelectOfAddress={onSelectOfAddress}
                        />
                        <StyledText as="p" variant="H2" className='descriptionText'>
                            {intl.formatMessage({ id: "description" })}
                        </StyledText>
                        <StyledText as="p" variant="B3d">
                            {intl.formatMessage({ id: "add_your_description" })}
                        </StyledText>
                        <Form.Item
                            name="description"
                            className=""
                            rules={[
                                {
                                    whitespace: true,
                                    required: true,
                                    message: intl.formatMessage({ id: "enter" }),
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder={intl.formatMessage({
                                    id: "enter",
                                })}
                            />
                        </Form.Item>
                    </div>
                    <div className='buttonContainer'>
                        <Divider />
                        <div className='buttonContentContainer'>
                            <div className='nextAndPreviousContainer'>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => handlePrevious()}>
                                    {intl.formatMessage({ id: "previous" })}
                                </Button>
                                <Button
                                    variant="primary"
                                    htmlType="submit"
                                >
                                    {intl.formatMessage({ id: "next" })}
                                </Button>
                            </div>
                            <div className="saveAndExitButton">
                                <Button
                                    variant="secondary"
                                    htmlType="button"
                                    onClick={() => handleSaveAndExit()}>
                                    {intl.formatMessage({ id: "save_n_exit" })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

const StyledCreateProfileEntity = styled(CreateProfileEntity)`
&.createProfile{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    .formContainer {
    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 83vh;
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 8px;
            .formItem {
                width: 450px;
                margin-bottom: 5px !important;
            }
            .googlePlaceInputItem {
                width: 265px;
            }
            .descriptionText{
                margin-top: 24px;
            }
        }

    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
            }
        }
    }
    }
}`;

export default StyledCreateProfileEntity;