import { Button as AntButton } from "antd";
import { theme } from "../theme/ThemeConfig";
import styled from "styled-components";

const StyledButton = styled(AntButton)`
  & {
    font-size: ${(props) =>
    `${theme?.buttons[props?.variant]?.default?.fontSize}px`} !important;
    font-family: ${(props) =>
    `${theme?.buttons[props?.variant]?.default?.fontFamily}`};
    cursor: pointer !important;
    color: ${(props) =>
    theme?.buttons[props?.variant]?.default?.color} !important;
    background-color: ${(props) =>
    theme?.buttons[props?.variant]?.default?.background} !important;
    border: ${(props) => `${theme?.buttons[props?.variant]?.default?.borderWidth} solid ${theme?.buttons[props?.variant]?.default?.borderColor} !important`};
    border-radius: ${(props) =>
    theme?.buttons[props?.variant]?.default?.borderRadius} !important;
    text-align: center !important;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s !important;
    &:hover {
      background-color: ${(props) =>
    theme?.buttons[props?.variant]?.hover?.background} !important;
      color: ${(props) =>
    theme?.buttons[props?.variant]?.hover?.color} !important;
      border-color: ${(props) =>
    theme?.buttons[props?.variant]?.hover?.borderColor} !important;
    }
    &:disabled {
      background-color: ${(props) =>
    theme?.buttons[props?.variant]?.disabled?.background} !important;
      color: ${(props) =>
    theme?.buttons[props?.variant]?.disabled?.color} !important;
      border-color: ${(props) =>
    theme?.buttons[props?.variant]?.disabled?.borderColor} !important;
      cursor: not-allowed !important;
    }
  }
`;
export default StyledButton;
