import React, { useEffect, useState } from "react";
import clsx from "clsx";
import dayjs from 'dayjs';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'
import { Tabs, Row, Col, Input, Form, DatePicker, Space } from "antd";
import { Helmet as HelmetAsync } from "react-helmet-async";
import styled from "styled-components";
import { useIntl } from "react-intl";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/store";
import {
  interactionStatusSelector,
  sharePreApprovedSelector,
  allInteractionsSelector,
} from "../../redux/selectors";
import Config from '../../config';
import axios from '../../utils/axios';
import {
  getInteractionStatus,
  downloadProgrammeData,
  setSearchInputFieldValue,
  setActionInteraction,
  getSharePreApproved,
  getAllInteraction,
  getActionInteraction
} from "../../redux/actions";
import ApplicationAction from "./ApplicationAction";
import PendingAction from "./PendingAction";
import PreApprovedAction from "./PreApprovedAction";
import ApprovedAction from "./ApprovedAction";
import DeclinedAction from "./DeclinedAction";
import StyledText from "../../components/StyledText";
import CustomSelect from "../../components/CustomSelect";
import { getLocalStorageItem, removeDuplicatesByKey, convertDate } from "../../utils/utilities";
import CustomModal from "../../components/Modal";
import Button from "../../components/Button";
const dateFormat = 'YYYY/MM/DD';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const DashboardPage = ({ className }) => {
  const intl = useIntl();
  const baseClassName = clsx("dashboardActionPage", className);
  const [savedProgramId, setSavedProgramId] = useState('');
  const [roundOptions, setRoundOptions] = useState([]);
  const [roundId, setRoundId] = useState('');
  const [form] = Form.useForm();
  const [dropDownForm] = Form.useForm();
  const [disable, setDisable] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [votingModalOpen, setVotingModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const sharePreApproved = useAppSelector(sharePreApprovedSelector);
  const allInteractions = useAppSelector(allInteractionsSelector);
  const interactionStatus = useAppSelector(interactionStatusSelector);
  const decodeToken = (getLocalStorageItem('id_Token') && jwtDecode(getLocalStorageItem('id_Token'))) || {};
  const uniqueStatuses = [...new Set(interactionStatus?.map(item => item?.status).filter(Boolean))].map((el) => ({
    key: el,
    label: el,
    value: el
  }));
  const programNames = removeDuplicatesByKey(allInteractions?.map(({ programID, programName }) => ({
    key: programID,
    label: programName,
    value: programID
  })), 'label');

  const { isPresent, invitedVoters, expiryDate } = sharePreApproved || {};

  useEffect(() => {
    if (isPresent) {
      form.setFieldsValue({
        invitedVoters: invitedVoters.map(voter => ({ email: voter })),
        expiryDate: dayjs(convertDate(expiryDate))
      });
    }
  }, [isPresent])

  useEffect(() => {
    dispatch(setActionInteraction(null));
    if (!allInteractions?.length) {
      dispatch(getAllInteraction({
        sortBy: "programName",
        asc: "true",
        filterBy: {
          status: [
            "Applied",
            "Approved",
            "InEdit",
            "PreApproved",
            "Completed",
            "Declined"
          ]
        }
      }));
    }
    return () => { dispatch(setSearchInputFieldValue('')) }
  }, [location]);

  const setDrawerRoundOptionsResponse = (responseData) => {
    if (responseData) {
      setRoundOptions(responseData?.data?.uniqueProgrammeRound);
    }
  };

  const setRoundData = (id) => {
    const getInteraction = Config.dev.url.getInteraction;
    axios.put(getInteraction, setDrawerRoundOptionsResponse, {
      sortBy: "programName",
      asc: "true",
      action: "uniqueProgrammeRound",
      filterBy: {
        programID: id
      }
    }, true);
  };

  const onProgrammeChange = (id) => {
    if (id !== "All") {
      setSavedProgramId(id);
    }
    dropDownForm.setFieldsValue({
      roundId: undefined,
    })
    setRoundOptions([]);
    sessionStorage.removeItem('savedRoundId');
    sessionStorage.setItem('savedProgramId', id);
    setRoundData(id);
    const locationPathName = location.pathname;
    const commonData = {
      sortBy: "programName",
      asc: "true",
      limit: "100",
      filterBy: {
        ...(id !== "All" && { programID: id, companyID: decodeToken?.companyID })
      }
    };

    const statusMap = {
      '/company/applications/actions': ["Applied"],
      '/company/applications/approved': ["Approved", "Completed"],
      '/company/applications/declined': ["Declined"],
      '/company/applications/pending': ["InEdit"],
      'default': ["PreApproved"]
    };

    const data = {
      ...commonData,
      filterBy: {
        ...commonData.filterBy,
        status: statusMap[locationPathName] || statusMap['default']
      },
      ...(locationPathName === '/' && { action: "voting" })
    };

    dispatch(getActionInteraction(data));
  };


  const onRoundDropdownChange = (id) => {
    if (id !== "All") {
      sessionStorage.setItem('savedRoundId', id);
    }
    setRoundId(id);
    const locationPathName = location.pathname;
    const commonData = {
      sortBy: "programName",
      asc: "true",
      limit: "100",
      filterBy: {
        ...(id !== "All" && { programID: savedProgramId, companyID: decodeToken?.companyID, programmeRound: id })
      }
    };

    const statusMap = {
      '/company/applications/actions': ["Applied"],
      '/company/applications/approved': ["Approved", "Completed"],
      '/company/applications/declined': ["Declined"],
      '/company/applications/pending': ["InEdit"],
      'default': ["PreApproved"]
    };

    const data = {
      ...commonData,
      filterBy: {
        ...commonData.filterBy,
        status: statusMap[locationPathName] || statusMap['default']
      },
      ...(locationPathName === '/' && { action: "voting" })
    };

    dispatch(getActionInteraction(data));
  };

  const onShareProgrammeDropdownChange = () => {
    const { getFieldValue } = form;
    const values = getFieldValue();
    dispatch(getSharePreApproved({
      programID: values?.downloadProgramme,
      type: "CHECK"
    }));
  }

  const handleShareButtonClick = () => {
    const { getFieldValue } = form;
    const values = getFieldValue();
    dispatch(getSharePreApproved({
      type: "Edit",
      expiryDate: values.expiryDate.format(dateFormat),
      programID: values?.downloadProgramme,
      invitedVoters: values?.invitedVoters?.map(voter => voter?.email)
    }));
    setVotingModalOpen(false);
    setDisable(false)
  }

  const onFinish = (values) => {
    const data = {
      programID: values?.downloadProgramme,
      status: values?.downloadApplicationStatus === 'all' ? 'Applied' : values?.downloadApplicationStatus
    }
    dispatch(downloadProgrammeData(data))
    setDownloadModalOpen(false);
  };

  const items = [
    {
      key: "actions",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "actions_required" })}
        </StyledText>
      ),
      children: <ApplicationAction />,
    },
    {
      key: "pending",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "pending_response" })}
        </StyledText>
      ),
      children: <PendingAction />,
    },
    {
      key: "pre-approved",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "pre_approved" })}
        </StyledText>
      ),
      children: <PreApprovedAction />,
    },
    {
      key: "approved",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "approved" })}
        </StyledText>
      ),
      children: <ApprovedAction />,
    },
    {
      key: "declined",
      label: (
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "declined" })}
        </StyledText>
      ),
      children: <DeclinedAction />,
    },
  ];

  const handleChangeRoute = (key) => {
    navigate(`/company/applications/${key}`);
  };

  const onSearch = ({ target }) => {
    const { value } = target
    dispatch(setSearchInputFieldValue(value));
  }

  const onDownloadProgrammeChange = (programID) => {
    const status = programID === "All" ? ["Applied"] : [
      "Applied",
      "Approved",
      "PreApproved",
      "Completed",
      "Declined"
    ]
    dispatch(getInteractionStatus({
      sortBy: "programName",
      asc: "true",
      filterBy: {
        status,
        ...(programID !== "All" && { programID, companyID: decodeToken?.companyID }),
      },
    }))
  }

  const activeKey = window?.location?.pathname.split('/').pop();
  const isPreApproved = activeKey === 'pre-approved';

  const onFormChange = (_, allValues) => {
    if ('invitedVoters' in _) {
      setDisable(false)
    }
  };

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Applications Page</title>
      </HelmetAsync>
      <StyledText as="p" variant="H1" className="title">
        {intl.formatMessage({ id: "applications" })}
      </StyledText>
      <Row className="filters">
        <Form
          {...formItemLayout}
          form={dropDownForm}
          className="subFilterSection"
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          onValuesChange={onFormChange}
        >
          <Col span={5}>
            <Form.Item
              name="programmeId"
            >
              <CustomSelect
                type="formSelect"
                onChange={onProgrammeChange}
                options={[
                  { key: "all", label: "All", value: "All" },
                  ...(programNames ?? []),
                ]}
                placeholder={intl.formatMessage({ id: "all_programmes" })}
              />
            </Form.Item>
          </Col>
          <Col span={5} className="roundSelect">
            <Form.Item
              name="roundId"
            >
              <CustomSelect
                type="formSelect"
                onChange={onRoundDropdownChange}
                disabled={!roundOptions?.length}
                options={[
                  { key: "all", label: "All", value: "All" },
                  ...(roundOptions || []).map((value, index) => ({
                    key: index.toString(),
                    label: value,
                    value: value
                  }))
                ]}
                placeholder={intl.formatMessage({ id: "round" })}
              />
            </Form.Item>
          </Col>
        </Form>
        <div className="sortOptions">
          {isPreApproved && <Button
            variant="primary"
            className="reset"
            onClick={() => { setVotingModalOpen(true); setDisable(true); }}
          >
            {intl.formatMessage({ id: 'voting' })}
          </Button>}
          <Button
            variant="primary"
            className="reset"
            onClick={() => setDownloadModalOpen(true)}
          >
            {intl.formatMessage({ id: 'download' })}
          </Button>
          <Input.Search
            allowClear
            placeholder={intl.formatMessage({ id: 'search_placeholder' })}
            className="searchTableCom"
            onChange={onSearch}
          />
        </div>
      </Row>
      <CustomModal
        title={intl.formatMessage({ id: 'share_and_manage_voting' })}
        open={votingModalOpen}
        onOk={() => { setVotingModalOpen(false); setDisable(false); }}
        onCancel={() => {
          setVotingModalOpen(false);
          setDisable(false);
          form.setFieldsValue({
            downloadProgramme: undefined,
            programmeDownload: undefined,
            invitedVoters: undefined,
            expiryDate: undefined,
          });
        }}
        closable={false}
        footer={null}>
        <StyledText as="p" variant="B3" className="title">
          {intl.formatMessage({ id: "invited_users_will_receive_an_email_with_access_details" })}
        </StyledText>
        <Form
          {...formItemLayout}
          form={form}
          className="formContainer"
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          onValuesChange={onFormChange}
        >
          <Form.Item
            name="downloadProgramme"
          >
            <CustomSelect
              type="formSelect"
              name="programmeDownload"
              placeholder={intl.formatMessage({ id: "select_programme" })}
              onChange={(e) => onShareProgrammeDropdownChange()}
              options={[
                ...(programNames ?? []),
              ]}
            />
          </Form.Item>
          {[true, false].includes(isPresent) && <>
            <StyledText as="p" variant="B3d" className="title">
              {intl.formatMessage({ id: "current_share_access_closing_date" })}
            </StyledText>
            <Form.Item name="expiryDate">
              <DatePicker
                onChange={() => setDisable(false)}
              />
            </Form.Item>
            <StyledText as="p" variant="B3d" className="title">
              {intl.formatMessage({ id: "invited_voters" })}
            </StyledText>
            <Form.List name="invitedVoters">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        className="emailAddressField"
                        name={[name, 'email']}
                        rules={[{ required: true, message: 'Missing first name' }]}
                      >
                        <Input placeholder={intl.formatMessage({ id: "add_email_address" })} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />} className="addEmailAddress">
                      {intl.formatMessage({ id: "add_email_address" })}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>}
          <div className='modalFooterButtonCenter'>
            <Button
              variant="secondary"
              htmlType="button"
              onClick={() => { setVotingModalOpen(false); setDisable(false) }}>
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              variant="primary"
              htmlType="button"
              onClick={() => handleShareButtonClick()}
              disabled={disable}
              className='deleteButton'>
              {intl.formatMessage({ id: "share" })}
            </Button>
          </div>
        </Form>
      </CustomModal>
      <CustomModal
        title={intl.formatMessage({ id: 'customize_your_download' })}
        open={downloadModalOpen}
        onOk={() => setDownloadModalOpen(false)}
        onCancel={() => setDownloadModalOpen(false)}
        closable={false}
        footer={null}>
        <Form
          {...formItemLayout}
          form={form}
          className="formContainer"
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="downloadProgramme"
          >
            <CustomSelect
              type="formSelect"
              name="programmeDownload"
              placeholder={intl.formatMessage({ id: "select_programme" })}
              onChange={(e) => onDownloadProgrammeChange(e)}
              options={[
                { key: "all", label: "All", value: "All" },
                ...(programNames ?? []),
              ]}
            />
          </Form.Item>
          <Form.Item name="downloadApplicationStatus"
          >
            <CustomSelect
              name="applicationDownloadStatus"
              type="formSelect"
              options={[
                { key: "all", label: "All", value: "All" },
                ...(uniqueStatuses ?? []),
              ]}
              placeholder={intl.formatMessage({ id: "select_application_status" })}
            />
          </Form.Item>
          <div className='modalFooterButtonCenter'>
            <Button
              variant="secondary"
              htmlType="button"
              onClick={() => setDownloadModalOpen(false)}>
              {intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              variant="primary"
              htmlType="submit"
              className='deleteButton'>
              {intl.formatMessage({ id: "download" })}
            </Button>
          </div>
        </Form>
      </CustomModal>
      <Tabs defaultActiveKey={activeKey} items={items} onChange={handleChangeRoute} />
    </div>
  );
};

const StyledDashboardPage = styled(DashboardPage)`
  &.dashboardActionPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
    .filters{
      margin: 20px 0px;
      display: flex;
      justify-content: space-between;
      .subFilterSection{
        display: flex;
        justify-content: space-between;
        .customSelect{
          width: 200px;
        }
      }
    }
    .sortOptions{
      display: flex;
      gap: 20px;
    }
    .button{
        border: 0;
        background: transparent;
        color: #5550F7;
        cursor: pointer;
        min-width: 100px;
    }
  }
`;

export default StyledDashboardPage;
