import React from 'react'

const CharityProfileHome = () => {
    return (
        <div>
            <h3>Welcome to charity profile page </h3>
        </div>
    )
}

export default CharityProfileHome
