import React, { useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Steps } from "antd";
import styled from "styled-components";
import { getProgramById } from '../../redux/actions';
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import DateFormatter from "../../components/DateFormatter";
import {
    programDataSelector,
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";

const CreateProfileStart = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const { programID = '5cb23bc5-4ff5-434e-a599-2920273feaf3' } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programData = useAppSelector(programDataSelector);

    const {
        companyName,
        programName,
        acceptStartDate,
        acceptEndDate,
        applicationAcceptPeriod
    } = programData || {};

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const handleProceed = () => {
        navigate("/impact-maker/applications/create/name")
    }

    return (
        <div className={baseClassName}>
            <Steps
                current={0}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            <StyledText as="p" variant="H1a" className='mainTitle'>
                {intl.formatMessage({ id: "application_form" })}
            </StyledText>
            <div className='mainContainer'>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "this_application_is_to" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {companyName} for the {programName}
                    </StyledText>
                </div>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "application_accepted" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {applicationAcceptPeriod || `${<DateFormatter dateValue={acceptStartDate?.trim()} />} to ${<DateFormatter dateValue={acceptEndDate?.trim()} />}`}
                    </StyledText>
                </div>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "application_sections" })}
                </StyledText>
                <div>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "about_your_project" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "your_request" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "planned_impact_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "award_criteria" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "contact_details" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "application_preview" })}
                    </StyledText>
                </div>

                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "application_guidance" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {intl.formatMessage({ id: "in_the_application_form" })}
                </StyledText>

                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "next_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "saves_your_data_and_moves" })}
                    </StyledText>
                </div>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "save_n_exit_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "saves_your_data_and_closes" })}
                    </StyledText>
                </div>
                <div className="contentContainer">
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "submit_title" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "sends_your_application_form_to_the_company" })}
                    </StyledText>
                </div>
                <div>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "to_access_a_saved_application" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "to_view_a_submitted_application" })}
                    </StyledText>
                </div>
            </div>

            <div className='buttonContainer'>
                <Divider />
                <Button
                    className='proceedButton'
                    variant="primary"
                    type="button"
                    onClick={() => handleProceed()}>
                    {intl.formatMessage({ id: "start" })}
                </Button>
            </div>
        </div>
    )
}

const StyledCreateProfile = styled(CreateProfileStart)`
&.createProfile{
    background: white;
    display: flex;
    padding: 20px;
    flex-direction: column;
    height: 100vh;
    .mainTitle{
        margin-bottom: 20px;
    }
    .mainContainer{
        display: flex;
        gap: 20px;
        flex-direction: column;
        .contentContainer{
        display: flex;
        gap: 4px;
        }
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -30px;
        .proceedButton{
            margin-bottom: 15px;
        }
    }
}`;

export default StyledCreateProfile;