import React from 'react';
import { FormattedDate } from 'react-intl';

const parseDate = (dateString) => {
    let year, month, day;
    if (dateString?.includes('/')) {
        [year, month, day] = dateString.split('/');
    } else {
        year = dateString?.substring(0, 4);
        month = dateString?.substring(4, 6);
        day = dateString?.substring(6, 8);
    }
    return new Date(`${year}-${month}-${day}`);
};

const DateFormatter = ({ dateValue }) => {
    return (
        <FormattedDate
            value={parseDate(dateValue)}
            year="numeric"
            month="long"
            day="numeric"
        />
    );
};

export default DateFormatter;
