import axios from "axios";
import mime from "mime";
import Config from "../config";
import { useState, useEffect } from "react";

export const millisecondsToMinutesSeconds = (ms) => {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const convertDate = inputDate => {
  if (inputDate?.includes('/')) {
    const [day, month, year] = inputDate.split('/');
    return `${year}/${month}/${day}`;
  }
  return inputDate
};

export const formatDate = (timestamp) => {
  const date = new Date(Number(timestamp));
  return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
}

export const formatTimestamp = (timestamp) => {
  return new Date(Number(timestamp)).toLocaleDateString("en-GB", { day: "numeric", month: "long", year: "numeric" });
}

export const getBrowserLocale = () => {
  let lang = "";
  if (navigator.languages && navigator.languages?.length) {
    lang = navigator.languages?.[0];
  } else if (navigator.language) {
    lang = navigator.language;
  }
  return lang ? lang.split("-")?.[0] : "en";
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export const removeDuplicatesByKey = (array, key) => {
  const uniqueMap = new Map();
  if (Array.isArray(array)) {
    array.forEach(obj => {
      const keyValue = obj[key];

      if (!uniqueMap.has(keyValue)) {
        uniqueMap.set(keyValue, obj);
      }
    });

    const uniqueArray = Array.from(uniqueMap.values());

    return uniqueArray;
  }
}

export const sortData = (data) => {
  data?.sort((a, b) => b?.category?.localeCompare(a?.category) || b?.value - a?.value);
  return data;
}

export const fetchData = async (
  url,
  method,
  data,
  dispatch,
  successActionType,
  setErrorObj,
  skipToken,
  asStringParam
) => {
  try {
    const token = localStorage.getItem("id_Token");
    const headers = token ? { Authorization: token } : {};
    const isDataObject = typeof data === 'object'
    if (token || skipToken) {
      const isQueryParma = !isDataObject && data ? `${Config.dev.url[url]}?${data}` : Config.dev.url[url];
      const response = await axios({
        method,
        url: !isDataObject && data && asStringParam ? `${Config.dev.url[url]}${data}` : isQueryParma,
        headers,
        data: isDataObject && data,
      });

      dispatch(successActionType(response.data));
      dispatch(setErrorObj({ error: false }));
      return response?.data;
    }
  } catch (error) {
    if (error.response)
      dispatch(setErrorObj({ error: true, message: error.response.data.message }));
  }
};

export const uploadFile = (url, postData, callBackFn, configObj) => {
  axios.post(url, postData, configObj)
    .then((res) => {
      callBackFn(res);
    }).catch((err) => {
      callBackFn(null, err);
    });
};

export const get = (url, cb) => {
  const token = localStorage.getItem('id_Token');
  if (token) {
    axios.get(url, {
      headers: { Authorization: token },
    })
      .then((res) => {
        cb(res);
      }).catch((err) => {
        cb(null, err);
      });
  }
};

export const passwordValidator = (value) => {
  const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const passed = regEx.test(value);
  return passed;
}

export const getLocalStorageItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item;
  } catch (error) {
    console.error('Error getting item from local storage:', error);
    return null;
  }
};

export const numericValidator = async (rule, value) => {
  if (value !== undefined && value?.trim().length) {
    const regEx = /^[0-9\s]+$/;
    const passed = regEx.test(value.trim());
    if (!passed) {
      return Promise.reject(new Error(rule?.message));
    }
  }
  return Promise.resolve();
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error('Error setting item in local storage:', error);
  }
};

export const removeQueryParam = (url, param) => url?.replace(new RegExp('([?&])' + param + '=[^&#]*(&|#|$)'), '$1')?.replace(/&$/, '')?.replace(/&$/, '');

export const getImage = (url, cb, obj, filename, useAlternateUrl) => {
  const contentType = filename ? mime.getType(filename) : "image/jpeg";
  const token = localStorage.getItem("id_Token");
  const uri = (useAlternateUrl ? Config.dev.url.imageURL.replace('ceasset/', '') : Config.dev.url.imageURL) + url;
  if (!uri?.includes(".zip") && token) {
    try {
      fetch(uri, {
        method: "GET",
        headers: new Headers({
          Authorization: token,
          "Content-Type": contentType,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          cb(blob, obj);
        })
        .catch((error) => {
          cb("", obj, error);
        });
    } catch (err) {
      console.log("Error", err);
    }
  }
};

export const getSecuredUrl = async (fileKey, useAlternateUrl) =>
  new Promise((resolve, reject) => {
    getImage(
      fileKey,
      (res, obj, err) => {
        if (res) {
          resolve(res);
        } else {
          reject(err);
        }
      },
      fileKey,
      '',
      useAlternateUrl
    );
  });

export const onFileRemovedHandler = (fileKey, callBackFn) => {
  if (fileKey) {
    const { deleteURL } = Config.dev.url;
    const data = { bucketName: 'bg-uploaded-media', fileName: fileKey };
    const token = localStorage.getItem('id_Token');
    const headers = {
      Authorization: token
    };
    uploadFile(deleteURL, data, callBackFn, { headers });
  }
};

const checkBlobExistence = async (blobUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img.complete && img.naturalWidth !== 0);
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = blobUrl;
  });
};

export const processPicture = async (picture) => {
  if (Array.isArray(picture) && picture?.length > 0 && picture?.[0]?.uid) {
    return picture;
  } else if (Array.isArray(picture) && picture?.length > 0) {
    const blobUrl = picture?.[0]?.toString();
    const blobExists = await checkBlobExistence(blobUrl);
    if (blobExists) {
      return [{ uid: '-1', name: 'image.png', status: 'done', url: blobUrl }];
    }
  }
  return [];
};

export const onDownloadFile = async (key, name) => {
  const getSecuredURL = await getSecuredUrl(key, name);
  const securedURL = URL.createObjectURL(getSecuredURL);
  const a = document.createElement('a');
  a.href = securedURL;
  a.download = name || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(securedURL);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
};

export const encodeString = (input, shift = 3, seed = 1) => {
  const random = (seed) => Math.sin(seed) * 10000 % 1;
  return input.split('')
    .map((char, index) => {
      const shiftAmount = shift + Math.floor(random(index + seed) * 10);
      return String.fromCharCode(char.charCodeAt(0) + shiftAmount);
    })
    .join('');
}

export const decodeString = (input, shift = 3, seed = 1) => {
  const random = (seed) => Math.sin(seed) * 10000 % 1;
  return input.split('')
    .map((char, index) => {
      const shiftAmount = shift + Math.floor(random(index + seed) * 10);
      return String.fromCharCode(char.charCodeAt(0) - shiftAmount);
    })
    .join('');
}

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
      if (timeoutId) {
          clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
          func(...args);
      }, delay);
  };
}

