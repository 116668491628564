import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Menu } from "antd";
import { useIntl } from "react-intl";
import styled from "styled-components";
import bizGive from "../assets/BizGive_logo_final.png";
import bizGiveCollapsed from "../assets/BizGive_logo_collapsed.png";
import userIcon from "../assets/avatar.svg";
import {
  useAppDispatch,
} from "../redux/store";
import StyledText from "./StyledText";
import { getLocalStorageItem, removeQueryParam } from '../utils/utilities';
import { resetState } from '../redux/actions';

const Header = ({ className, menuCollapsed }) => {
  const intl = useIntl();
  const [tokenAvailable, setTokenAvailable] = useState();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const hideLoginButtons = ["/forgot-password", "/create-password"].includes(location?.pathname)

  useEffect(() => {
    const isTokenAvailable = window.localStorage.getItem('id_Token');
    setTokenAvailable(!!isTokenAvailable);
  }, [location])

  const loggedInItems = [
    {
      key: 1,
      icon: <Avatar src={userIcon} className="userIcon" />,
      children: [
        {
          key: "logout",
          label: intl.formatMessage({ id: "logout" }),
        },
      ],
    },
  ];

  const onClick = (e) => {
    if (e.key === 'logout') {
      dispatch(resetState())
      localStorage.clear();
      sessionStorage.clear();
      window.open(`${window.location.origin}/`, '_self');
      removeQueryParam()
    }
  };

  const handleUserDetails = (page) => {
    if (page === 'login') {
      navigate(`/`);
      removeQueryParam()
    } else {
      navigate(`/signup`);
      removeQueryParam()
    }
  }

  const baseClassName = clsx("header", className);
  return (
    <div className={baseClassName}>
      <Avatar
        src={menuCollapsed && tokenAvailable ? bizGiveCollapsed : bizGive}
        shape="square"
        className={`logo ${menuCollapsed ? "collapsed" : ""}`}
      />
      {!hideLoginButtons && tokenAvailable ?
        <div className="userNameContainer">
          <span className="userName">{getLocalStorageItem('user_details')} </span>
          <Menu
            className="menu"
            mode="horizontal"
            onClick={onClick}
            items={loggedInItems}
          />
        </div>
        :
        !hideLoginButtons && <div className='loginAndSignUp'>
          <button onClick={() => handleUserDetails('login')}>
            <StyledText as="p" variant="H2" className="title">
              {intl.formatMessage({ id: 'login' })}
            </StyledText>
          </button>
          <button onClick={() => handleUserDetails('signUp')}>
            <StyledText as="p" variant="H2" className="signUp">
              {intl.formatMessage({ id: 'sign_up' })}
            </StyledText>
          </button>
        </div>}
    </div>
  );
};

const StyledHeader = styled(Header)`
  &.header {
    background-color: ${props => props.theme.bizGiveThemeColor};
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 50px;
    position: fixed;
    top: 0px;
    z-index: 99999;
    width: 100%;
    .userNameContainer {
      display: flex;
      align-items: center;
      gap: 20px;
      .menu {
        background-color: ${props => props.theme.bizGiveThemeColor};
        .userIcon {
          position: absolute;
          top: 5px;
          right: 0;
        }
      }
      .userName {
        color: #fff;
      }
    }

    .logo {
      height: 52px;
      width: 200px;
      background: #fff;
      border-radius: 0;
      &.collapsed {
        width: 80px;
      }
      img {
        object-fit: contain;
        transform: scale(0.7);
      }
    }
    .loginAndSignUp{
      display: flex;
      margin-right: 15px;
      gap: 20px;
      button {
        border: 0;
        background: transparent;
        cursor: pointer;
        p{
          color: #ffffff;
        }
      }
    }
  }
`;

export default StyledHeader;
