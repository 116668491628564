import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { Divider, Form, Input, Checkbox, Space, Radio, Avatar, Spin, Button as AntdButton, Steps } from "antd";
import styled from "styled-components";
import { editOpportunity, getOpportunity, getProgramById, setEditOpportunitiesData, toggleSpinner } from "../../redux/actions";
import StyledText from "../../components/StyledText";
import Button from "../../components/Button";
import { getLocalStorageItem, numericValidator } from "../../utils/utilities";
import Select from "../../components/CustomSelect"
import {
    opportunityDataSelector,
    programDataSelector,
    editOpportunityData,
    toggleSpinnerSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import lightbulb from '../../assets/lightbulb.svg'

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 24 },
};

const CharityRequest = ({ className }) => {
    const baseClassName = clsx("charityRequest", className);
    const intl = useIntl();
    const [formData, setFormData] = useState({});
    const [monetaryUnitError, setMonetaryUnitError] = useState(null);
    const [donationUseError, setDonationUseError] = useState(null);
    const [inKindUseError, setInKindUseError] = useState(null);
    const [volunteeringUseError, setVolunteeringUseError] = useState(null);
    const [expectedOutputError, setExpectedOutputError] = useState(null);
    const [savedClicked, setSavedClicked] = useState(false);
    const opportunityData = useAppSelector(opportunityDataSelector) || {}
    const programData = useAppSelector(programDataSelector) || {};
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const programReviewDetails = JSON.parse(getLocalStorageItem('programReviewDetails') || '{}');
    const { opportunityId, programID } = programReviewDetails || {};
    const { volunteeringSkillList, volunteeringType, currencySymbol, inKindOffering, outcomeMethod, cashFullFundingRequired } = programData || {};
    const { opportunity } = opportunityData || {};
    const editOpp = useAppSelector(editOpportunityData) || {};
    const { opportunityID } = editOpp || {};

    useEffect(() => {
        if (opportunityID && !savedClicked) {
            dispatch(setEditOpportunitiesData(''))
            dispatch(toggleSpinner(false));
            if (outcomeMethod === "Opportunity Custom2") {
                navigate("/impact-maker/applications/create/impact-opportunity-custom");
            } else {
                navigate("/impact-maker/applications/create/impact-programme-custom");
            }
        }
    }, [opportunityID])

    useEffect(() => {
        const validateMonetaryUnitSum = () => {
            const totalCashRequested = form.getFieldValue('totalCashRequested');
            const donationUse = form.getFieldValue('donationUse');

            const sumMonetaryUnits = donationUse?.reduce((sum, item) => {
                const monetaryUnitValue = parseFloat(item?.monetaryUnit || 0);
                return sum + monetaryUnitValue;
            }, 0);

            const differenceMonetaryUnits = parseFloat(totalCashRequested) - sumMonetaryUnits;
            if (sumMonetaryUnits !== parseFloat(totalCashRequested) && donationUse?.length) {
                setMonetaryUnitError(`The sum of these costs is ${currencySymbol}${Math.abs(differenceMonetaryUnits)} ${differenceMonetaryUnits < 0 ? 'more' : 'less'} than how much you are applying for.`);
            } else {
                setMonetaryUnitError(null);
            }
        };

        validateMonetaryUnitSum();
    }, [form, form.getFieldValue('donationUse'), form.getFieldValue('totalCashRequested')]);

    useEffect(() => {
        const validateFormList = () => {
            const cashGiving = formData?.checkboxGroup?.includes("cashGiving");
            const donationUse = form.getFieldValue('donationUse');
            const inKind = formData?.checkboxGroup?.includes("inKind");
            const inKindUse = form.getFieldValue('inKindUse');
            const volunteering = formData?.checkboxGroup?.includes("volunteering");
            const volunteerUse = form.getFieldValue('volunteerUse');
            const expectedOutput = formData?.expectedOutput;
            const grantBudgetPercentage = Math.round((totalCashRequested / wholeProjectFunding) * 100)
            if (cashGiving && !donationUse?.length) {
                setDonationUseError('Please enter at least 1 row');
            } else {
                setDonationUseError(null);
            }
            if (inKind && !inKindUse?.length) {
                setInKindUseError('Please enter at least 1 row');
            } else {
                setInKindUseError(null);
            }
            if (volunteering && !volunteerUse?.length) {
                setVolunteeringUseError('Please enter at least 1 row');
            } else {
                setVolunteeringUseError(null);
            }
            if (grantBudgetPercentage < 100 && !expectedOutput?.length) {
                setExpectedOutputError('Please enter at least 1 row');
            } else {
                setExpectedOutputError(null);
            }
        };

        validateFormList();
    }, [form, formData]);

    useEffect(() => {
        if (opportunity) {
            const group = [];
            if (opportunity?.inKind === 'Y') group.push('inKind');
            if (opportunity?.volunteering === 'Y') group.push('volunteering');
            if (opportunity?.cashGiving === 'Y') group.push('cashGiving');

            const data = {
                ...(opportunity?.opportunitySummary && { opportunitySummary: opportunity?.opportunitySummary }),
                ...(opportunity?.opportunityObjectives && { opportunityObjectives: opportunity?.opportunityObjectives }),
                checkboxGroup: group,
                ...(opportunity?.totalCashRequested && { totalCashRequested: opportunity?.totalCashRequested }),
                ...(opportunity?.wholeProjectFunding && { wholeProjectFunding: opportunity?.wholeProjectFunding }),
                ...(opportunity?.donationUse && { donationUse: opportunity?.donationUse }),
                ...(opportunity?.additionalFundsSource && { additionalFundsSource: Object.keys(opportunity?.additionalFundsSource).filter(key => opportunity?.additionalFundsSource[key]) }),
                ...(opportunity?.fundingSecured && { fundingSecured: opportunity?.fundingSecured }),
                ...(opportunity?.expectedOutput && { expectedOutput: opportunity?.expectedOutput }),
                ...(opportunity?.oppFullBudgetNotRequired && { oppFullBudgetNotRequired: opportunity?.oppFullBudgetNotRequired }),
                ...(opportunity?.inKindUse ? { inKindUse: opportunity?.inKindUse } : { inKindUse: [{ inKindOfferingDescription: undefined, inKindActivityDescription: "" }] }),
                ...(opportunity?.volunteerUse ? { volunteerUse: opportunity?.volunteerUse } : { volunteerUse: [{ volunteerUnit: undefined, volunteerValue: "" }] }),
            };

            form.setFieldsValue(data);
            setFormData(data);
        }
    }, [opportunity]);


    useEffect(() => {
        if (opportunityId) {
            dispatch(getOpportunity(opportunityId));
        }
    }, [opportunityId, programID]);

    useEffect(() => {
        if (programID) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            dispatch(getProgramById(programID));
        }
    }, [programID]);

    const onFinishFailed = (e) => {
        console.log("Failed:", e);
    };

    const handleProceed = (e) => {
        const formData = form.getFieldsValue();
        if (monetaryUnitError || inKindUseError || donationUseError || volunteeringUseError) return;
        const data = {
            inKind: formData?.checkboxGroup?.includes("inKind") ? "Y" : "N",
            volunteering: formData?.checkboxGroup?.includes("volunteering") ? "Y" : "N",
            cashGiving: formData?.checkboxGroup?.includes("cashGiving") ? "Y" : "N",
            expectedOutput: formData?.expectedOutput,
            opportunityID: opportunityId,
            programID: programID,
            cashTargetCurrencyCode: currencySymbol,
            donationUse: formData?.donationUse,
            totalCashRequested: formData?.totalCashRequested,
            wholeProjectFunding: formData?.wholeProjectFunding,
            volunteerUse: formData?.volunteerUse,
            inKindUse: formData?.inKindUse,
            grantBudgetPercentage: Math.round((totalCashRequested / wholeProjectFunding) * 100),
            oppFullBudgetNotRequired: formData?.oppFullBudgetNotRequired,
            fundingSecured: formData?.fundingSecured,
            additionalFundsSource: {
                internal: formData?.additionalFundsSource?.includes("internal"),
                external: formData?.additionalFundsSource?.includes("external"),
            }
        }
        dispatch(editOpportunity(data));
        dispatch(toggleSpinner(true));
    }

    const handleSaveAndExit = () => {
        setSavedClicked(true);
        setTimeout(() => {
            form.submit();
            dispatch(toggleSpinner(false));
            navigate(`/impact-maker/programme/${programID}/${opportunityId}`);
        }, 300);
    }

    const handlePrevious = () => {
        navigate("/impact-maker/applications/create/dates")
    }

    const onFormChange = (_, allValues) => {
        setFormData(allValues);
    };

    const { checkboxGroup, totalCashRequested, wholeProjectFunding, expectedOutput } = formData || {};
    const grantBudgetPercentage = Math.round((totalCashRequested / wholeProjectFunding) * 100);

    const validateTotalProjectFunding = async (rule, value) => {
        try {
            await numericValidator(rule, value);
        } catch (error) {
            return Promise.reject(intl.formatMessage({ id: 'please_enter_numeric_value' }));
        }
        if (grantBudgetPercentage > 100) {
            return Promise.reject(intl.formatMessage({ id: 'this_value_cannot_be_more_than_the_amount_you_are_applying_for' }));
        }
        return Promise.resolve();
    };

    const validateWholeProjectFunding = async (rule, value) => {
        try {
            await numericValidator(rule, value);
        } catch (error) {
            return Promise.reject(intl.formatMessage({ id: 'please_enter_numeric_value' }));
        }
        if (grantBudgetPercentage > 100) {
            return Promise.reject(intl.formatMessage({ id: 'this_value_cannot_be_lower_than_the_amount_you_are_applying_for' }));
        }
        return Promise.resolve();
    };

    return (
        <div className={baseClassName}>
            {showSpinner && (
                <div className="overlay">
                    <Spin className="spinner" size="large" spinning={showSpinner} />
                </div>
            )}
            <Steps
                current={1}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            <div className='contentContainer'>
                <div>
                    <StyledText as="p" variant="H1" className='mainTitle'>
                        {intl.formatMessage({ id: "your_request" })}
                    </StyledText>
                    <StyledText as="p" variant="B3d" className='mainTitle'>
                        {intl.formatMessage({ id: "what_support_are_you_applying_for" })}
                    </StyledText>
                    <Form
                        {...formItemLayout}
                        labelAlign="left"
                        form={form}
                        onFinish={handleProceed}
                        onFinishFailed={onFinishFailed}
                        className="formContainer"
                        scrollToFirstError
                        onValuesChange={onFormChange}
                        initialValues={{
                            inKindUse: [
                                {
                                    inKindOfferingDescription: undefined,
                                    inKindActivityDescription: "",
                                },
                            ],
                            volunteerUse: [
                                {
                                    volunteerUnit: undefined,
                                    volunteerValue: "",
                                },
                            ],
                            donationUse: [
                                {
                                    monetaryUnit: '',
                                    numberOfItems: '',
                                    itemDescription: ''
                                }
                            ],
                            expectedOutput: [
                                {
                                    outputNumber: '',
                                    outputDescription: ''
                                }
                            ]
                        }}
                    >
                        <Form.Item
                            name="checkboxGroup"
                        >
                            <Checkbox.Group className='checkboxGroup'>
                                <Checkbox key="cashGiving" value="cashGiving">
                                    {intl.formatMessage({ id: "grant" })}
                                </Checkbox>
                                <Checkbox key="inKind" value="inKind">
                                    {intl.formatMessage({ id: "in_kind" })}
                                </Checkbox>
                                <Checkbox key="volunteering" value="volunteering">
                                    {intl.formatMessage({ id: "volunteering" })}
                                </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                        {checkboxGroup?.includes('cashGiving') && <>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "how_much_are_you_applying_for" })}</StyledText>
                            {cashFullFundingRequired === 'Y' && <StyledText as="p" variant="B3b">{intl.formatMessage({ id: "you_must_apply_for_100_percentage_of_the_total_project_cost" })}</StyledText>}
                            <Form.Item
                                name="totalCashRequested"
                                className="formItem"
                                rules={[
                                    { required: true, message: intl.formatMessage({ id: "enter" }) },
                                    { validator: validateTotalProjectFunding }
                                ]}
                            >
                                <Input
                                    addonBefore={currencySymbol}
                                    placeholder={intl.formatMessage({ id: "enter_amount" })}
                                    type="text"
                                />
                            </Form.Item>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "how_much_does_your_project_cost_in_total" })}</StyledText>
                            <Form.Item
                                name="wholeProjectFunding"
                                className="formItem"
                                rules={[{ required: true, message: intl.formatMessage({ id: "enter" }) }, { validator: validateWholeProjectFunding }]}
                            >
                                <Input
                                    addonBefore={currencySymbol}
                                    placeholder={intl.formatMessage({ id: "enter_amount" })}
                                    type="text"
                                />
                            </Form.Item>
                            <StyledText as="p" variant="B3b" className='helperText'>{intl.formatMessage({ id: "you_are_applying_for_percentage_of_the_total_cost_of_your_project" }).replace('percentage', `${grantBudgetPercentage}%`)}</StyledText>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "how_will_you_use_any_support_awarded" })}</StyledText>
                            <Form.List name="donationUse">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'monetaryUnit']}
                                                    className="formItemInput"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                        { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                    ]}
                                                >
                                                    <Input addonBefore={currencySymbol}
                                                        placeholder={intl.formatMessage({ id: "enter_amount" })}
                                                    />
                                                </Form.Item>
                                                <StyledText as="p" variant="B3" className='for'>
                                                    {intl.formatMessage({ id: "for" })}
                                                </StyledText>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'numberOfItems']}
                                                    className="formItemTextFieldPart"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                        { validator: numericValidator, message: intl.formatMessage({ id: 'please_enter_numeric_value' }) }
                                                    ]}
                                                >
                                                    <TextArea rows={1} placeholder={intl.formatMessage({ id: "enter_number_of_items_or_activity" })} />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'itemDescription']}
                                                    className="formItemTextFieldPart"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={1} placeholder={intl.formatMessage({ id: "enter_description" })} />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                            </Space>
                                        ))}
                                        {donationUseError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {donationUseError}
                                            </StyledText>
                                        )}
                                        {monetaryUnitError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {monetaryUnitError}
                                            </StyledText>
                                        )}
                                        <Form.Item>
                                            <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                                <StyledText as="p" variant="B3">
                                                    {intl.formatMessage({ id: "add" })}
                                                </StyledText>
                                            </AntdButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            {grantBudgetPercentage < 100 && <>
                                <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "where_will_additional_funds_come_from" })}</StyledText>
                                <Form.Item
                                    name="additionalFundsSource"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select',
                                        },
                                    ]}
                                >
                                    <Checkbox.Group className='checkboxGroup'>
                                        <Checkbox key="internal" value="internal">
                                            {intl.formatMessage({ id: "internal_resources" })}
                                        </Checkbox>
                                        <Checkbox key="external" value="external">
                                            {intl.formatMessage({ id: "external_funding" })}
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                                <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "have_you_already_secured_this_funding" })}</StyledText>
                                <Form.Item
                                    name="fundingSecured"
                                    rules={[
                                        { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                                    ]}
                                >
                                    <Radio.Group className="radioGroup">
                                        <Radio className="radio" value="Y">
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "yes" })}
                                            </StyledText>
                                        </Radio>
                                        <Radio className="radio" value="N">
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "no" })}
                                            </StyledText>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "can_your_project_proceed_if_you" })}</StyledText>
                                <Form.Item
                                    name="oppFullBudgetNotRequired"
                                    rules={[
                                        { whitespace: true, required: true, message: intl.formatMessage({ id: "enter" }) }
                                    ]}
                                >
                                    <Radio.Group className="radioGroup">
                                        <Radio className="radio" value="Y">
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "yes" })}
                                            </StyledText>
                                        </Radio>
                                        <Radio className="radio" value="N">
                                            <StyledText as="p" variant="B3">
                                                {intl.formatMessage({ id: "no" })}
                                            </StyledText>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </>}
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "what_outputs_will_the_support_help_you_to_achieve" })}</StyledText>
                            <Form.List name="expectedOutput">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'outputNumber']}
                                                    className="formItemTextFieldPart"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={intl.formatMessage({ id: "enter_estimated_number" })} />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'outputDescription']}
                                                    className="formItemTextFieldPart"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <Input rows={1} placeholder={intl.formatMessage({ id: "description" })} />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                            </Space>
                                        ))}
                                        {expectedOutputError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {expectedOutputError}
                                            </StyledText>
                                        )}
                                        <Form.Item>
                                            <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                                <StyledText as="p" variant="B3">
                                                    {intl.formatMessage({ id: "add" })}
                                                </StyledText>
                                            </AntdButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <StyledText as="p" variant="B3">{intl.formatMessage({ id: "your_outputs_will_show_like_this_in_your_application_form" })}</StyledText>
                            {expectedOutput?.map((el) => (el?.outputNumber && el?.outputDescription) ? <StyledText as="p" variant="B3b" className='helperText'>{el?.outputNumber} {el?.outputDescription}</StyledText> : '')}
                        </>}

                        {checkboxGroup?.includes('inKind') && <>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "what_items_or_services_do_you_need" })}</StyledText>
                            <Form.List name="inKindUse">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'inKindOfferingDescription']}
                                                    className="formItem"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        type="formSelect"
                                                        allowClear
                                                        placeholder={intl.formatMessage({ id: "option" })}
                                                        onSelect={() => { }}
                                                        options={[
                                                            ...(inKindOffering || []).map((value, index) => ({
                                                                key: index.toString(),
                                                                label: value?.inKindOfferingDescription,
                                                                value: value?.inKindOfferingDescription,
                                                            })),
                                                        ]}
                                                    />
                                                </Form.Item>
                                                <StyledText as="p" variant="B3" className='for'>
                                                    {intl.formatMessage({ id: "for" })}
                                                </StyledText>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'inKindActivityDescription']}
                                                    className="formItemTextField"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={1} placeholder={intl.formatMessage({ id: "enter_description" })} />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                            </Space>
                                        ))}
                                        {inKindUseError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {inKindUseError}
                                            </StyledText>
                                        )}
                                        <Form.Item>
                                            <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                                <StyledText as="p" variant="B3">
                                                    {intl.formatMessage({ id: "add" })}
                                                </StyledText>
                                            </AntdButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </>}
                        {checkboxGroup?.includes('volunteering') && <>
                            <StyledText as="p" variant="B3d">{intl.formatMessage({ id: "what_volunteering_support_do_you_need" })}</StyledText>
                            <Form.List name="volunteerUse">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 0 }} className='optionsContainer'>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'volunteerUnit']}
                                                    className="formItem"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        type="formSelect"
                                                        allowClear
                                                        placeholder={intl.formatMessage({ id: "option" })}
                                                        onSelect={() => { }}
                                                        options={[
                                                            ...(volunteeringType?.includes("General") ? [{ key: "general", label: "General", value: "General" }] : []),
                                                            ...(volunteeringSkillList || []).map((value, index) => ({
                                                                key: index.toString(),
                                                                label: value,
                                                                value: value,
                                                            })),
                                                        ]}
                                                    />
                                                </Form.Item>
                                                <StyledText as="p" variant="B3" className='for'>
                                                    {intl.formatMessage({ id: "for" })}
                                                </StyledText>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'volunteerValue']}
                                                    className="formItemTextField"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({ id: "enter" }),
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={1} placeholder={intl.formatMessage({ id: "enter_description" })} />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: '#04ac9c' }} className='for' />
                                            </Space>
                                        ))}
                                        {volunteeringUseError && (
                                            <StyledText as="p" variant="B3" className="errorText">
                                                {volunteeringUseError}
                                            </StyledText>
                                        )}
                                        <Form.Item>
                                            <AntdButton htmlType='button' className='addButton' type="dashed" onClick={() => add()} block icon={<PlusOutlined fill="red" />}>
                                                <StyledText as="p" variant="B3">
                                                    {intl.formatMessage({ id: "add" })}
                                                </StyledText>
                                            </AntdButton>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </>}

                        <div className='buttonContainer'>
                            <Divider />
                            <div className='buttonContentContainer'>
                                <div className='nextAndPreviousContainer'>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => handlePrevious()}>
                                        {intl.formatMessage({ id: "previous" })}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        htmlType="submit"
                                    >
                                        {intl.formatMessage({ id: "next" })}
                                    </Button>
                                </div>
                                <div className="saveAndExitButton">
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => handleSaveAndExit()}>
                                        {intl.formatMessage({ id: "save_n_exit" })}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className='tipsContainer'>
                    <div className='titleContainer'>
                        <Avatar src={lightbulb} />
                        <StyledText as="p" variant="H1">
                            {intl.formatMessage({ id: "tips" })}
                        </StyledText>
                    </div>
                    <StyledText as="p" variant="B3d">
                        {intl.formatMessage({ id: "what_is_an_output" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "an_output_is_what_you_expect_to_achieve_from_the_support_awarded_below_are_some_examples" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "number_new_teaching_assistants_trained" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "number_new_teachers_trained" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "number_workshops_hosted" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "number_energy_audits_completed" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "number_people_more_aware_of_climate_change_in_our_community" })}
                    </StyledText>
                    <StyledText as="p" variant="B3">
                        {intl.formatMessage({ id: "number_new_playground_installed" })}
                    </StyledText>
                </div>
            </div>
        </div>
    )
}

const StyledCharityRequest = styled(CharityRequest)`
&.charityRequest{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    .mainTitle{
        margin-bottom: 20px;
    }
    .errorText{
        color: #ff4d4f;
    }
    .contentContainer{
        display: flex;
        padding: 20px;
        justify-content: space-between;
        height: 280vh;
    }
    .addButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .formContainer{
        display: flex;
        gap: 12px;
        flex-direction: column;
    }
    .checkboxGroup{
        display: flex;
        flex-direction: column;
    }
    .optionsContainer{
        display: flex;
        align-items: center;
        gap: 30px;
        .for{
            margin-bottom: 10px;
        }
    }
    .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.45);
        }
    .spinner {
            z-index: 2000;
            margin: auto;
            left: 50%;
            right: 50%;
            position: absolute;
            bottom: 50%;
    }

    .radioGroup {
            display: flex;
            flex-direction: column;
    }

    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: white;
        margin-left: -80px;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
                margin-bottom: 15px;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
                margin-bottom: 15px;
            }
        }
    }
    .formItemTextField{
        .ant-form-item-control-input{
            width: 34.5vw !important;
        }
    }
    .formItemTextFieldPart{
        .ant-form-item-control-input{
            width: 16vw !important;
        } 
    }
    .helperText {
    color: #5271FF !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
  .tipsContainer{
    background: #D7FEFB;
    border: 1px solid #04ac9c;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    height: max-content;
    .titleContainer {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        gap: 10px;
    }
  }
  .formItemInput{
    width: 300px;
  }
}`;

export default StyledCharityRequest;