import React, { useEffect, useRef, memo, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { mapBoxToken } from "../config";

const fetchMapStyleData = async (styleUrl) => {
    try {
        const response = await axios.get(
            `https://api.mapbox.com/styles/v1/${styleUrl?.substring('mapbox://styles/'.length)}?access_token=${mapBoxToken}&permanent=true`
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching map style data:", error);
        return null;
    }
};

const reverseGeocode = async (lng, lat) => {
    try {
        const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapBoxToken}&permanent=true`);
        return response.data.features[0]?.place_name || "Unknown location";
    } catch (error) {
        console.error('Error reverse geocoding:', error);
        return "Error occurred";
    }
};

const MapBoxComponent = ({
    zoom,
    styles,
    geoJsonData,
    id,
    fitToCoordinates,
    onGeometryClick,
    selectedLocationName,
    showSearchBox,
    markers = [],
    onMarkerChange,
    hideStyles,
    isFullscreen, 
    hideZoomControls
}) => {
    const mapContainerRef = useRef(null);
    const geocoderRef = useRef(null);
    const isGeocoderInputRef = useRef(false);
    const markersRef = useRef([]);

    const addMarker = useCallback(async (lng, lat, map, additionalData = {}) => {
        const markerData = { ...additionalData.features };
        markersRef.current.push(markerData);
        if (onMarkerChange) {
            onMarkerChange(markersRef.current);
        }
        new mapboxgl.Marker({ color: 'red' })
            .setLngLat([lng, lat])
            .addTo(map);
    }, [onMarkerChange]);

    const initializeMap = useCallback(async () => {
        if (mapContainerRef?.current) {
            mapboxgl.accessToken = mapBoxToken;
            const styleUrl = styles || "mapbox://styles/amjp20/clp27a1k0009e01qterka7hfw";
            const styleData = await fetchMapStyleData(styleUrl);
            const center = styleData?.center || [0, 0];

            mapContainerRef.current.innerHTML = '';
            const map = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: hideStyles ? undefined : styleUrl,
                zoom,
                center: hideStyles ? Array.isArray(geoJsonData?.features?.[0]?.geometry?.coordinates?.[0]) ? geoJsonData?.features?.[0]?.geometry?.coordinates?.[0]?.[Math.floor(geoJsonData?.features?.[0]?.geometry?.coordinates?.[0]?.length / 2)] : geoJsonData?.features?.[0]?.geometry?.coordinates : center
            });

            map.on('style.load', () => {
                if (!hideZoomControls) {
                    map.addControl(new mapboxgl.NavigationControl({
                        visualizePitch: true
                    }));
                }
                if (showSearchBox || markers?.length > 0) {
                    markers.forEach(marker => {
                        if (marker?.geometry?.coordinates) {
                            new mapboxgl.Marker({ color: 'red' })
                                .setLngLat(marker.geometry.coordinates)
                                .addTo(map);
                            map.flyTo({ center: marker.geometry.coordinates, zoom: 7 });
                        }
                    });
                }

                if (geoJsonData?.features?.length > 0) {
                    geoJsonData.features.forEach((feature, index) => {
                        const { geometry, properties } = feature;
                        const currentCoordinates = (selectedLocationName && (selectedLocationName === properties?.name)) ||
                            (Array.isArray(fitToCoordinates) && fitToCoordinates.every(Array.isArray) ?
                                geometry?.coordinates?.[0]?.[0]?.toString() === fitToCoordinates?.[0]?.[0]?.toString() &&
                                geometry?.coordinates?.[0]?.[geometry?.coordinates?.[0]?.length - 1]?.toString() === fitToCoordinates?.[0]?.[fitToCoordinates?.length - 1]?.toString() :
                                fitToCoordinates && geometry.coordinates?.toString() === fitToCoordinates?.toString()
                            );

                        switch (geometry.type) {
                            case 'Point':
                                const marker = new mapboxgl.Marker({ color: currentCoordinates ? '#ED6C2D' : 'rgba(237, 237, 237, 1)', borderColor: '#242424', opacity: 0.1 })
                                    .setLngLat(geometry.coordinates)
                                    .addTo(map);
                                if (onGeometryClick) {
                                    marker.getElement().addEventListener('click', () => {
                                        onGeometryClick(feature, properties?.name);
                                    });
                                }
                                break;
                            case 'LineString':
                                map.addSource(`line-${id}-${index}`, {
                                    type: 'geojson',
                                    data: {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'LineString',
                                            coordinates: geometry?.coordinates
                                        }
                                    }
                                });

                                map.addLayer({
                                    id: `line-${id}-${index}`,
                                    type: 'line',
                                    source: `line-${id}-${index}`,
                                    paint: {
                                        'line-color': currentCoordinates ? '#ED6C2D' : '#800080',
                                        'line-width': 2,
                                        'line-opacity': 0.9
                                    }
                                });
                                if (onGeometryClick) {
                                    map.on('click', `line-${id}-${index}`, (e) => {
                                        const clickedFeature = map.queryRenderedFeatures(e.point, { layers: [`line-${id}-${index}`] })[0];
                                        onGeometryClick(clickedFeature, properties?.name);
                                    });
                                }
                                break;
                            case 'Polygon':
                                map.addSource(`polygon-${id}-${index}`, {
                                    type: 'geojson',
                                    data: {
                                        type: 'Feature',
                                        geometry: {
                                            type: 'Polygon',
                                            coordinates: geometry?.coordinates
                                        }
                                    }
                                });

                                map.addLayer({
                                    id: `polygon-${id}-${index}`,
                                    type: 'fill',
                                    source: `polygon-${id}-${index}`,
                                    paint: {
                                        'fill-color': hideStyles ? 'yellow' : currentCoordinates ? '#ED6C2D' : '#f3d8d2',
                                        'fill-opacity': hideStyles ? 0.1 : 0.9
                                    }
                                });
                                map.addLayer({
                                    'id': `outline-${id}-${index}`,
                                    'type': 'line',
                                    'source': `polygon-${id}-${index}`,
                                    'layout': {},
                                    'paint': {
                                        'line-color': hideStyles ? 'yellow' : '#ED6C2D',
                                        'line-width': hideStyles ? 1 : .4
                                    }
                                });
                                if (onGeometryClick) {
                                    map.on('click', `polygon-${id}-${index}`, (e) => {
                                        const clickedFeature = map.queryRenderedFeatures(e.point, { layers: [`polygon-${id}-${index}`] })[0];
                                        onGeometryClick(clickedFeature, properties?.name);
                                    });
                                }
                                break;
                            default:
                                console.warn(`Unhandled geometry type: ${geometry.type}`);
                        }
                    });
                }

                if (fitToCoordinates) {
                    let bounds;
                    if (Array.isArray(fitToCoordinates) && fitToCoordinates.every(Array.isArray)) {
                        const coordinates = geoJsonData?.features?.reduce((acc, feature) => {
                            if (feature?.geometry?.type === 'Polygon') {
                                acc = acc.concat(feature?.geometry?.coordinates?.[0]);
                            }
                            return acc;
                        }, []);
                        if (coordinates?.length > 0) {
                            bounds = coordinates.reduce((bounds, coord) => {
                                return bounds.extend(coord);
                            }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
                        }
                        map.fitBounds(bounds, { padding: 100 });
                    } else {
                        bounds = new mapboxgl.LngLatBounds();
                        bounds.extend(fitToCoordinates);
                        map.fitBounds(bounds, { maxZoom: 15 });
                    }
                }

                if (showSearchBox) {
                    const geocoder = new MapboxGeocoder({
                        accessToken: mapboxgl.accessToken,
                        mapboxgl: mapboxgl
                    });
                    map.addControl(geocoder, 'top-left');
                    geocoderRef.current = geocoder;

                    geocoder.on('result', async (event) => {
                        const coordinates = event.result.geometry.coordinates;
                        await addMarker(coordinates[0], coordinates[1], map, { features: event?.result });
                        map.flyTo({ center: [coordinates[0], coordinates[1]], zoom: 7 });
                    });

                    const geocoderInput = geocoder._inputEl;
                    geocoderInput.addEventListener('focus', () => {
                        isGeocoderInputRef.current = true;
                    });
                    geocoderInput.addEventListener('blur', () => {
                        isGeocoderInputRef.current = false;
                    });
                }
            });

            if (showSearchBox) {
                map.on('click', async (e) => {
                    if (!isGeocoderInputRef.current) {
                        const { lng, lat } = e.lngLat;
                        const placeName = await reverseGeocode(lng, lat);

                        const result = {
                            id: `place.${Date.now()}`,
                            type: 'Feature',
                            place_type: ['region', 'place'],
                            relevance: 1,
                            properties: {
                                mapbox_id: `dXJuOm1ieHBsYzpB${Date.now()}`,  // Unique Mapbox ID placeholder
                                wikidata: '',
                                short_code: ''
                            },
                            text_en: placeName,
                            language_en: 'en',
                            place_name_en: placeName,
                            text: placeName,
                            language: 'en',
                            place_name: placeName,
                            bbox: [lng - 0.01, lat - 0.01, lng + 0.01, lat + 0.01],
                            center: [lng, lat],
                            geometry: {
                                type: 'Point',
                                coordinates: [lng, lat]
                            },
                            context: [
                                {
                                    id: '',
                                    mapbox_id: '',
                                    wikidata: '',
                                    short_code: '',
                                    text_en: '',
                                    language_en: 'en',
                                    text: '',
                                    language: 'en'
                                }
                            ]
                        };

                        if (geocoderRef.current) {
                            geocoderRef.current.setInput(placeName);
                        }
                        await addMarker(lng, lat, map, { features: { ...result } });
                        map.flyTo({ center: [lng, lat], zoom: 7 });
                    }
                });

                markersRef.current = markers;
                markers.forEach(marker => {
                    if (marker.lng && marker.lat) {
                        new mapboxgl.Marker({ color: 'red' })
                            .setLngLat([marker.lng, marker.lat])
                            .addTo(map)
                    }
                });
            }

            return () => {
                map.remove();
                markersRef.current = [];
            };
        }
    }, [zoom, styles, geoJsonData, id, fitToCoordinates, selectedLocationName, onGeometryClick, markers, isFullscreen]);

    useEffect(() => {
        initializeMap();
    }, [initializeMap]);

    return (
        <div
            ref={mapContainerRef}
            id="map"
            style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                width: "100%",
                height: "100%"
            }}
        />
    );
};

export default memo(MapBoxComponent);
