import React from 'react';
import { Helmet as HelmetAsync } from "react-helmet-async";
import clsx from "clsx";
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';
import styled from "styled-components";
import CharityDonation from './charityDonation';
import StyledText from "../../components/StyledText";

const CharityDonationTabHomePage = ({ className }) => {
  const baseClassName = clsx("dashboardPage", className);
  const intl = useIntl();

  const items = [
    {
      key: "donations",
      label: '',
      children: <CharityDonation />,
    },
  ];

  return (
    <div className={baseClassName}>
      <HelmetAsync>
        <title>Charity Donations Page</title>
      </HelmetAsync>
      <StyledText as="p" variant="H1" className="title">
        {intl.formatMessage({ id: "donations" })}
      </StyledText>
      <Tabs defaultActiveKey={items[0]} items={items} />
    </div>
  )
}

const StyledDashboardPage = styled(CharityDonationTabHomePage)`
  &.dashboardPage {
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .ant-tabs-tab-active {
      .title {
        color: ${(props) => props.theme.bizGiveThemeColor} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${(props) => props.theme.bizGiveThemeColor} !important;
    }
  }
`;

export default StyledDashboardPage;


