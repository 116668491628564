import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";
import { getMyProgramList } from "../../../redux/actions";
import { programmeListSelector } from "../../../redux/selectors";
import { getSecuredUrl, formatDate, getLocalStorageItem } from "../../../utils/utilities";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";

const MyProfile = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const [logo, setLogo] = useState(null);
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const programmeList = useAppSelector(programmeListSelector);

    const setLogoImage = async (fileKey) => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogo(securedURL);
    };

    const handleEdit = () => {
        navigate("/company/my-profile/edit")
    }
    const decodedToken = (getLocalStorageItem('id_Token') && jwtDecode(getLocalStorageItem('id_Token'))) || {};
    
    useEffect(() => {
        dispatch(getMyProgramList())
    }, []);

    useEffect(() => {
        if (decodedToken?.picture) {
            setLogoImage(decodedToken?.picture)
        }
    }, [decodedToken?.picture]);

    return (
        <div className={baseClassName}>
            <StyledText as="p" variant="H1">
                {intl.formatMessage({ id: "my_profile" })}
            </StyledText>
            <div className='contentContainer'>
                <div className="titleContainer">
                    <div className='logoContentContainer'>
                        <div className='logoContainer'>
                            <img src={logo} alt="logo" className="hero-image" />
                        </div>
                        <StyledText as="p" variant="H1a" className='companyName'>
                            {decodedToken?.name}
                        </StyledText>
                    </div>
                    <div>
                        <Button className='editButton' variant="primary" type="button" onClick={() => handleEdit()}>{intl.formatMessage({ id: "edit" })}</Button>
                    </div>
                </div>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "phone_number" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {decodedToken?.["custom:phoneNumber"]}
                </StyledText>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "job_title" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {decodedToken?.["custom:job"]}
                </StyledText>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "email" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {decodedToken?.email}
                </StyledText>
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "biz_give_role" })}
                </StyledText>
                <StyledText as="p" variant="B3">
                    {decodedToken?.["custom:role"]}
                </StyledText>
                {decodedToken?.["custom:dateCreated"] && <><StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "member_since" })}
                </StyledText>
                    <StyledText as="p" variant="B3">
                        {formatDate(decodedToken?.["custom:dateCreated"])}
                    </StyledText></>}
                <StyledText as="p" variant="H2">
                    {intl.formatMessage({ id: "my_programmes" })}
                </StyledText>
                {programmeList?.map((el) => el?.programName?.trim() && <StyledText key={el?.programName} as="p" variant="B3">
                    {el?.programName}
                </StyledText>)}
            </div>
        </div>
    )
}

const StyledMyProfile = styled(MyProfile)`
&.createProfile{
    background: ${(props) => props.theme.backgroundCanvas};
    min-height: 100vh;
    display: flex;
    padding: 20px;
    flex-direction: column;
    .contentContainer{
        margin-block: 20px;
        background-color: white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        .titleContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .editButton{
                padding-inline: 50px;
            }
            .logoContentContainer{
            display: flex;
            align-items: center;
            .logoContainer{
                height: 100px;
                width: 100px;
                border-radius: 50%;
                img{
                    width: 100%;
                    border-radius: 50%;
                }
               
            }
            .companyName{
                    margin-left: 20px;
                }
        }
        }
        .subContentContainer{
            display: flex;
        flex-direction: column;
        gap: 10px;
        .linkContainer{
        display: flex;
        align-items: center;
        gap: 20px;
       }
        }
       
    }
}`;

export default StyledMyProfile;