import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import moment from 'moment';
import { useIntl } from 'react-intl';
import {
    Select, Layout, Form, Row, Col, Table, Spin,
} from 'antd';
import axios from '../../utils/axios';
import Config from '../../config';
import {
    donationSelector,
    donationListSelector,
    toggleSpinnerSelector
} from "../../redux/selectors";
import {
    useAppDispatch,
    useAppSelector
} from "../../redux/store";
import {
    setDonationListData as setDonationList,
    setDonationTwo as setDonation,
    toggleSpinner
} from '../../redux/actions';

const FormItem = Form.Item;

const getColumnData = (list, currencySymbol) => {
    if (list?.length > 0) {
        return list?.map((item, idx) => {
            const {
                programName,
                budgetEndDateHistory,
                grantTotalProgReportedCurrentPeriodHistory,
                budgetAmountHistory,
                remainingBudgetCurrentPeriodHistory,
            } = item || {};
            return {
                key: idx,
                programName,
                budgetEndDateHistory: budgetEndDateHistory?.trim()
                    ? moment(budgetEndDateHistory?.trim()).format('YYYY-MM-DD')
                    : '',
                grantTotalProgReportedCurrentPeriodHistory: grantTotalProgReportedCurrentPeriodHistory
                    ? `${currencySymbol} ${grantTotalProgReportedCurrentPeriodHistory}`
                    : '',
                budgetAmountHistory: budgetAmountHistory
                    ? `${currencySymbol} ${budgetAmountHistory}`
                    : '',
                remainingBudgetCurrentPeriodHistory: remainingBudgetCurrentPeriodHistory
                    ? `${currencySymbol} ${remainingBudgetCurrentPeriodHistory}`
                    : '',
            };
        });
    }
    return null;
};

const CompanyDonationBudget = ({
    className
}) => {
    const [currencySymbol, setCurrencySymbol] = useState('£');
    const [form] = Form.useForm();
    const values = form.getFieldsValue();
    const { programID } = values || {};
    const donation = useAppSelector(donationSelector);
    const progListData = useAppSelector(donationListSelector);
    const showSpinner = useAppSelector(toggleSpinnerSelector);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const setDataFromApi = async (responseData) => {
        const interaction = responseData?.data?.interaction;
        if (interaction) {
            dispatch(setDonation(interaction));
        }
    };

    const programColumns = [
        {
            title: intl.formatMessage({ id: 'programme' }),
            dataIndex: 'programName',
            key: 'programName',
        },
        {
            title: intl.formatMessage({ id: 'budget_period_end' }),
            dataIndex: 'budgetEndDateHistory',
            key: 'budgetEndDateHistory',
        },
        {
            title: intl.formatMessage({ id: 'round' }),
            dataIndex: 'budgetAmountHistory',
            key: 'budgetAmountHistory',
        },
        {
            title: intl.formatMessage({ id: 'awarded' }),
            dataIndex: 'grantTotalProgReportedCurrentPeriodHistory',
            key: 'grantTotalProgReportedCurrentPeriodHistory',
        },
        {
            title: intl.formatMessage({ id: 'budget_available' }),
            dataIndex: 'budgetAmountHistory',
            key: 'budgetAmountHistory',
        },
        {
            title: intl.formatMessage({ id: 'remaining' }),
            dataIndex: 'remainingBudgetCurrentPeriodHistory',
            key: 'remainingBudgetCurrentPeriodHistory',
        },
    ];

    const setFilters = (responseData) => {
        if (responseData) {
            setDataFromApi(responseData);
            dispatch(toggleSpinner(false));
        } else {
            dispatch(toggleSpinner(false));
        }
    };

    const setData = () => {
        dispatch(toggleSpinner(true));
        const progUrl = Config.dev.url.getInteraction;
        const data = {
            sortBy: 'programName',
            asc: 'true',
            filterBy: { status: ['Approved', 'PreApproved', 'Completed'] },
        };
        axios.put(progUrl, setFilters, data, true);
    };

    const setProgramDataFromApi = async (responseData) => {
        const list = responseData?.data?.data;
        if (list) {
            dispatch(setDonationList(list));
            dispatch(toggleSpinner(false));
        }
    };

    const getCurrencySymbol = (interaction) => {
        const tempProgramData = interaction?.data?.data?.[0]?.currencySymbol;
        return tempProgramData;
    };

    const setProgramListData = (responseData) => {
        if (responseData) {
            setCurrencySymbol(getCurrencySymbol(responseData));
            setProgramDataFromApi(responseData);
        } else {
            dispatch(toggleSpinner(false));
        }
    };

    const setListData = () => {
        dispatch(toggleSpinner(true));
        const progUrl = Config.dev.url.getDonationBudgetHistory;
        axios.put(progUrl, setProgramListData, { programID }, true);
    };

    useEffect(() => {
        dispatch(toggleSpinner(true));
        setData();
    }, []);

    const renderTable = () => (
        <Table
            className="components-table-demo-nested"
            columns={programColumns}
            dataSource={getColumnData(progListData, currencySymbol)}
            pagination={false}
        />
    );

    return (
        <Layout className={`companyDonationTwo ${className}`}>
            <Form form={form} >
                {showSpinner && (
                    <div className="overlay">
                        <Spin className="spinner" size="large" spinning={showSpinner} />
                    </div>
                )}
                <Layout className="progOppContainer">
                    <Row gutter={16}>
                        <Col span={4}>
                            <FormItem label="" name="programID" rules={[
                                {
                                    whitespace: true,
                                    required: false,
                                    message: 'Please select a programme',
                                },
                            ]}>
                                <Select allowClear placeholder="All Programmes" onSelect={setListData} options={[
                                    { label: intl.formatMessage({ id: 'all_programmes' }), value: '' },
                                    ...([...new Map(
                                        (donation || []).map((item) => [item.programName, item])
                                    ).values() || []]).map((value, index) => ({
                                        key: index.toString(),
                                        label: value.programName,
                                        value: value.programID
                                    }))
                                ]} />
                            </FormItem>
                        </Col>
                    </Row>
                </Layout>
                <Layout className="coverImageContainer">{renderTable()}</Layout>
            </Form>
        </Layout>
    );
};

const StyledCompanyDonationHomePage = styled(CompanyDonationBudget)`
&.companyDonationTwo{
  background: ${(props) => props.theme.backgroundCanvas};
    .progOppContainer {
    padding: 10px 15px;
    }

    .coverImageContainer {
      width: 100%;
      background-color: #f1eded !important;
      height: auto;
      min-height: 80px;
    }

    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.45);
    }

    .spinner {
      z-index: 2000;
      margin: auto;
      left: 50%;
      right: 50%;
      position: absolute;
      bottom: 50%;
    }

    @media only screen and (max-width: 1024px) {
      .steadyForm {
        width: 150%;
      }
    }

    @media only screen and (max-width: 960px) {
      .steadyForm {
        width: 200%;
      }
    }

    @media only screen and (max-width: 767px) {
      .steadyForm {
        width: 485%;
      }
    }

    @media only screen and (max-width: 280px) {
      .steadyForm {
        width: 1000%;
      }
    }
}
`;

export default StyledCompanyDonationHomePage;
