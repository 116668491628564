import React, { useState, useEffect } from 'react';
import clsx from "clsx";
import { useIntl } from "react-intl";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ImgCrop from 'antd-img-crop';
import { Divider, Upload, Steps, Button as AntButton } from "antd";
import StyledText from "../../../components/StyledText";
import Button from "../../../components/Button";
import { editProfile, getProfileDataCompany } from "../../../redux/actions";
import { profileSelector } from "../../../redux/selectors";
import CustomModal from "../../../components/Modal";
import {
    useAppDispatch,
    useAppSelector
} from "../../../redux/store";
import Config from "../../../config"
import { processPicture, uploadFile, get, getLocalStorageItem, getSecuredUrl, onFileRemovedHandler } from "../../../utils/utilities";

const CreateProfileLogo = ({ className }) => {
    const baseClassName = clsx("createProfile", className);
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(profileSelector);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [processedLogoS3URL, setProcessedLogoS3URL] = useState([]);
    const [logoS3URL, setLogoS3URL] = useState([]);
    const [logoS3URLData, setLogoS3URLData] = useState();

    const { company: {
        legalName,
        tradingName,
        registeredAddress,
        description,
        ID,
        socialMedia: {
            linkedInURL,
            facebookURL,
            instagramURL,
            xURL,
            twitterURL
        } = {},
        webURI,
        logoS3URL: savedLogoS3URL
    } = {}
    } = profile || {};

    useEffect(() => {
        dispatch(getProfileDataCompany())
    }, []);

    const afterFileRemoval = () => {
        setLogoS3URL([]);
        setIsModalOpen(false)
    }

    const onDelete = () => {
        onFileRemovedHandler(logoS3URLData, afterFileRemoval)
    }

    const onChange = ({ fileList: newFileList }) => {
        if (newFileList?.length === 0) {
            setIsModalOpen(true)
            return
        }
        setLogoS3URL(newFileList)
    };

    useEffect(() => {
        if (logoS3URL) {
            const fetchProcessedLogoS3URL = async () => {
                const processedFiles = await processPicture(logoS3URL);
                setProcessedLogoS3URL(processedFiles);
            };
            fetchProcessedLogoS3URL();
        }
    }, [logoS3URL]);

    const setLogoImage = async (fileKey) => {
        const securedURI = await getSecuredUrl(fileKey);
        const securedURL = URL.createObjectURL(securedURI);
        setLogoS3URL([securedURL]);
    };

    useEffect(() => {
        if (((savedLogoS3URL && typeof savedLogoS3 === 'string') || savedLogoS3URL?.length > 0)) {
            const url = Array.isArray(savedLogoS3URL) ? savedLogoS3URL?.[0] : savedLogoS3URL
            setLogoS3URLData(url);
            setLogoImage(url)
        }
    }, [savedLogoS3URL]);

    const handleProceed = () => {
        const data = {
            ID: ID,
            legalName,
            tradingName,
            registeredAddress,
            description,
            socialMedia: {
                linkedInURL: linkedInURL,
                facebookURL: facebookURL,
                instagramURL: instagramURL,
                xURL: xURL || twitterURL,
            },
            webURI: webURI,
            logoS3URL: logoS3URLData,
            profilePercentage: 100
        }
        dispatch(editProfile(data));
        setTimeout(() => {
            const decodeToken = (getLocalStorageItem('id_Token') && jwtDecode(getLocalStorageItem('id_Token'))) || {};
            if (decodeToken?.name) {
                navigate("/company/profile");
            } else {
                navigate("/company/my-profile/edit");
            }
        }, 500)
    }

    const handlePrevious = () => {
        navigate("/company/profile/create/media")
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const uploadFileToS3 = async (presignedPostData, file, onProgress) =>
        new Promise((resolve, reject) => {
            const formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            setLogoS3URLData(presignedPostData?.fields?.key)
            Object.keys(presignedPostData.fields).forEach((key) => {
                formData.append(key, presignedPostData.fields[key]);
            });

            formData.append("file", file);
            uploadFile(
                presignedPostData.url,
                formData,
                (res, err) => {
                    if (res && res.status === 204) {
                        resolve(res.data);
                    } else {
                        reject(err.response);
                    }
                },
                config
            );
        });

    const getPresignedPostData = async (selectedFile) =>
        new Promise((resolve, reject) => {
            const fileName = (selectedFile.name).replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
            const url = `${Config.dev.url.companyLogoUploadUrl}?fileName=${fileName}`;
            get(url, (res, err) => {
                if (res && res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(err.response);
                }
            });
        });

    const processFileUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            let presignedPostData = await getPresignedPostData(file);
            presignedPostData = await presignedPostData.data;
            await uploadFileToS3(presignedPostData, file, onProgress);
            onSuccess(null, file);
        } catch (e) {
            onError(e);
        }
    };

    return (
        <div className={baseClassName}>
            <Steps
                current={2}
                items={[
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_1" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "about_your_project" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_2" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "your_request" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_3" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3c" className='mainTitle'>
                            {intl.formatMessage({ id: "planned_impact_title" })}
                        </StyledText>,
                    },
                    {
                        title: <StyledText as="p" variant="H3">
                            {intl.formatMessage({ id: "step_4" })}
                        </StyledText>,
                        description: <StyledText as="p" variant="B3" className='mainTitle'>
                            {intl.formatMessage({ id: "award_criteria" })}
                        </StyledText>,
                    },
                ]}
            />
            <div className="mainContentContainer">
                <div className="contentContainer">
                    <StyledText as="p" variant="H1">
                        {intl.formatMessage({ id: "logo" })}
                    </StyledText>
                    <StyledText as="span" variant="B3d">
                        {intl.formatMessage({ id: "add_your_company_logo" })}
                    </StyledText>
                    <ImgCrop rotationSlider>
                        <Upload
                            maxCount={1}
                            listType="picture-card"
                            onChange={onChange}
                            fileList={processedLogoS3URL}
                            onPreview={onPreview}
                            customRequest={({
                                onSuccess, onError, file, onProgress
                            }) => processFileUpload({
                                onSuccess, onError, file, onProgress
                            })}
                        >
                            {processedLogoS3URL?.length < 1 && `+ ${intl.formatMessage({ id: "upload" })}`}
                        </Upload>
                    </ImgCrop>
                    <CustomModal
                        title={intl.formatMessage({ id: 'delete_file_confirmation' })}
                        open={isModalOpen}
                        onOk={() => setIsModalOpen(false)}
                        onCancel={() => setIsModalOpen(false)}
                        closable={false}
                        footer={null}>
                        <>
                            <StyledText as="p" variant="B3" className="title">
                                {intl.formatMessage({ id: 'please_confirm_you_are_happy_to_permanently_delete_this_file' })}
                            </StyledText>
                            <div>
                                <div className='modalFooterButton'>
                                    <Button
                                        variant="secondary"
                                        htmlType="button"
                                        onClick={() => setIsModalOpen(false)}>
                                        {intl.formatMessage({ id: "cancel" })}
                                    </Button>
                                    <AntButton
                                        variant="primary"
                                        htmlType="button"
                                        className='deleteButton'
                                        onClick={() => onDelete()}>
                                        {intl.formatMessage({ id: "delete" })}
                                    </AntButton>
                                </div>
                            </div>
                        </>
                    </CustomModal>
                </div>
                <div className='buttonContainer'>
                    <Divider />
                    <div className='buttonContentContainer'>
                        <div className='nextAndPreviousContainer'>
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={() => handlePrevious()}>
                                {intl.formatMessage({ id: "previous" })}
                            </Button>
                            <Button
                                variant="primary"
                                htmlType="button"
                                onClick={() => handleProceed()}>
                                {intl.formatMessage({ id: "complete" })}
                            </Button>
                        </div>
                        <div className="saveAndExitButton">
                            <Button
                                variant="secondary"
                                htmlType="button"
                                onClick={() => handleProceed()}>
                                {intl.formatMessage({ id: "save_n_exit" })}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const StyledCreateProfileLogo = styled(CreateProfileLogo)`
&.createProfile{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 83vh;
    .contentContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .formContainer {
            display: grid;
            gap: 20px;
            .formItem {
                width: 400px;
                margin-bottom: 0;
            }
        }
    }
    .buttonContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .buttonContentContainer{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            .saveAndExitButton{
                position: absolute;
                right: 0;
            }
            .nextAndPreviousContainer{
                display: flex;
                gap: 20px;
            }
        }
    }
    .linkContainer{
        display: flex;
        align-items: center;
        gap: 20px;
       }
    }
}`;

export default StyledCreateProfileLogo;